.popup-navigation {
  background-color: #F2F2F2;
  .popup-navigation__menu {
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -25px;
    padding: 25px;
  }
  .popup-navigation__menu_white {
    background-color: white;
  }
  .popup-navigation__currency {
    background-color: transparent;
    padding: 0;
    padding-top: 25px;
    margin: 0;
  }
  .menu__list--styled {
    .menu__item {
      padding-left: 25px;
      a {
        line-height: 16px;
        padding-top: 16px;
      }
    }
    .menu__item-element {
      padding-left: 36px;
    }
  }
}
