/*START: Bootstrap*/

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block
}


[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0
}

p {
    margin-top: 0
}

abbr[title], abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol, ul, dl {
    margin-top: 0
}

ol ol, ul ul, ol ul, ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    text-decoration: none
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

pre, code, kbd, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
    height: auto
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.container-fluid {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 1025px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}


.table, .responsive-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
    background-color: transparent
}

.table th, .table td, .responsive-table th, .responsive-table td {
    padding: 15px;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th, .responsive-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody + tbody, .responsive-table tbody + tbody {
    border-top: 2px solid #dee2e6
}

.table .table, .responsive-table .table {
    background-color: #fff
}

.table-sm th, .table-sm td {
    padding: 10px
}

.table-bordered {
    border: 1px solid #dee2e6
}

.table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6
}

.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075)
}

.table-primary, .table-primary > th, .table-primary > td {
    background-color: #b7daff
}

.table-hover .table-primary:hover {
    background-color: #9ecdff
}

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
    background-color: #9ecdff
}

.table-secondary, .table-secondary > th, .table-secondary > td {
    background-color: #d5d8da
}

.table-hover .table-secondary:hover {
    background-color: #c7cbce
}

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
    background-color: #c7cbce
}

.table-success, .table-success > th, .table-success > td {
    background-color: #c2e6ca
}

.table-hover .table-success:hover {
    background-color: #b0dfba
}

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
    background-color: #b0dfba
}

.table-info, .table-info > th, .table-info > td {
    background-color: #bee4eb
}

.table-hover .table-info:hover {
    background-color: #abdce5
}

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
    background-color: #abdce5
}

.table-warning, .table-warning > th, .table-warning > td {
    background-color: #ffedb9
}

.table-hover .table-warning:hover {
    background-color: #ffe6a0
}

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
    background-color: #ffe6a0
}

.table-danger, .table-danger > th, .table-danger > td {
    background-color: #f5c6ca
}

.table-hover .table-danger:hover {
    background-color: #f1b0b6
}

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
    background-color: #f1b0b6
}

.table-light, .table-light > th, .table-light > td {
    background-color: #fdfdfd
}

.table-hover .table-light:hover {
    background-color: #f0f0f0
}

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
    background-color: #f0f0f0
}

.table-dark, .table-dark > th, .table-dark > td {
    background-color: #c6c7c9
}

.table-hover .table-dark:hover {
    background-color: #b9babd
}

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
    background-color: #b9babd
}

.table-active, .table-active > th, .table-active > td {
    background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.table-dark {
    color: #fff;
    background-color: #212529
}

.table-dark th, .table-dark td, .table-dark thead th {
    border-color: #32383e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05)
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075)
}

@media (max-width: 540.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-sm > .table-bordered {
        border: 0
    }
}

@media (max-width: 777.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-md > .table-bordered {
        border: 0
    }
}

@media (max-width: 1024.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-lg > .table-bordered {
        border: 0
    }
}

@media (max-width: 1259.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .table-responsive-xl > .table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.table-responsive > .table-bordered {
    border: 0
}

.form-control, input[type="text"], input[type="tel"], input[type="number"], input[type="email"], input[type="password"], input[type="search"], textarea, select {
    display: block;
    width: 100%;
    background-clip: padding-box
}

.form-control:focus, input[type="text"]:focus, input[type="tel"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="search"]:focus, textarea:focus, select:focus {
    outline: 0
}

.form-control::placeholder, input[type="text"]::placeholder, input[type="number"]::placeholder, input[type="email"]::placeholder, input[type="password"]::placeholder, input[type="search"]::placeholder, textarea::placeholder, select::placeholder {
    opacity: 1
}

.form-control:disabled, .form-control[readonly], .form-control[disabled], input[type="text"]:disabled, input[type="text"][readonly], input[type="text"][disabled], input[type="number"]:disabled, input[type="number"][readonly], input[type="number"][disabled], input[type="email"]:disabled, input[type="email"][readonly], input[type="email"][disabled], input[type="password"]:disabled, input[type="password"][readonly], input[type="password"][disabled], input[type="search"]:disabled, input[type="search"][readonly], input[type="search"][disabled], textarea:disabled, textarea[readonly], textarea[disabled], select:disabled, select[readonly], select[disabled] {
    opacity: 1
}

.form-control::-ms-expand, input[type="text"]::-ms-expand, input[type="number"]::-ms-expand, input[type="email"]::-ms-expand, input[type="password"]::-ms-expand, input[type="search"]::-ms-expand, textarea::-ms-expand, select::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control-file, .form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0
}

select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px)
}

.form-control-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem
}

select.form-control-lg:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px)
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: 0.25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled ~ .form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: #28a745
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a
}

.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #34ce57
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit
}

.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip {
    display: block
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: #dc3545
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
    display: block
}

.was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip {
    display: block
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e4606d
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width: 541px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .input-group, .form-inline .custom-select {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none
}

.btn:hover, .btn:focus {
    text-decoration: none
}

.btn:focus, .btn.focus {
    outline: 0
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5)
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5)
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-link:disabled, .btn-link.disabled {
    pointer-events: none
}

.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0
}

.btn-block, .btn--full {
    display: block;
    width: 100%
}

.btn-block + .btn-block, .btn-block + .btn--full, .btn--full + .btn-block, .btn--full + .btn--full {
    margin-top: 0.5rem
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: 0.9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #007bff !important
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #0062cc !important
}

.bg-secondary {
    background-color: #6c757d !important
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
    background-color: #545b62 !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid transparent !important
}

.border-top {
    border-top: 1px solid transparent !important
}

.border-bottom {
    border-bottom: 1px solid transparent !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

body:not([dir='rtl']) .border-right {
    border-right: 1px solid transparent
}

body:not([dir='rtl']) .border-left {
    border-left: 1px solid transparent
}

body:not([dir='rtl']) .border-right-0 {
    border-right: 0 !important
}

body:not([dir='rtl']) .border-left-0 {
    border-left: 0 !important
}

[dir='rtl'] .border-right {
    border-left: 1px solid transparent
}

[dir='rtl'] .border-left {
    border-right: 1px solid transparent
}

[dir='rtl'] .border-right-0 {
    border-left: 0 !important
}

[dir='rtl'] .border-left-0 {
    border-right: 0 !important
}

.border-primary {
    border-color: #007bff !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #343a40 !important
}

.border-white {
    border-color: #fff !important
}

.rounded {
    border-radius: 0.25rem !important
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width: 541px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 778px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 1025px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 1260px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-center {
    justify-content: center !important;
    align-items: center !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width: 541px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-center {
        justify-content: center !important;
        align-items: center !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 778px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-center {
        justify-content: center !important;
        align-items: center !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1025px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-center {
        justify-content: center !important;
        align-items: center !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1260px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-center {
        justify-content: center !important;
        align-items: center !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width: 541px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width: 778px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width: 1025px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width: 1260px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0
}

.fixed-left {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0
}

.fixed-left [dir='rtl'] {
    left: 0;
    right: 0
}

.fixed-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0
}

.fixed-right [dir='rtl'] {
    right: auto;
    left: 0
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-0 {
    width: 0 !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-0 {
    height: 0 !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.m-0 {
    margin: 0 !important
}

.mt-0, .my-0 {
    margin-top: 0 !important
}

.mr-0, .mx-0 {
    margin-right: 0 !important
}

.mb-0, .my-0 {
    margin-bottom: 0 !important
}

.ml-0, .mx-0 {
    margin-left: 0 !important
}

[dir='rtl'] .mr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.m-1 {
    margin: 1px !important
}

.mt-1, .my-1 {
    margin-top: 1px !important
}

.mr-1, .mx-1 {
    margin-right: 1px !important
}

.mb-1, .my-1 {
    margin-bottom: 1px !important
}

.ml-1, .mx-1 {
    margin-left: 1px !important
}

[dir='rtl'] .mr-1 {
    margin-left: 1px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-1 {
    margin-left: 0 !important;
    margin-right: 1px !important
}

.m-2 {
    margin: 2px !important
}

.mt-2, .my-2 {
    margin-top: 2px !important
}

.mr-2, .mx-2 {
    margin-right: 2px !important
}

.mb-2, .my-2 {
    margin-bottom: 2px !important
}

.ml-2, .mx-2 {
    margin-left: 2px !important
}

[dir='rtl'] .mr-2 {
    margin-left: 2px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-2 {
    margin-left: 0 !important;
    margin-right: 2px !important
}

.m-3 {
    margin: 3px !important
}

.mt-3, .my-3 {
    margin-top: 3px !important
}

.mr-3, .mx-3 {
    margin-right: 3px !important
}

.mb-3, .my-3 {
    margin-bottom: 3px !important
}

.ml-3, .mx-3 {
    margin-left: 3px !important
}

[dir='rtl'] .mr-3 {
    margin-left: 3px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-3 {
    margin-left: 0 !important;
    margin-right: 3px !important
}

.m-4 {
    margin: 4px !important
}

.mt-4, .my-4 {
    margin-top: 4px !important
}

.mr-4, .mx-4 {
    margin-right: 4px !important
}

.mb-4, .my-4 {
    margin-bottom: 4px !important
}

.ml-4, .mx-4 {
    margin-left: 4px !important
}

[dir='rtl'] .mr-4 {
    margin-left: 4px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-4 {
    margin-left: 0 !important;
    margin-right: 4px !important
}

.m-5 {
    margin: 5px !important
}

.mt-5, .my-5 {
    margin-top: 5px !important
}

.mr-5, .mx-5 {
    margin-right: 5px !important
}

.mb-5, .my-5 {
    margin-bottom: 5px !important
}

.ml-5, .mx-5 {
    margin-left: 5px !important
}

[dir='rtl'] .mr-5 {
    margin-left: 5px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-5 {
    margin-left: 0 !important;
    margin-right: 5px !important
}

.m-6 {
    margin: 6px !important
}

.mt-6, .my-6 {
    margin-top: 6px !important
}

.mr-6, .mx-6 {
    margin-right: 6px !important
}

.mb-6, .my-6 {
    margin-bottom: 6px !important
}

.ml-6, .mx-6 {
    margin-left: 6px !important
}

[dir='rtl'] .mr-6 {
    margin-left: 6px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-6 {
    margin-left: 0 !important;
    margin-right: 6px !important
}

.m-7 {
    margin: 7px !important
}

.mt-7, .my-7 {
    margin-top: 7px !important
}

.mr-7, .mx-7 {
    margin-right: 7px !important
}

.mb-7, .my-7 {
    margin-bottom: 7px !important
}

.ml-7, .mx-7 {
    margin-left: 7px !important
}

[dir='rtl'] .mr-7 {
    margin-left: 7px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-7 {
    margin-left: 0 !important;
    margin-right: 7px !important
}

.m-8 {
    margin: 8px !important
}

.mt-8, .my-8 {
    margin-top: 8px !important
}

.mr-8, .mx-8 {
    margin-right: 8px !important
}

.mb-8, .my-8 {
    margin-bottom: 8px !important
}

.ml-8, .mx-8 {
    margin-left: 8px !important
}

[dir='rtl'] .mr-8 {
    margin-left: 8px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-8 {
    margin-left: 0 !important;
    margin-right: 8px !important
}

.m-9 {
    margin: 9px !important
}

.mt-9, .my-9 {
    margin-top: 9px !important
}

.mr-9, .mx-9 {
    margin-right: 9px !important
}

.mb-9, .my-9 {
    margin-bottom: 9px !important
}

.ml-9, .mx-9 {
    margin-left: 9px !important
}

[dir='rtl'] .mr-9 {
    margin-left: 9px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-9 {
    margin-left: 0 !important;
    margin-right: 9px !important
}

.m-10 {
    margin: 10px !important
}

.my-10 {
    margin-top: 10px !important
}

.mr-10, .mx-10 {
    margin-right: 10px !important
}

.mb-10, .my-10 {
    margin-bottom: 10px !important
}

.ml-10, .mx-10 {
    margin-left: 10px !important
}

[dir='rtl'] .mr-10 {
    margin-left: 10px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-10 {
    margin-left: 0 !important;
    margin-right: 10px !important
}

.m-15 {
    margin: 15px !important
}

.mt-15, .my-15 {
    margin-top: 15px !important
}

.mr-15, .mx-15 {
    margin-right: 15px !important
}

.mb-15, .my-15 {
    margin-bottom: 15px !important
}

.ml-15, .mx-15 {
    margin-left: 15px !important
}

[dir='rtl'] .mr-15 {
    margin-left: 15px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-15 {
    margin-left: 0 !important;
    margin-right: 15px !important
}

.m-20 {
    margin: 20px !important
}

.mt-20, .my-20 {
    margin-top: 20px !important
}

.mr-20, .mx-20 {
    margin-right: 20px !important
}

.mb-20, .my-20 {
    margin-bottom: 20px !important
}

.ml-20, .mx-20 {
    margin-left: 20px !important
}

[dir='rtl'] .mr-20 {
    margin-left: 20px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-20 {
    margin-left: 0 !important;
    margin-right: 20px !important
}

.m-25 {
    margin: 25px !important
}

.mt-25, .my-25 {
    margin-top: 25px !important
}

.mr-25, .mx-25 {
    margin-right: 25px !important
}

.mb-25, .my-25 {
    margin-bottom: 25px !important
}

.ml-25, .mx-25 {
    margin-left: 25px !important
}

[dir='rtl'] .mr-25 {
    margin-left: 25px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-25 {
    margin-left: 0 !important;
    margin-right: 25px !important
}

.m-30 {
    margin: 30px !important
}

.mt-30, .my-30 {
    margin-top: 30px !important
}

.mr-30, .mx-30 {
    margin-right: 30px !important
}

.mb-30, .my-30 {
    margin-bottom: 30px !important
}

.ml-30, .mx-30 {
    margin-left: 30px !important
}

[dir='rtl'] .mr-30 {
    margin-left: 30px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-30 {
    margin-left: 0 !important;
    margin-right: 30px !important
}

.m-35 {
    margin: 35px !important
}

.mt-35, .my-35 {
    margin-top: 35px !important
}

.mr-35, .mx-35 {
    margin-right: 35px !important
}

.mb-35, .my-35 {
    margin-bottom: 35px !important
}

.ml-35, .mx-35 {
    margin-left: 35px !important
}

[dir='rtl'] .mr-35 {
    margin-left: 35px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-35 {
    margin-left: 0 !important;
    margin-right: 35px !important
}

.m-40 {
    margin: 40px !important
}

.mt-40, .my-40 {
    margin-top: 40px !important
}

.mr-40, .mx-40 {
    margin-right: 40px !important
}

.mb-40, .my-40 {
    margin-bottom: 40px !important
}

.ml-40, .mx-40 {
    margin-left: 40px !important
}

[dir='rtl'] .mr-40 {
    margin-left: 40px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-40 {
    margin-left: 0 !important;
    margin-right: 40px !important
}

.m-45 {
    margin: 45px !important
}

.mt-45, .my-45 {
    margin-top: 45px !important
}

.mr-45, .mx-45 {
    margin-right: 45px !important
}

.mb-45, .my-45 {
    margin-bottom: 45px !important
}

.ml-45, .mx-45 {
    margin-left: 45px !important
}

[dir='rtl'] .mr-45 {
    margin-left: 45px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-45 {
    margin-left: 0 !important;
    margin-right: 45px !important
}

.m-50 {
    margin: 50px !important
}

.mt-50, .my-50 {
    margin-top: 50px !important
}

.mr-50, .mx-50 {
    margin-right: 50px !important
}

.mb-50, .my-50 {
    margin-bottom: 50px !important
}

.ml-50, .mx-50 {
    margin-left: 50px !important
}

[dir='rtl'] .mr-50 {
    margin-left: 50px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-50 {
    margin-left: 0 !important;
    margin-right: 50px !important
}

.m-55 {
    margin: 55px !important
}

.mt-55, .my-55 {
    margin-top: 55px !important
}

.mr-55, .mx-55 {
    margin-right: 55px !important
}

.mb-55, .my-55 {
    margin-bottom: 55px !important
}

.ml-55, .mx-55 {
    margin-left: 55px !important
}

[dir='rtl'] .mr-55 {
    margin-left: 55px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-55 {
    margin-left: 0 !important;
    margin-right: 55px !important
}

.m-60 {
    margin: 60px !important
}

.mt-60, .my-60 {
    margin-top: 60px !important
}

.mr-60, .mx-60 {
    margin-right: 60px !important
}

.mb-60, .my-60 {
    margin-bottom: 60px !important
}

.ml-60, .mx-60 {
    margin-left: 60px !important
}

[dir='rtl'] .mr-60 {
    margin-left: 60px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-60 {
    margin-left: 0 !important;
    margin-right: 60px !important
}

.m-65 {
    margin: 65px !important
}

.mt-65, .my-65 {
    margin-top: 65px !important
}

.mr-65, .mx-65 {
    margin-right: 65px !important
}

.mb-65, .my-65 {
    margin-bottom: 65px !important
}

.ml-65, .mx-65 {
    margin-left: 65px !important
}

[dir='rtl'] .mr-65 {
    margin-left: 65px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-65 {
    margin-left: 0 !important;
    margin-right: 65px !important
}

.m-70 {
    margin: 70px !important
}

.mt-70, .my-70 {
    margin-top: 70px !important
}

.mr-70, .mx-70 {
    margin-right: 70px !important
}

.mb-70, .my-70 {
    margin-bottom: 70px !important
}

.ml-70, .mx-70 {
    margin-left: 70px !important
}

[dir='rtl'] .mr-70 {
    margin-left: 70px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-70 {
    margin-left: 0 !important;
    margin-right: 70px !important
}

.m-75 {
    margin: 75px !important
}

.mt-75, .my-75 {
    margin-top: 75px !important
}

.mr-75, .mx-75 {
    margin-right: 75px !important
}

.mb-75, .my-75 {
    margin-bottom: 75px !important
}

.ml-75, .mx-75 {
    margin-left: 75px !important
}

[dir='rtl'] .mr-75 {
    margin-left: 75px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-75 {
    margin-left: 0 !important;
    margin-right: 75px !important
}

.m-80 {
    margin: 80px !important
}

.mt-80, .my-80 {
    margin-top: 80px !important
}

.mr-80, .mx-80 {
    margin-right: 80px !important
}

.mb-80, .my-80 {
    margin-bottom: 80px !important
}

.ml-80, .mx-80 {
    margin-left: 80px !important
}

[dir='rtl'] .mr-80 {
    margin-left: 80px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-80 {
    margin-left: 0 !important;
    margin-right: 80px !important
}

.m-85 {
    margin: 85px !important
}

.mt-85, .my-85 {
    margin-top: 85px !important
}

.mr-85, .mx-85 {
    margin-right: 85px !important
}

.mb-85, .my-85 {
    margin-bottom: 85px !important
}

.ml-85, .mx-85 {
    margin-left: 85px !important
}

[dir='rtl'] .mr-85 {
    margin-left: 85px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-85 {
    margin-left: 0 !important;
    margin-right: 85px !important
}

.m-90 {
    margin: 90px !important
}

.mt-90, .my-90 {
    margin-top: 90px !important
}

.mr-90, .mx-90 {
    margin-right: 90px !important
}

.mb-90, .my-90 {
    margin-bottom: 90px !important
}

.ml-90, .mx-90 {
    margin-left: 90px !important
}

[dir='rtl'] .mr-90 {
    margin-left: 90px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-90 {
    margin-left: 0 !important;
    margin-right: 90px !important
}

.m-95 {
    margin: 95px !important
}

.mt-95, .my-95 {
    margin-top: 95px !important
}

.mr-95, .mx-95 {
    margin-right: 95px !important
}

.mb-95, .my-95 {
    margin-bottom: 95px !important
}

.ml-95, .mx-95 {
    margin-left: 95px !important
}

[dir='rtl'] .mr-95 {
    margin-left: 95px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-95 {
    margin-left: 0 !important;
    margin-right: 95px !important
}

.m-100 {
    margin: 100px !important
}

.mt-100, .my-100 {
    margin-top: 100px !important
}

.mr-100, .mx-100 {
    margin-right: 100px !important
}

.mb-100, .my-100 {
    margin-bottom: 100px !important
}

.ml-100, .mx-100 {
    margin-left: 100px !important
}

[dir='rtl'] .mr-100 {
    margin-left: 100px !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-100 {
    margin-left: 0 !important;
    margin-right: 100px !important
}

.p-0 {
    padding: 0 !important
}

.pt-0, .py-0 {
    padding-top: 0 !important
}

.pr-0, .px-0 {
    padding-right: 0 !important
}

.pb-0, .py-0 {
    padding-bottom: 0 !important
}

.pl-0, .px-0 {
    padding-left: 0 !important
}

[dir='rtl'] .pr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.p-1 {
    padding: 1px !important
}

.pt-1, .py-1 {
    padding-top: 1px !important
}

.pr-1, .px-1 {
    padding-right: 1px !important
}

.pb-1, .py-1 {
    padding-bottom: 1px !important
}

.pl-1, .px-1 {
    padding-left: 1px !important
}

[dir='rtl'] .pr-1 {
    padding-left: 1px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-1 {
    padding-left: 0 !important;
    padding-right: 1px !important
}

.p-2 {
    padding: 2px !important
}

.pt-2, .py-2 {
    padding-top: 2px !important
}

.pr-2, .px-2 {
    padding-right: 2px !important
}

.pb-2, .py-2 {
    padding-bottom: 2px !important
}

.pl-2, .px-2 {
    padding-left: 2px !important
}

[dir='rtl'] .pr-2 {
    padding-left: 2px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-2 {
    padding-left: 0 !important;
    padding-right: 2px !important
}

.p-3 {
    padding: 3px !important
}

.pt-3, .py-3 {
    padding-top: 3px !important
}

.pr-3, .px-3 {
    padding-right: 3px !important
}

.pb-3, .py-3 {
    padding-bottom: 3px !important
}

.pl-3, .px-3 {
    padding-left: 3px !important
}

[dir='rtl'] .pr-3 {
    padding-left: 3px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-3 {
    padding-left: 0 !important;
    padding-right: 3px !important
}

.p-4 {
    padding: 4px !important
}

.pt-4, .py-4 {
    padding-top: 4px !important
}

.pr-4, .px-4 {
    padding-right: 4px !important
}

.pb-4, .py-4 {
    padding-bottom: 4px !important
}

.pl-4, .px-4 {
    padding-left: 4px !important
}

[dir='rtl'] .pr-4 {
    padding-left: 4px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-4 {
    padding-left: 0 !important;
    padding-right: 4px !important
}

.p-5 {
    padding: 5px !important
}

.pt-5, .py-5 {
    padding-top: 5px !important
}

.pr-5, .px-5 {
    padding-right: 5px !important
}

.pb-5, .py-5 {
    padding-bottom: 5px !important
}

.pl-5, .px-5 {
    padding-left: 5px !important
}

[dir='rtl'] .pr-5 {
    padding-left: 5px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-5 {
    padding-left: 0 !important;
    padding-right: 5px !important
}

.p-6 {
    padding: 6px !important
}

.pt-6, .py-6 {
    padding-top: 6px !important
}

.pr-6, .px-6 {
    padding-right: 6px !important
}

.pb-6, .py-6 {
    padding-bottom: 6px !important
}

.pl-6, .px-6 {
    padding-left: 6px !important
}

[dir='rtl'] .pr-6 {
    padding-left: 6px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-6 {
    padding-left: 0 !important;
    padding-right: 6px !important
}

.p-7 {
    padding: 7px !important
}

.pt-7, .py-7 {
    padding-top: 7px !important
}

.pr-7, .px-7 {
    padding-right: 7px !important
}

.pb-7, .py-7 {
    padding-bottom: 7px !important
}

.pl-7, .px-7 {
    padding-left: 7px !important
}

[dir='rtl'] .pr-7 {
    padding-left: 7px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-7 {
    padding-left: 0 !important;
    padding-right: 7px !important
}

.p-8 {
    padding: 8px !important
}

.pt-8, .py-8 {
    padding-top: 8px !important
}

.pr-8, .px-8 {
    padding-right: 8px !important
}

.pb-8, .py-8 {
    padding-bottom: 8px !important
}

.pl-8, .px-8 {
    padding-left: 8px !important
}

[dir='rtl'] .pr-8 {
    padding-left: 8px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-8 {
    padding-left: 0 !important;
    padding-right: 8px !important
}

.p-9 {
    padding: 9px !important
}

.pt-9, .py-9 {
    padding-top: 9px !important
}

.pr-9, .px-9 {
    padding-right: 9px !important
}

.pb-9, .py-9 {
    padding-bottom: 9px !important
}

.pl-9, .px-9 {
    padding-left: 9px !important
}

[dir='rtl'] .pr-9 {
    padding-left: 9px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-9 {
    padding-left: 0 !important;
    padding-right: 9px !important
}

.p-10 {
    padding: 10px !important
}

.pt-10, .py-10 {
    padding-top: 10px !important
}

.pr-10, .px-10 {
    padding-right: 10px !important
}

.pb-10, .py-10 {
    padding-bottom: 10px !important
}

.pl-10, .px-10 {
    padding-left: 10px !important
}

[dir='rtl'] .pr-10 {
    padding-left: 10px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-10 {
    padding-left: 0 !important;
    padding-right: 10px !important
}

.p-15 {
    padding: 15px !important
}

.pt-15, .py-15 {
    padding-top: 15px !important
}

.pr-15, .px-15 {
    padding-right: 15px !important
}

.pb-15, .py-15 {
    padding-bottom: 15px !important
}

.pl-15, .px-15 {
    padding-left: 15px !important
}

[dir='rtl'] .pr-15 {
    padding-left: 15px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-15 {
    padding-left: 0 !important;
    padding-right: 15px !important
}

.p-20 {
    padding: 20px !important
}

.pt-20, .py-20 {
    padding-top: 20px !important
}

.pr-20, .px-20 {
    padding-right: 20px !important
}

.pb-20, .py-20 {
    padding-bottom: 20px !important
}

.pl-20, .px-20 {
    padding-left: 20px !important
}

[dir='rtl'] .pr-20 {
    padding-left: 20px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-20 {
    padding-left: 0 !important;
    padding-right: 20px !important
}

.p-25 {
    padding: 25px !important
}

.pt-25, .py-25 {
    padding-top: 25px !important
}

.pr-25, .px-25 {
    padding-right: 25px !important
}

.pb-25, .py-25 {
    padding-bottom: 25px !important
}

.pl-25, .px-25 {
    padding-left: 25px !important
}

[dir='rtl'] .pr-25 {
    padding-left: 25px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-25 {
    padding-left: 0 !important;
    padding-right: 25px !important
}

.p-30 {
    padding: 30px !important
}

.pt-30, .py-30 {
    padding-top: 30px !important
}

.pr-30, .px-30 {
    padding-right: 30px !important
}

.pb-30, .py-30 {
    padding-bottom: 30px !important
}

.pl-30, .px-30 {
    padding-left: 30px !important
}

[dir='rtl'] .pr-30 {
    padding-left: 30px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-30 {
    padding-left: 0 !important;
    padding-right: 30px !important
}

.p-35 {
    padding: 35px !important
}

.pt-35, .py-35 {
    padding-top: 35px !important
}

.pr-35, .px-35 {
    padding-right: 35px !important
}

.pb-35, .py-35 {
    padding-bottom: 35px !important
}

.pl-35, .px-35 {
    padding-left: 35px !important
}

[dir='rtl'] .pr-35 {
    padding-left: 35px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-35 {
    padding-left: 0 !important;
    padding-right: 35px !important
}

.p-40 {
    padding: 40px !important
}

.pt-40, .py-40 {
    padding-top: 40px !important
}

.pr-40, .px-40 {
    padding-right: 40px !important
}

.pb-40, .py-40 {
    padding-bottom: 40px !important
}

.pl-40, .px-40 {
    padding-left: 40px !important
}

[dir='rtl'] .pr-40 {
    padding-left: 40px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-40 {
    padding-left: 0 !important;
    padding-right: 40px !important
}

.p-45 {
    padding: 45px !important
}

.pt-45, .py-45 {
    padding-top: 45px !important
}

.pr-45, .px-45 {
    padding-right: 45px !important
}

.pb-45, .py-45 {
    padding-bottom: 45px !important
}

.pl-45, .px-45 {
    padding-left: 45px !important
}

[dir='rtl'] .pr-45 {
    padding-left: 45px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-45 {
    padding-left: 0 !important;
    padding-right: 45px !important
}

.p-50 {
    padding: 50px !important
}

.pt-50, .py-50 {
    padding-top: 50px !important
}

.pr-50, .px-50 {
    padding-right: 50px !important
}

.pb-50, .py-50 {
    padding-bottom: 50px !important
}

.pl-50, .px-50 {
    padding-left: 50px !important
}

[dir='rtl'] .pr-50 {
    padding-left: 50px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-50 {
    padding-left: 0 !important;
    padding-right: 50px !important
}

.p-55 {
    padding: 55px !important
}

.pt-55, .py-55 {
    padding-top: 55px !important
}

.pr-55, .px-55 {
    padding-right: 55px !important
}

.pb-55, .py-55 {
    padding-bottom: 55px !important
}

.pl-55, .px-55 {
    padding-left: 55px !important
}

[dir='rtl'] .pr-55 {
    padding-left: 55px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-55 {
    padding-left: 0 !important;
    padding-right: 55px !important
}

.p-60 {
    padding: 60px !important
}

.pt-60, .py-60 {
    padding-top: 60px !important
}

.pr-60, .px-60 {
    padding-right: 60px !important
}

.pb-60, .py-60 {
    padding-bottom: 60px !important
}

.pl-60, .px-60 {
    padding-left: 60px !important
}

[dir='rtl'] .pr-60 {
    padding-left: 60px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-60 {
    padding-left: 0 !important;
    padding-right: 60px !important
}

.p-65 {
    padding: 65px !important
}

.pt-65, .py-65 {
    padding-top: 65px !important
}

.pr-65, .px-65 {
    padding-right: 65px !important
}

.pb-65, .py-65 {
    padding-bottom: 65px !important
}

.pl-65, .px-65 {
    padding-left: 65px !important
}

[dir='rtl'] .pr-65 {
    padding-left: 65px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-65 {
    padding-left: 0 !important;
    padding-right: 65px !important
}

.p-70 {
    padding: 70px !important
}

.pt-70, .py-70 {
    padding-top: 70px !important
}

.pr-70, .px-70 {
    padding-right: 70px !important
}

.pb-70, .py-70 {
    padding-bottom: 70px !important
}

.pl-70, .px-70 {
    padding-left: 70px !important
}

[dir='rtl'] .pr-70 {
    padding-left: 70px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-70 {
    padding-left: 0 !important;
    padding-right: 70px !important
}

.p-75 {
    padding: 75px !important
}

.pt-75, .py-75 {
    padding-top: 75px !important
}

.pr-75, .px-75 {
    padding-right: 75px !important
}

.pb-75, .py-75 {
    padding-bottom: 75px !important
}

.pl-75, .px-75 {
    padding-left: 75px !important
}

[dir='rtl'] .pr-75 {
    padding-left: 75px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-75 {
    padding-left: 0 !important;
    padding-right: 75px !important
}

.p-80 {
    padding: 80px !important
}

.pt-80, .py-80 {
    padding-top: 80px !important
}

.pr-80, .px-80 {
    padding-right: 80px !important
}

.pb-80, .py-80 {
    padding-bottom: 80px !important
}

.pl-80, .px-80 {
    padding-left: 80px !important
}

[dir='rtl'] .pr-80 {
    padding-left: 80px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-80 {
    padding-left: 0 !important;
    padding-right: 80px !important
}

.p-85 {
    padding: 85px !important
}

.pt-85, .py-85 {
    padding-top: 85px !important
}

.pr-85, .px-85 {
    padding-right: 85px !important
}

.pb-85, .py-85 {
    padding-bottom: 85px !important
}

.pl-85, .px-85 {
    padding-left: 85px !important
}

[dir='rtl'] .pr-85 {
    padding-left: 85px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-85 {
    padding-left: 0 !important;
    padding-right: 85px !important
}

.p-90 {
    padding: 90px !important
}

.pt-90, .py-90 {
    padding-top: 90px !important
}

.pr-90, .px-90 {
    padding-right: 90px !important
}

.pb-90, .py-90 {
    padding-bottom: 90px !important
}

.pl-90, .px-90 {
    padding-left: 90px !important
}

[dir='rtl'] .pr-90 {
    padding-left: 90px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-90 {
    padding-left: 0 !important;
    padding-right: 90px !important
}

.p-95 {
    padding: 95px !important
}

.pt-95, .py-95 {
    padding-top: 95px !important
}

.pr-95, .px-95 {
    padding-right: 95px !important
}

.pb-95, .py-95 {
    padding-bottom: 95px !important
}

.pl-95, .px-95 {
    padding-left: 95px !important
}

[dir='rtl'] .pr-95 {
    padding-left: 95px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-95 {
    padding-left: 0 !important;
    padding-right: 95px !important
}

.p-100 {
    padding: 100px !important
}

.pt-100, .py-100 {
    padding-top: 100px !important
}

.pr-100, .px-100 {
    padding-right: 100px !important
}

.pb-100, .py-100 {
    padding-bottom: 100px !important
}

.pl-100, .px-100 {
    padding-left: 100px !important
}

[dir='rtl'] .pr-100 {
    padding-left: 100px !important;
    padding-right: 0 !important
}

[dir='rtl'] .pl-100 {
    padding-left: 0 !important;
    padding-right: 100px !important
}

.m-auto {
    margin: auto !important
}

.mt-auto, .my-auto {
    margin-top: auto !important
}

.mr-auto, .mx-auto {
    margin-right: auto !important
}

.mb-auto, .my-auto {
    margin-bottom: auto !important
}

.ml-auto, .mx-auto {
    margin-left: auto !important
}

[dir='rtl'] .mr-auto {
    margin-left: auto !important;
    margin-right: 0 !important
}

[dir='rtl'] .ml-auto {
    margin-left: 0 !important;
    margin-right: auto !important
}

@media (min-width: 541px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0, .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0, .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0, .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0, .mx-sm-0 {
        margin-left: 0 !important
    }

    [dir='rtl'] .mr-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .m-sm-1 {
        margin: 1px !important
    }

    .mt-sm-1, .my-sm-1 {
        margin-top: 1px !important
    }

    .mr-sm-1, .mx-sm-1 {
        margin-right: 1px !important
    }

    .mb-sm-1, .my-sm-1 {
        margin-bottom: 1px !important
    }

    .ml-sm-1, .mx-sm-1 {
        margin-left: 1px !important
    }

    [dir='rtl'] .mr-sm-1 {
        margin-left: 1px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-1 {
        margin-left: 0 !important;
        margin-right: 1px !important
    }

    .m-sm-2 {
        margin: 2px !important
    }

    .mt-sm-2, .my-sm-2 {
        margin-top: 2px !important
    }

    .mr-sm-2, .mx-sm-2 {
        margin-right: 2px !important
    }

    .mb-sm-2, .my-sm-2 {
        margin-bottom: 2px !important
    }

    .ml-sm-2, .mx-sm-2 {
        margin-left: 2px !important
    }

    [dir='rtl'] .mr-sm-2 {
        margin-left: 2px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-2 {
        margin-left: 0 !important;
        margin-right: 2px !important
    }

    .m-sm-3 {
        margin: 3px !important
    }

    .mt-sm-3, .my-sm-3 {
        margin-top: 3px !important
    }

    .mr-sm-3, .mx-sm-3 {
        margin-right: 3px !important
    }

    .mb-sm-3, .my-sm-3 {
        margin-bottom: 3px !important
    }

    .ml-sm-3, .mx-sm-3 {
        margin-left: 3px !important
    }

    [dir='rtl'] .mr-sm-3 {
        margin-left: 3px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-3 {
        margin-left: 0 !important;
        margin-right: 3px !important
    }

    .m-sm-4 {
        margin: 4px !important
    }

    .mt-sm-4, .my-sm-4 {
        margin-top: 4px !important
    }

    .mr-sm-4, .mx-sm-4 {
        margin-right: 4px !important
    }

    .mb-sm-4, .my-sm-4 {
        margin-bottom: 4px !important
    }

    .ml-sm-4, .mx-sm-4 {
        margin-left: 4px !important
    }

    [dir='rtl'] .mr-sm-4 {
        margin-left: 4px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-4 {
        margin-left: 0 !important;
        margin-right: 4px !important
    }

    .m-sm-5 {
        margin: 5px !important
    }

    .mt-sm-5, .my-sm-5 {
        margin-top: 5px !important
    }

    .mr-sm-5, .mx-sm-5 {
        margin-right: 5px !important
    }

    .mb-sm-5, .my-sm-5 {
        margin-bottom: 5px !important
    }

    .ml-sm-5, .mx-sm-5 {
        margin-left: 5px !important
    }

    [dir='rtl'] .mr-sm-5 {
        margin-left: 5px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-5 {
        margin-left: 0 !important;
        margin-right: 5px !important
    }

    .m-sm-6 {
        margin: 6px !important
    }

    .mt-sm-6, .my-sm-6 {
        margin-top: 6px !important
    }

    .mr-sm-6, .mx-sm-6 {
        margin-right: 6px !important
    }

    .mb-sm-6, .my-sm-6 {
        margin-bottom: 6px !important
    }

    .ml-sm-6, .mx-sm-6 {
        margin-left: 6px !important
    }

    [dir='rtl'] .mr-sm-6 {
        margin-left: 6px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-6 {
        margin-left: 0 !important;
        margin-right: 6px !important
    }

    .m-sm-7 {
        margin: 7px !important
    }

    .mt-sm-7, .my-sm-7 {
        margin-top: 7px !important
    }

    .mr-sm-7, .mx-sm-7 {
        margin-right: 7px !important
    }

    .mb-sm-7, .my-sm-7 {
        margin-bottom: 7px !important
    }

    .ml-sm-7, .mx-sm-7 {
        margin-left: 7px !important
    }

    [dir='rtl'] .mr-sm-7 {
        margin-left: 7px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-7 {
        margin-left: 0 !important;
        margin-right: 7px !important
    }

    .m-sm-8 {
        margin: 8px !important
    }

    .mt-sm-8, .my-sm-8 {
        margin-top: 8px !important
    }

    .mr-sm-8, .mx-sm-8 {
        margin-right: 8px !important
    }

    .mb-sm-8, .my-sm-8 {
        margin-bottom: 8px !important
    }

    .ml-sm-8, .mx-sm-8 {
        margin-left: 8px !important
    }

    [dir='rtl'] .mr-sm-8 {
        margin-left: 8px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-8 {
        margin-left: 0 !important;
        margin-right: 8px !important
    }

    .m-sm-9 {
        margin: 9px !important
    }

    .mt-sm-9, .my-sm-9 {
        margin-top: 9px !important
    }

    .mr-sm-9, .mx-sm-9 {
        margin-right: 9px !important
    }

    .mb-sm-9, .my-sm-9 {
        margin-bottom: 9px !important
    }

    .ml-sm-9, .mx-sm-9 {
        margin-left: 9px !important
    }

    [dir='rtl'] .mr-sm-9 {
        margin-left: 9px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-9 {
        margin-left: 0 !important;
        margin-right: 9px !important
    }

    .m-sm-10 {
        margin: 10px !important
    }

    .mt-sm-10, .my-sm-10 {
        margin-top: 10px !important
    }

    .mr-sm-10, .mx-sm-10 {
        margin-right: 10px !important
    }

    .mb-sm-10, .my-sm-10 {
        margin-bottom: 10px !important
    }

    .ml-sm-10, .mx-sm-10 {
        margin-left: 10px !important
    }

    [dir='rtl'] .mr-sm-10 {
        margin-left: 10px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-10 {
        margin-left: 0 !important;
        margin-right: 10px !important
    }

    .m-sm-15 {
        margin: 15px !important
    }

    .mt-sm-15, .my-sm-15 {
        margin-top: 15px !important
    }

    .mr-sm-15, .mx-sm-15 {
        margin-right: 15px !important
    }

    .mb-sm-15, .my-sm-15 {
        margin-bottom: 15px !important
    }

    .ml-sm-15, .mx-sm-15 {
        margin-left: 15px !important
    }

    [dir='rtl'] .mr-sm-15 {
        margin-left: 15px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-15 {
        margin-left: 0 !important;
        margin-right: 15px !important
    }

    .m-sm-20 {
        margin: 20px !important
    }

    .mt-sm-20, .my-sm-20 {
        margin-top: 20px !important
    }

    .mr-sm-20, .mx-sm-20 {
        margin-right: 20px !important
    }

    .mb-sm-20, .my-sm-20 {
        margin-bottom: 20px !important
    }

    .ml-sm-20, .mx-sm-20 {
        margin-left: 20px !important
    }

    [dir='rtl'] .mr-sm-20 {
        margin-left: 20px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-20 {
        margin-left: 0 !important;
        margin-right: 20px !important
    }

    .m-sm-25 {
        margin: 25px !important
    }

    .mt-sm-25, .my-sm-25 {
        margin-top: 25px !important
    }

    .mr-sm-25, .mx-sm-25 {
        margin-right: 25px !important
    }

    .mb-sm-25, .my-sm-25 {
        margin-bottom: 25px !important
    }

    .ml-sm-25, .mx-sm-25 {
        margin-left: 25px !important
    }

    [dir='rtl'] .mr-sm-25 {
        margin-left: 25px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-25 {
        margin-left: 0 !important;
        margin-right: 25px !important
    }

    .m-sm-30 {
        margin: 30px !important
    }

    .mt-sm-30, .my-sm-30 {
        margin-top: 30px !important
    }

    .mr-sm-30, .mx-sm-30 {
        margin-right: 30px !important
    }

    .mb-sm-30, .my-sm-30 {
        margin-bottom: 30px !important
    }

    .ml-sm-30, .mx-sm-30 {
        margin-left: 30px !important
    }

    [dir='rtl'] .mr-sm-30 {
        margin-left: 30px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-30 {
        margin-left: 0 !important;
        margin-right: 30px !important
    }

    .m-sm-35 {
        margin: 35px !important
    }

    .mt-sm-35, .my-sm-35 {
        margin-top: 35px !important
    }

    .mr-sm-35, .mx-sm-35 {
        margin-right: 35px !important
    }

    .mb-sm-35, .my-sm-35 {
        margin-bottom: 35px !important
    }

    .ml-sm-35, .mx-sm-35 {
        margin-left: 35px !important
    }

    [dir='rtl'] .mr-sm-35 {
        margin-left: 35px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-35 {
        margin-left: 0 !important;
        margin-right: 35px !important
    }

    .m-sm-40 {
        margin: 40px !important
    }

    .mt-sm-40, .my-sm-40 {
        margin-top: 40px !important
    }

    .mr-sm-40, .mx-sm-40 {
        margin-right: 40px !important
    }

    .mb-sm-40, .my-sm-40 {
        margin-bottom: 40px !important
    }

    .ml-sm-40, .mx-sm-40 {
        margin-left: 40px !important
    }

    [dir='rtl'] .mr-sm-40 {
        margin-left: 40px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-40 {
        margin-left: 0 !important;
        margin-right: 40px !important
    }

    .m-sm-45 {
        margin: 45px !important
    }

    .mt-sm-45, .my-sm-45 {
        margin-top: 45px !important
    }

    .mr-sm-45, .mx-sm-45 {
        margin-right: 45px !important
    }

    .mb-sm-45, .my-sm-45 {
        margin-bottom: 45px !important
    }

    .ml-sm-45, .mx-sm-45 {
        margin-left: 45px !important
    }

    [dir='rtl'] .mr-sm-45 {
        margin-left: 45px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-45 {
        margin-left: 0 !important;
        margin-right: 45px !important
    }

    .m-sm-50 {
        margin: 50px !important
    }

    .mt-sm-50, .my-sm-50 {
        margin-top: 50px !important
    }

    .mr-sm-50, .mx-sm-50 {
        margin-right: 50px !important
    }

    .mb-sm-50, .my-sm-50 {
        margin-bottom: 50px !important
    }

    .ml-sm-50, .mx-sm-50 {
        margin-left: 50px !important
    }

    [dir='rtl'] .mr-sm-50 {
        margin-left: 50px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-50 {
        margin-left: 0 !important;
        margin-right: 50px !important
    }

    .m-sm-55 {
        margin: 55px !important
    }

    .mt-sm-55, .my-sm-55 {
        margin-top: 55px !important
    }

    .mr-sm-55, .mx-sm-55 {
        margin-right: 55px !important
    }

    .mb-sm-55, .my-sm-55 {
        margin-bottom: 55px !important
    }

    .ml-sm-55, .mx-sm-55 {
        margin-left: 55px !important
    }

    [dir='rtl'] .mr-sm-55 {
        margin-left: 55px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-55 {
        margin-left: 0 !important;
        margin-right: 55px !important
    }

    .m-sm-60 {
        margin: 60px !important
    }

    .mt-sm-60, .my-sm-60 {
        margin-top: 60px !important
    }

    .mr-sm-60, .mx-sm-60 {
        margin-right: 60px !important
    }

    .mb-sm-60, .my-sm-60 {
        margin-bottom: 60px !important
    }

    .ml-sm-60, .mx-sm-60 {
        margin-left: 60px !important
    }

    [dir='rtl'] .mr-sm-60 {
        margin-left: 60px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-60 {
        margin-left: 0 !important;
        margin-right: 60px !important
    }

    .m-sm-65 {
        margin: 65px !important
    }

    .mt-sm-65, .my-sm-65 {
        margin-top: 65px !important
    }

    .mr-sm-65, .mx-sm-65 {
        margin-right: 65px !important
    }

    .mb-sm-65, .my-sm-65 {
        margin-bottom: 65px !important
    }

    .ml-sm-65, .mx-sm-65 {
        margin-left: 65px !important
    }

    [dir='rtl'] .mr-sm-65 {
        margin-left: 65px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-65 {
        margin-left: 0 !important;
        margin-right: 65px !important
    }

    .m-sm-70 {
        margin: 70px !important
    }

    .mt-sm-70, .my-sm-70 {
        margin-top: 70px !important
    }

    .mr-sm-70, .mx-sm-70 {
        margin-right: 70px !important
    }

    .mb-sm-70, .my-sm-70 {
        margin-bottom: 70px !important
    }

    .ml-sm-70, .mx-sm-70 {
        margin-left: 70px !important
    }

    [dir='rtl'] .mr-sm-70 {
        margin-left: 70px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-70 {
        margin-left: 0 !important;
        margin-right: 70px !important
    }

    .m-sm-75 {
        margin: 75px !important
    }

    .mt-sm-75, .my-sm-75 {
        margin-top: 75px !important
    }

    .mr-sm-75, .mx-sm-75 {
        margin-right: 75px !important
    }

    .mb-sm-75, .my-sm-75 {
        margin-bottom: 75px !important
    }

    .ml-sm-75, .mx-sm-75 {
        margin-left: 75px !important
    }

    [dir='rtl'] .mr-sm-75 {
        margin-left: 75px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-75 {
        margin-left: 0 !important;
        margin-right: 75px !important
    }

    .m-sm-80 {
        margin: 80px !important
    }

    .mt-sm-80, .my-sm-80 {
        margin-top: 80px !important
    }

    .mr-sm-80, .mx-sm-80 {
        margin-right: 80px !important
    }

    .mb-sm-80, .my-sm-80 {
        margin-bottom: 80px !important
    }

    .ml-sm-80, .mx-sm-80 {
        margin-left: 80px !important
    }

    [dir='rtl'] .mr-sm-80 {
        margin-left: 80px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-80 {
        margin-left: 0 !important;
        margin-right: 80px !important
    }

    .m-sm-85 {
        margin: 85px !important
    }

    .mt-sm-85, .my-sm-85 {
        margin-top: 85px !important
    }

    .mr-sm-85, .mx-sm-85 {
        margin-right: 85px !important
    }

    .mb-sm-85, .my-sm-85 {
        margin-bottom: 85px !important
    }

    .ml-sm-85, .mx-sm-85 {
        margin-left: 85px !important
    }

    [dir='rtl'] .mr-sm-85 {
        margin-left: 85px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-85 {
        margin-left: 0 !important;
        margin-right: 85px !important
    }

    .m-sm-90 {
        margin: 90px !important
    }

    .mt-sm-90, .my-sm-90 {
        margin-top: 90px !important
    }

    .mr-sm-90, .mx-sm-90 {
        margin-right: 90px !important
    }

    .mb-sm-90, .my-sm-90 {
        margin-bottom: 90px !important
    }

    .ml-sm-90, .mx-sm-90 {
        margin-left: 90px !important
    }

    [dir='rtl'] .mr-sm-90 {
        margin-left: 90px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-90 {
        margin-left: 0 !important;
        margin-right: 90px !important
    }

    .m-sm-95 {
        margin: 95px !important
    }

    .mt-sm-95, .my-sm-95 {
        margin-top: 95px !important
    }

    .mr-sm-95, .mx-sm-95 {
        margin-right: 95px !important
    }

    .mb-sm-95, .my-sm-95 {
        margin-bottom: 95px !important
    }

    .ml-sm-95, .mx-sm-95 {
        margin-left: 95px !important
    }

    [dir='rtl'] .mr-sm-95 {
        margin-left: 95px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-95 {
        margin-left: 0 !important;
        margin-right: 95px !important
    }

    .m-sm-100 {
        margin: 100px !important
    }

    .mt-sm-100, .my-sm-100 {
        margin-top: 100px !important
    }

    .mr-sm-100, .mx-sm-100 {
        margin-right: 100px !important
    }

    .mb-sm-100, .my-sm-100 {
        margin-bottom: 100px !important
    }

    .ml-sm-100, .mx-sm-100 {
        margin-left: 100px !important
    }

    [dir='rtl'] .mr-sm-100 {
        margin-left: 100px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-100 {
        margin-left: 0 !important;
        margin-right: 100px !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0, .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0, .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0, .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0, .px-sm-0 {
        padding-left: 0 !important
    }

    [dir='rtl'] .pr-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .p-sm-1 {
        padding: 1px !important
    }

    .pt-sm-1, .py-sm-1 {
        padding-top: 1px !important
    }

    .pr-sm-1, .px-sm-1 {
        padding-right: 1px !important
    }

    .pb-sm-1, .py-sm-1 {
        padding-bottom: 1px !important
    }

    .pl-sm-1, .px-sm-1 {
        padding-left: 1px !important
    }

    [dir='rtl'] .pr-sm-1 {
        padding-left: 1px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-1 {
        padding-left: 0 !important;
        padding-right: 1px !important
    }

    .p-sm-2 {
        padding: 2px !important
    }

    .pt-sm-2, .py-sm-2 {
        padding-top: 2px !important
    }

    .pr-sm-2, .px-sm-2 {
        padding-right: 2px !important
    }

    .pb-sm-2, .py-sm-2 {
        padding-bottom: 2px !important
    }

    .pl-sm-2, .px-sm-2 {
        padding-left: 2px !important
    }

    [dir='rtl'] .pr-sm-2 {
        padding-left: 2px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-2 {
        padding-left: 0 !important;
        padding-right: 2px !important
    }

    .p-sm-3 {
        padding: 3px !important
    }

    .pt-sm-3, .py-sm-3 {
        padding-top: 3px !important
    }

    .pr-sm-3, .px-sm-3 {
        padding-right: 3px !important
    }

    .pb-sm-3, .py-sm-3 {
        padding-bottom: 3px !important
    }

    .pl-sm-3, .px-sm-3 {
        padding-left: 3px !important
    }

    [dir='rtl'] .pr-sm-3 {
        padding-left: 3px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-3 {
        padding-left: 0 !important;
        padding-right: 3px !important
    }

    .p-sm-4 {
        padding: 4px !important
    }

    .pt-sm-4, .py-sm-4 {
        padding-top: 4px !important
    }

    .pr-sm-4, .px-sm-4 {
        padding-right: 4px !important
    }

    .pb-sm-4, .py-sm-4 {
        padding-bottom: 4px !important
    }

    .pl-sm-4, .px-sm-4 {
        padding-left: 4px !important
    }

    [dir='rtl'] .pr-sm-4 {
        padding-left: 4px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-4 {
        padding-left: 0 !important;
        padding-right: 4px !important
    }

    .p-sm-5 {
        padding: 5px !important
    }

    .pt-sm-5, .py-sm-5 {
        padding-top: 5px !important
    }

    .pr-sm-5, .px-sm-5 {
        padding-right: 5px !important
    }

    .pb-sm-5, .py-sm-5 {
        padding-bottom: 5px !important
    }

    .pl-sm-5, .px-sm-5 {
        padding-left: 5px !important
    }

    [dir='rtl'] .pr-sm-5 {
        padding-left: 5px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-5 {
        padding-left: 0 !important;
        padding-right: 5px !important
    }

    .p-sm-6 {
        padding: 6px !important
    }

    .pt-sm-6, .py-sm-6 {
        padding-top: 6px !important
    }

    .pr-sm-6, .px-sm-6 {
        padding-right: 6px !important
    }

    .pb-sm-6, .py-sm-6 {
        padding-bottom: 6px !important
    }

    .pl-sm-6, .px-sm-6 {
        padding-left: 6px !important
    }

    [dir='rtl'] .pr-sm-6 {
        padding-left: 6px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-6 {
        padding-left: 0 !important;
        padding-right: 6px !important
    }

    .p-sm-7 {
        padding: 7px !important
    }

    .pt-sm-7, .py-sm-7 {
        padding-top: 7px !important
    }

    .pr-sm-7, .px-sm-7 {
        padding-right: 7px !important
    }

    .pb-sm-7, .py-sm-7 {
        padding-bottom: 7px !important
    }

    .pl-sm-7, .px-sm-7 {
        padding-left: 7px !important
    }

    [dir='rtl'] .pr-sm-7 {
        padding-left: 7px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-7 {
        padding-left: 0 !important;
        padding-right: 7px !important
    }

    .p-sm-8 {
        padding: 8px !important
    }

    .pt-sm-8, .py-sm-8 {
        padding-top: 8px !important
    }

    .pr-sm-8, .px-sm-8 {
        padding-right: 8px !important
    }

    .pb-sm-8, .py-sm-8 {
        padding-bottom: 8px !important
    }

    .pl-sm-8, .px-sm-8 {
        padding-left: 8px !important
    }

    [dir='rtl'] .pr-sm-8 {
        padding-left: 8px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-8 {
        padding-left: 0 !important;
        padding-right: 8px !important
    }

    .p-sm-9 {
        padding: 9px !important
    }

    .pt-sm-9, .py-sm-9 {
        padding-top: 9px !important
    }

    .pr-sm-9, .px-sm-9 {
        padding-right: 9px !important
    }

    .pb-sm-9, .py-sm-9 {
        padding-bottom: 9px !important
    }

    .pl-sm-9, .px-sm-9 {
        padding-left: 9px !important
    }

    [dir='rtl'] .pr-sm-9 {
        padding-left: 9px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-9 {
        padding-left: 0 !important;
        padding-right: 9px !important
    }

    .p-sm-10 {
        padding: 10px !important
    }

    .pt-sm-10, .py-sm-10 {
        padding-top: 10px !important
    }

    .pr-sm-10, .px-sm-10 {
        padding-right: 10px !important
    }

    .pb-sm-10, .py-sm-10 {
        padding-bottom: 10px !important
    }

    .pl-sm-10, .px-sm-10 {
        padding-left: 10px !important
    }

    [dir='rtl'] .pr-sm-10 {
        padding-left: 10px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-10 {
        padding-left: 0 !important;
        padding-right: 10px !important
    }

    .p-sm-15 {
        padding: 15px !important
    }

    .pt-sm-15, .py-sm-15 {
        padding-top: 15px !important
    }

    .pr-sm-15, .px-sm-15 {
        padding-right: 15px !important
    }

    .pb-sm-15, .py-sm-15 {
        padding-bottom: 15px !important
    }

    .pl-sm-15, .px-sm-15 {
        padding-left: 15px !important
    }

    [dir='rtl'] .pr-sm-15 {
        padding-left: 15px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-15 {
        padding-left: 0 !important;
        padding-right: 15px !important
    }

    .p-sm-20 {
        padding: 20px !important
    }

    .pt-sm-20, .py-sm-20 {
        padding-top: 20px !important
    }

    .pr-sm-20, .px-sm-20 {
        padding-right: 20px !important
    }

    .pb-sm-20, .py-sm-20 {
        padding-bottom: 20px !important
    }

    .pl-sm-20, .px-sm-20 {
        padding-left: 20px !important
    }

    [dir='rtl'] .pr-sm-20 {
        padding-left: 20px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-20 {
        padding-left: 0 !important;
        padding-right: 20px !important
    }

    .p-sm-25 {
        padding: 25px !important
    }

    .pt-sm-25, .py-sm-25 {
        padding-top: 25px !important
    }

    .pr-sm-25, .px-sm-25 {
        padding-right: 25px !important
    }

    .pb-sm-25, .py-sm-25 {
        padding-bottom: 25px !important
    }

    .pl-sm-25, .px-sm-25 {
        padding-left: 25px !important
    }

    [dir='rtl'] .pr-sm-25 {
        padding-left: 25px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-25 {
        padding-left: 0 !important;
        padding-right: 25px !important
    }

    .p-sm-30 {
        padding: 30px !important
    }

    .pt-sm-30, .py-sm-30 {
        padding-top: 30px !important
    }

    .pr-sm-30, .px-sm-30 {
        padding-right: 30px !important
    }

    .pb-sm-30, .py-sm-30 {
        padding-bottom: 30px !important
    }

    .pl-sm-30, .px-sm-30 {
        padding-left: 30px !important
    }

    [dir='rtl'] .pr-sm-30 {
        padding-left: 30px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-30 {
        padding-left: 0 !important;
        padding-right: 30px !important
    }

    .p-sm-35 {
        padding: 35px !important
    }

    .pt-sm-35, .py-sm-35 {
        padding-top: 35px !important
    }

    .pr-sm-35, .px-sm-35 {
        padding-right: 35px !important
    }

    .pb-sm-35, .py-sm-35 {
        padding-bottom: 35px !important
    }

    .pl-sm-35, .px-sm-35 {
        padding-left: 35px !important
    }

    [dir='rtl'] .pr-sm-35 {
        padding-left: 35px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-35 {
        padding-left: 0 !important;
        padding-right: 35px !important
    }

    .p-sm-40 {
        padding: 40px !important
    }

    .pt-sm-40, .py-sm-40 {
        padding-top: 40px !important
    }

    .pr-sm-40, .px-sm-40 {
        padding-right: 40px !important
    }

    .pb-sm-40, .py-sm-40 {
        padding-bottom: 40px !important
    }

    .pl-sm-40, .px-sm-40 {
        padding-left: 40px !important
    }

    [dir='rtl'] .pr-sm-40 {
        padding-left: 40px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-40 {
        padding-left: 0 !important;
        padding-right: 40px !important
    }

    .p-sm-45 {
        padding: 45px !important
    }

    .pt-sm-45, .py-sm-45 {
        padding-top: 45px !important
    }

    .pr-sm-45, .px-sm-45 {
        padding-right: 45px !important
    }

    .pb-sm-45, .py-sm-45 {
        padding-bottom: 45px !important
    }

    .pl-sm-45, .px-sm-45 {
        padding-left: 45px !important
    }

    [dir='rtl'] .pr-sm-45 {
        padding-left: 45px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-45 {
        padding-left: 0 !important;
        padding-right: 45px !important
    }

    .p-sm-50 {
        padding: 50px !important
    }

    .pt-sm-50, .py-sm-50 {
        padding-top: 50px !important
    }

    .pr-sm-50, .px-sm-50 {
        padding-right: 50px !important
    }

    .pb-sm-50, .py-sm-50 {
        padding-bottom: 50px !important
    }

    .pl-sm-50, .px-sm-50 {
        padding-left: 50px !important
    }

    [dir='rtl'] .pr-sm-50 {
        padding-left: 50px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-50 {
        padding-left: 0 !important;
        padding-right: 50px !important
    }

    .p-sm-55 {
        padding: 55px !important
    }

    .pt-sm-55, .py-sm-55 {
        padding-top: 55px !important
    }

    .pr-sm-55, .px-sm-55 {
        padding-right: 55px !important
    }

    .pb-sm-55, .py-sm-55 {
        padding-bottom: 55px !important
    }

    .pl-sm-55, .px-sm-55 {
        padding-left: 55px !important
    }

    [dir='rtl'] .pr-sm-55 {
        padding-left: 55px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-55 {
        padding-left: 0 !important;
        padding-right: 55px !important
    }

    .p-sm-60 {
        padding: 60px !important
    }

    .pt-sm-60, .py-sm-60 {
        padding-top: 60px !important
    }

    .pr-sm-60, .px-sm-60 {
        padding-right: 60px !important
    }

    .pb-sm-60, .py-sm-60 {
        padding-bottom: 60px !important
    }

    .pl-sm-60, .px-sm-60 {
        padding-left: 60px !important
    }

    [dir='rtl'] .pr-sm-60 {
        padding-left: 60px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-60 {
        padding-left: 0 !important;
        padding-right: 60px !important
    }

    .p-sm-65 {
        padding: 65px !important
    }

    .pt-sm-65, .py-sm-65 {
        padding-top: 65px !important
    }

    .pr-sm-65, .px-sm-65 {
        padding-right: 65px !important
    }

    .pb-sm-65, .py-sm-65 {
        padding-bottom: 65px !important
    }

    .pl-sm-65, .px-sm-65 {
        padding-left: 65px !important
    }

    [dir='rtl'] .pr-sm-65 {
        padding-left: 65px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-65 {
        padding-left: 0 !important;
        padding-right: 65px !important
    }

    .p-sm-70 {
        padding: 70px !important
    }

    .pt-sm-70, .py-sm-70 {
        padding-top: 70px !important
    }

    .pr-sm-70, .px-sm-70 {
        padding-right: 70px !important
    }

    .pb-sm-70, .py-sm-70 {
        padding-bottom: 70px !important
    }

    .pl-sm-70, .px-sm-70 {
        padding-left: 70px !important
    }

    [dir='rtl'] .pr-sm-70 {
        padding-left: 70px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-70 {
        padding-left: 0 !important;
        padding-right: 70px !important
    }

    .p-sm-75 {
        padding: 75px !important
    }

    .pt-sm-75, .py-sm-75 {
        padding-top: 75px !important
    }

    .pr-sm-75, .px-sm-75 {
        padding-right: 75px !important
    }

    .pb-sm-75, .py-sm-75 {
        padding-bottom: 75px !important
    }

    .pl-sm-75, .px-sm-75 {
        padding-left: 75px !important
    }

    [dir='rtl'] .pr-sm-75 {
        padding-left: 75px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-75 {
        padding-left: 0 !important;
        padding-right: 75px !important
    }

    .p-sm-80 {
        padding: 80px !important
    }

    .pt-sm-80, .py-sm-80 {
        padding-top: 80px !important
    }

    .pr-sm-80, .px-sm-80 {
        padding-right: 80px !important
    }

    .pb-sm-80, .py-sm-80 {
        padding-bottom: 80px !important
    }

    .pl-sm-80, .px-sm-80 {
        padding-left: 80px !important
    }

    [dir='rtl'] .pr-sm-80 {
        padding-left: 80px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-80 {
        padding-left: 0 !important;
        padding-right: 80px !important
    }

    .p-sm-85 {
        padding: 85px !important
    }

    .pt-sm-85, .py-sm-85 {
        padding-top: 85px !important
    }

    .pr-sm-85, .px-sm-85 {
        padding-right: 85px !important
    }

    .pb-sm-85, .py-sm-85 {
        padding-bottom: 85px !important
    }

    .pl-sm-85, .px-sm-85 {
        padding-left: 85px !important
    }

    [dir='rtl'] .pr-sm-85 {
        padding-left: 85px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-85 {
        padding-left: 0 !important;
        padding-right: 85px !important
    }

    .p-sm-90 {
        padding: 90px !important
    }

    .pt-sm-90, .py-sm-90 {
        padding-top: 90px !important
    }

    .pr-sm-90, .px-sm-90 {
        padding-right: 90px !important
    }

    .pb-sm-90, .py-sm-90 {
        padding-bottom: 90px !important
    }

    .pl-sm-90, .px-sm-90 {
        padding-left: 90px !important
    }

    [dir='rtl'] .pr-sm-90 {
        padding-left: 90px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-90 {
        padding-left: 0 !important;
        padding-right: 90px !important
    }

    .p-sm-95 {
        padding: 95px !important
    }

    .pt-sm-95, .py-sm-95 {
        padding-top: 95px !important
    }

    .pr-sm-95, .px-sm-95 {
        padding-right: 95px !important
    }

    .pb-sm-95, .py-sm-95 {
        padding-bottom: 95px !important
    }

    .pl-sm-95, .px-sm-95 {
        padding-left: 95px !important
    }

    [dir='rtl'] .pr-sm-95 {
        padding-left: 95px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-95 {
        padding-left: 0 !important;
        padding-right: 95px !important
    }

    .p-sm-100 {
        padding: 100px !important
    }

    .pt-sm-100, .py-sm-100 {
        padding-top: 100px !important
    }

    .pr-sm-100, .px-sm-100 {
        padding-right: 100px !important
    }

    .pb-sm-100, .py-sm-100 {
        padding-bottom: 100px !important
    }

    .pl-sm-100, .px-sm-100 {
        padding-left: 100px !important
    }

    [dir='rtl'] .pr-sm-100 {
        padding-left: 100px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-sm-100 {
        padding-left: 0 !important;
        padding-right: 100px !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto, .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto, .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto, .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto, .mx-sm-auto {
        margin-left: auto !important
    }

    [dir='rtl'] .mr-sm-auto {
        margin-left: auto !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-sm-auto {
        margin-left: 0 !important;
        margin-right: auto !important
    }
}

@media (min-width: 778px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0, .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0, .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0, .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0, .mx-md-0 {
        margin-left: 0 !important
    }

    [dir='rtl'] .mr-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .m-md-1 {
        margin: 1px !important
    }

    .mt-md-1, .my-md-1 {
        margin-top: 1px !important
    }

    .mr-md-1, .mx-md-1 {
        margin-right: 1px !important
    }

    .mb-md-1, .my-md-1 {
        margin-bottom: 1px !important
    }

    .ml-md-1, .mx-md-1 {
        margin-left: 1px !important
    }

    [dir='rtl'] .mr-md-1 {
        margin-left: 1px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-1 {
        margin-left: 0 !important;
        margin-right: 1px !important
    }

    .m-md-2 {
        margin: 2px !important
    }

    .mt-md-2, .my-md-2 {
        margin-top: 2px !important
    }

    .mr-md-2, .mx-md-2 {
        margin-right: 2px !important
    }

    .mb-md-2, .my-md-2 {
        margin-bottom: 2px !important
    }

    .ml-md-2, .mx-md-2 {
        margin-left: 2px !important
    }

    [dir='rtl'] .mr-md-2 {
        margin-left: 2px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-2 {
        margin-left: 0 !important;
        margin-right: 2px !important
    }

    .m-md-3 {
        margin: 3px !important
    }

    .mt-md-3, .my-md-3 {
        margin-top: 3px !important
    }

    .mr-md-3, .mx-md-3 {
        margin-right: 3px !important
    }

    .mb-md-3, .my-md-3 {
        margin-bottom: 3px !important
    }

    .ml-md-3, .mx-md-3 {
        margin-left: 3px !important
    }

    [dir='rtl'] .mr-md-3 {
        margin-left: 3px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-3 {
        margin-left: 0 !important;
        margin-right: 3px !important
    }

    .m-md-4 {
        margin: 4px !important
    }

    .mt-md-4, .my-md-4 {
        margin-top: 4px !important
    }

    .mr-md-4, .mx-md-4 {
        margin-right: 4px !important
    }

    .mb-md-4, .my-md-4 {
        margin-bottom: 4px !important
    }

    .ml-md-4, .mx-md-4 {
        margin-left: 4px !important
    }

    [dir='rtl'] .mr-md-4 {
        margin-left: 4px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-4 {
        margin-left: 0 !important;
        margin-right: 4px !important
    }

    .m-md-5 {
        margin: 5px !important
    }

    .mt-md-5, .my-md-5 {
        margin-top: 5px !important
    }

    .mr-md-5, .mx-md-5 {
        margin-right: 5px !important
    }

    .mb-md-5, .my-md-5 {
        margin-bottom: 5px !important
    }

    .ml-md-5, .mx-md-5 {
        margin-left: 5px !important
    }

    [dir='rtl'] .mr-md-5 {
        margin-left: 5px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-5 {
        margin-left: 0 !important;
        margin-right: 5px !important
    }

    .m-md-6 {
        margin: 6px !important
    }

    .mt-md-6, .my-md-6 {
        margin-top: 6px !important
    }

    .mr-md-6, .mx-md-6 {
        margin-right: 6px !important
    }

    .mb-md-6, .my-md-6 {
        margin-bottom: 6px !important
    }

    .ml-md-6, .mx-md-6 {
        margin-left: 6px !important
    }

    [dir='rtl'] .mr-md-6 {
        margin-left: 6px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-6 {
        margin-left: 0 !important;
        margin-right: 6px !important
    }

    .m-md-7 {
        margin: 7px !important
    }

    .mt-md-7, .my-md-7 {
        margin-top: 7px !important
    }

    .mr-md-7, .mx-md-7 {
        margin-right: 7px !important
    }

    .mb-md-7, .my-md-7 {
        margin-bottom: 7px !important
    }

    .ml-md-7, .mx-md-7 {
        margin-left: 7px !important
    }

    [dir='rtl'] .mr-md-7 {
        margin-left: 7px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-7 {
        margin-left: 0 !important;
        margin-right: 7px !important
    }

    .m-md-8 {
        margin: 8px !important
    }

    .mt-md-8, .my-md-8 {
        margin-top: 8px !important
    }

    .mr-md-8, .mx-md-8 {
        margin-right: 8px !important
    }

    .mb-md-8, .my-md-8 {
        margin-bottom: 8px !important
    }

    .ml-md-8, .mx-md-8 {
        margin-left: 8px !important
    }

    [dir='rtl'] .mr-md-8 {
        margin-left: 8px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-8 {
        margin-left: 0 !important;
        margin-right: 8px !important
    }

    .m-md-9 {
        margin: 9px !important
    }

    .mt-md-9, .my-md-9 {
        margin-top: 9px !important
    }

    .mr-md-9, .mx-md-9 {
        margin-right: 9px !important
    }

    .mb-md-9, .my-md-9 {
        margin-bottom: 9px !important
    }

    .ml-md-9, .mx-md-9 {
        margin-left: 9px !important
    }

    [dir='rtl'] .mr-md-9 {
        margin-left: 9px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-9 {
        margin-left: 0 !important;
        margin-right: 9px !important
    }

    .m-md-10 {
        margin: 10px !important
    }

    .mt-md-10, .my-md-10 {
        margin-top: 10px !important
    }

    .mr-md-10, .mx-md-10 {
        margin-right: 10px !important
    }

    .mb-md-10, .my-md-10 {
        margin-bottom: 10px !important
    }

    .ml-md-10, .mx-md-10 {
        margin-left: 10px !important
    }

    [dir='rtl'] .mr-md-10 {
        margin-left: 10px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-10 {
        margin-left: 0 !important;
        margin-right: 10px !important
    }

    .m-md-15 {
        margin: 15px !important
    }

    .mt-md-15, .my-md-15 {
        margin-top: 15px !important
    }

    .mr-md-15, .mx-md-15 {
        margin-right: 15px !important
    }

    .mb-md-15, .my-md-15 {
        margin-bottom: 15px !important
    }

    .ml-md-15, .mx-md-15 {
        margin-left: 15px !important
    }

    [dir='rtl'] .mr-md-15 {
        margin-left: 15px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-15 {
        margin-left: 0 !important;
        margin-right: 15px !important
    }

    .m-md-20 {
        margin: 20px !important
    }

    .mt-md-20, .my-md-20 {
        margin-top: 20px !important
    }

    .mr-md-20, .mx-md-20 {
        margin-right: 20px !important
    }

    .mb-md-20, .my-md-20 {
        margin-bottom: 20px !important
    }

    .ml-md-20, .mx-md-20 {
        margin-left: 20px !important
    }

    [dir='rtl'] .mr-md-20 {
        margin-left: 20px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-20 {
        margin-left: 0 !important;
        margin-right: 20px !important
    }

    .m-md-25 {
        margin: 25px !important
    }

    .mt-md-25, .my-md-25 {
        margin-top: 25px !important
    }

    .mr-md-25, .mx-md-25 {
        margin-right: 25px !important
    }

    .mb-md-25, .my-md-25 {
        margin-bottom: 25px !important
    }

    .ml-md-25, .mx-md-25 {
        margin-left: 25px !important
    }

    [dir='rtl'] .mr-md-25 {
        margin-left: 25px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-25 {
        margin-left: 0 !important;
        margin-right: 25px !important
    }

    .m-md-30 {
        margin: 30px !important
    }

    .mt-md-30, .my-md-30 {
        margin-top: 30px !important
    }

    .mr-md-30, .mx-md-30 {
        margin-right: 30px !important
    }

    .mb-md-30, .my-md-30 {
        margin-bottom: 30px !important
    }

    .ml-md-30, .mx-md-30 {
        margin-left: 30px !important
    }

    [dir='rtl'] .mr-md-30 {
        margin-left: 30px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-30 {
        margin-left: 0 !important;
        margin-right: 30px !important
    }

    .m-md-35 {
        margin: 35px !important
    }

    .mt-md-35, .my-md-35 {
        margin-top: 35px !important
    }

    .mr-md-35, .mx-md-35 {
        margin-right: 35px !important
    }

    .mb-md-35, .my-md-35 {
        margin-bottom: 35px !important
    }

    .ml-md-35, .mx-md-35 {
        margin-left: 35px !important
    }

    [dir='rtl'] .mr-md-35 {
        margin-left: 35px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-35 {
        margin-left: 0 !important;
        margin-right: 35px !important
    }

    .m-md-40 {
        margin: 40px !important
    }

    .mt-md-40, .my-md-40 {
        margin-top: 40px !important
    }

    .mr-md-40, .mx-md-40 {
        margin-right: 40px !important
    }

    .mb-md-40, .my-md-40 {
        margin-bottom: 40px !important
    }

    .ml-md-40, .mx-md-40 {
        margin-left: 40px !important
    }

    [dir='rtl'] .mr-md-40 {
        margin-left: 40px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-40 {
        margin-left: 0 !important;
        margin-right: 40px !important
    }

    .m-md-45 {
        margin: 45px !important
    }

    .mt-md-45, .my-md-45 {
        margin-top: 45px !important
    }

    .mr-md-45, .mx-md-45 {
        margin-right: 45px !important
    }

    .mb-md-45, .my-md-45 {
        margin-bottom: 45px !important
    }

    .ml-md-45, .mx-md-45 {
        margin-left: 45px !important
    }

    [dir='rtl'] .mr-md-45 {
        margin-left: 45px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-45 {
        margin-left: 0 !important;
        margin-right: 45px !important
    }

    .m-md-50 {
        margin: 50px !important
    }

    .mt-md-50, .my-md-50 {
        margin-top: 50px !important
    }

    .mr-md-50, .mx-md-50 {
        margin-right: 50px !important
    }

    .mb-md-50, .my-md-50 {
        margin-bottom: 50px !important
    }

    .ml-md-50, .mx-md-50 {
        margin-left: 50px !important
    }

    [dir='rtl'] .mr-md-50 {
        margin-left: 50px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-50 {
        margin-left: 0 !important;
        margin-right: 50px !important
    }

    .m-md-55 {
        margin: 55px !important
    }

    .mt-md-55, .my-md-55 {
        margin-top: 55px !important
    }

    .mr-md-55, .mx-md-55 {
        margin-right: 55px !important
    }

    .mb-md-55, .my-md-55 {
        margin-bottom: 55px !important
    }

    .ml-md-55, .mx-md-55 {
        margin-left: 55px !important
    }

    [dir='rtl'] .mr-md-55 {
        margin-left: 55px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-55 {
        margin-left: 0 !important;
        margin-right: 55px !important
    }

    .m-md-60 {
        margin: 60px !important
    }

    .mt-md-60, .my-md-60 {
        margin-top: 60px !important
    }

    .mr-md-60, .mx-md-60 {
        margin-right: 60px !important
    }

    .mb-md-60, .my-md-60 {
        margin-bottom: 60px !important
    }

    .ml-md-60, .mx-md-60 {
        margin-left: 60px !important
    }

    [dir='rtl'] .mr-md-60 {
        margin-left: 60px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-60 {
        margin-left: 0 !important;
        margin-right: 60px !important
    }

    .m-md-65 {
        margin: 65px !important
    }

    .mt-md-65, .my-md-65 {
        margin-top: 65px !important
    }

    .mr-md-65, .mx-md-65 {
        margin-right: 65px !important
    }

    .mb-md-65, .my-md-65 {
        margin-bottom: 65px !important
    }

    .ml-md-65, .mx-md-65 {
        margin-left: 65px !important
    }

    [dir='rtl'] .mr-md-65 {
        margin-left: 65px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-65 {
        margin-left: 0 !important;
        margin-right: 65px !important
    }

    .m-md-70 {
        margin: 70px !important
    }

    .mt-md-70, .my-md-70 {
        margin-top: 70px !important
    }

    .mr-md-70, .mx-md-70 {
        margin-right: 70px !important
    }

    .mb-md-70, .my-md-70 {
        margin-bottom: 70px !important
    }

    .ml-md-70, .mx-md-70 {
        margin-left: 70px !important
    }

    [dir='rtl'] .mr-md-70 {
        margin-left: 70px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-70 {
        margin-left: 0 !important;
        margin-right: 70px !important
    }

    .m-md-75 {
        margin: 75px !important
    }

    .mt-md-75, .my-md-75 {
        margin-top: 75px !important
    }

    .mr-md-75, .mx-md-75 {
        margin-right: 75px !important
    }

    .mb-md-75, .my-md-75 {
        margin-bottom: 75px !important
    }

    .ml-md-75, .mx-md-75 {
        margin-left: 75px !important
    }

    [dir='rtl'] .mr-md-75 {
        margin-left: 75px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-75 {
        margin-left: 0 !important;
        margin-right: 75px !important
    }

    .m-md-80 {
        margin: 80px !important
    }

    .mt-md-80, .my-md-80 {
        margin-top: 80px !important
    }

    .mr-md-80, .mx-md-80 {
        margin-right: 80px !important
    }

    .mb-md-80, .my-md-80 {
        margin-bottom: 80px !important
    }

    .ml-md-80, .mx-md-80 {
        margin-left: 80px !important
    }

    [dir='rtl'] .mr-md-80 {
        margin-left: 80px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-80 {
        margin-left: 0 !important;
        margin-right: 80px !important
    }

    .m-md-85 {
        margin: 85px !important
    }

    .mt-md-85, .my-md-85 {
        margin-top: 85px !important
    }

    .mr-md-85, .mx-md-85 {
        margin-right: 85px !important
    }

    .mb-md-85, .my-md-85 {
        margin-bottom: 85px !important
    }

    .ml-md-85, .mx-md-85 {
        margin-left: 85px !important
    }

    [dir='rtl'] .mr-md-85 {
        margin-left: 85px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-85 {
        margin-left: 0 !important;
        margin-right: 85px !important
    }

    .m-md-90 {
        margin: 90px !important
    }

    .mt-md-90, .my-md-90 {
        margin-top: 90px !important
    }

    .mr-md-90, .mx-md-90 {
        margin-right: 90px !important
    }

    .mb-md-90, .my-md-90 {
        margin-bottom: 90px !important
    }

    .ml-md-90, .mx-md-90 {
        margin-left: 90px !important
    }

    [dir='rtl'] .mr-md-90 {
        margin-left: 90px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-90 {
        margin-left: 0 !important;
        margin-right: 90px !important
    }

    .m-md-95 {
        margin: 95px !important
    }

    .mt-md-95, .my-md-95 {
        margin-top: 95px !important
    }

    .mr-md-95, .mx-md-95 {
        margin-right: 95px !important
    }

    .mb-md-95, .my-md-95 {
        margin-bottom: 95px !important
    }

    .ml-md-95, .mx-md-95 {
        margin-left: 95px !important
    }

    [dir='rtl'] .mr-md-95 {
        margin-left: 95px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-95 {
        margin-left: 0 !important;
        margin-right: 95px !important
    }

    .m-md-100 {
        margin: 100px !important
    }

    .mt-md-100, .my-md-100 {
        margin-top: 100px !important
    }

    .mr-md-100, .mx-md-100 {
        margin-right: 100px !important
    }

    .mb-md-100, .my-md-100 {
        margin-bottom: 100px !important
    }

    .ml-md-100, .mx-md-100 {
        margin-left: 100px !important
    }

    [dir='rtl'] .mr-md-100 {
        margin-left: 100px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-100 {
        margin-left: 0 !important;
        margin-right: 100px !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0, .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0, .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0, .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0, .px-md-0 {
        padding-left: 0 !important
    }

    [dir='rtl'] .pr-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .p-md-1 {
        padding: 1px !important
    }

    .pt-md-1, .py-md-1 {
        padding-top: 1px !important
    }

    .pr-md-1, .px-md-1 {
        padding-right: 1px !important
    }

    .pb-md-1, .py-md-1 {
        padding-bottom: 1px !important
    }

    .pl-md-1, .px-md-1 {
        padding-left: 1px !important
    }

    [dir='rtl'] .pr-md-1 {
        padding-left: 1px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-1 {
        padding-left: 0 !important;
        padding-right: 1px !important
    }

    .p-md-2 {
        padding: 2px !important
    }

    .pt-md-2, .py-md-2 {
        padding-top: 2px !important
    }

    .pr-md-2, .px-md-2 {
        padding-right: 2px !important
    }

    .pb-md-2, .py-md-2 {
        padding-bottom: 2px !important
    }

    .pl-md-2, .px-md-2 {
        padding-left: 2px !important
    }

    [dir='rtl'] .pr-md-2 {
        padding-left: 2px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-2 {
        padding-left: 0 !important;
        padding-right: 2px !important
    }

    .p-md-3 {
        padding: 3px !important
    }

    .pt-md-3, .py-md-3 {
        padding-top: 3px !important
    }

    .pr-md-3, .px-md-3 {
        padding-right: 3px !important
    }

    .pb-md-3, .py-md-3 {
        padding-bottom: 3px !important
    }

    .pl-md-3, .px-md-3 {
        padding-left: 3px !important
    }

    [dir='rtl'] .pr-md-3 {
        padding-left: 3px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-3 {
        padding-left: 0 !important;
        padding-right: 3px !important
    }

    .p-md-4 {
        padding: 4px !important
    }

    .pt-md-4, .py-md-4 {
        padding-top: 4px !important
    }

    .pr-md-4, .px-md-4 {
        padding-right: 4px !important
    }

    .pb-md-4, .py-md-4 {
        padding-bottom: 4px !important
    }

    .pl-md-4, .px-md-4 {
        padding-left: 4px !important
    }

    [dir='rtl'] .pr-md-4 {
        padding-left: 4px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-4 {
        padding-left: 0 !important;
        padding-right: 4px !important
    }

    .p-md-5 {
        padding: 5px !important
    }

    .pt-md-5, .py-md-5 {
        padding-top: 5px !important
    }

    .pr-md-5, .px-md-5 {
        padding-right: 5px !important
    }

    .pb-md-5, .py-md-5 {
        padding-bottom: 5px !important
    }

    .pl-md-5, .px-md-5 {
        padding-left: 5px !important
    }

    [dir='rtl'] .pr-md-5 {
        padding-left: 5px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-5 {
        padding-left: 0 !important;
        padding-right: 5px !important
    }

    .p-md-6 {
        padding: 6px !important
    }

    .pt-md-6, .py-md-6 {
        padding-top: 6px !important
    }

    .pr-md-6, .px-md-6 {
        padding-right: 6px !important
    }

    .pb-md-6, .py-md-6 {
        padding-bottom: 6px !important
    }

    .pl-md-6, .px-md-6 {
        padding-left: 6px !important
    }

    [dir='rtl'] .pr-md-6 {
        padding-left: 6px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-6 {
        padding-left: 0 !important;
        padding-right: 6px !important
    }

    .p-md-7 {
        padding: 7px !important
    }

    .pt-md-7, .py-md-7 {
        padding-top: 7px !important
    }

    .pr-md-7, .px-md-7 {
        padding-right: 7px !important
    }

    .pb-md-7, .py-md-7 {
        padding-bottom: 7px !important
    }

    .pl-md-7, .px-md-7 {
        padding-left: 7px !important
    }

    [dir='rtl'] .pr-md-7 {
        padding-left: 7px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-7 {
        padding-left: 0 !important;
        padding-right: 7px !important
    }

    .p-md-8 {
        padding: 8px !important
    }

    .pt-md-8, .py-md-8 {
        padding-top: 8px !important
    }

    .pr-md-8, .px-md-8 {
        padding-right: 8px !important
    }

    .pb-md-8, .py-md-8 {
        padding-bottom: 8px !important
    }

    .pl-md-8, .px-md-8 {
        padding-left: 8px !important
    }

    [dir='rtl'] .pr-md-8 {
        padding-left: 8px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-8 {
        padding-left: 0 !important;
        padding-right: 8px !important
    }

    .p-md-9 {
        padding: 9px !important
    }

    .pt-md-9, .py-md-9 {
        padding-top: 9px !important
    }

    .pr-md-9, .px-md-9 {
        padding-right: 9px !important
    }

    .pb-md-9, .py-md-9 {
        padding-bottom: 9px !important
    }

    .pl-md-9, .px-md-9 {
        padding-left: 9px !important
    }

    [dir='rtl'] .pr-md-9 {
        padding-left: 9px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-9 {
        padding-left: 0 !important;
        padding-right: 9px !important
    }

    .p-md-10 {
        padding: 10px !important
    }

    .pt-md-10, .py-md-10 {
        padding-top: 10px !important
    }

    .pr-md-10, .px-md-10 {
        padding-right: 10px !important
    }

    .pb-md-10, .py-md-10 {
        padding-bottom: 10px !important
    }

    .pl-md-10, .px-md-10 {
        padding-left: 10px !important
    }

    [dir='rtl'] .pr-md-10 {
        padding-left: 10px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-10 {
        padding-left: 0 !important;
        padding-right: 10px !important
    }

    .p-md-15 {
        padding: 15px !important
    }

    .pt-md-15, .py-md-15 {
        padding-top: 15px !important
    }

    .pr-md-15, .px-md-15 {
        padding-right: 15px !important
    }

    .pb-md-15, .py-md-15 {
        padding-bottom: 15px !important
    }

    .pl-md-15, .px-md-15 {
        padding-left: 15px !important
    }

    [dir='rtl'] .pr-md-15 {
        padding-left: 15px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-15 {
        padding-left: 0 !important;
        padding-right: 15px !important
    }

    .p-md-20 {
        padding: 20px !important
    }

    .pt-md-20, .py-md-20 {
        padding-top: 20px !important
    }

    .pr-md-20, .px-md-20 {
        padding-right: 20px !important
    }

    .pb-md-20, .py-md-20 {
        padding-bottom: 20px !important
    }

    .pl-md-20, .px-md-20 {
        padding-left: 20px !important
    }

    [dir='rtl'] .pr-md-20 {
        padding-left: 20px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-20 {
        padding-left: 0 !important;
        padding-right: 20px !important
    }

    .p-md-25 {
        padding: 25px !important
    }

    .pt-md-25, .py-md-25 {
        padding-top: 25px !important
    }

    .pr-md-25, .px-md-25 {
        padding-right: 25px !important
    }

    .pb-md-25, .py-md-25 {
        padding-bottom: 25px !important
    }

    .pl-md-25, .px-md-25 {
        padding-left: 25px !important
    }

    [dir='rtl'] .pr-md-25 {
        padding-left: 25px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-25 {
        padding-left: 0 !important;
        padding-right: 25px !important
    }

    .p-md-30 {
        padding: 30px !important
    }

    .pt-md-30, .py-md-30 {
        padding-top: 30px !important
    }

    .pr-md-30, .px-md-30 {
        padding-right: 30px !important
    }

    .pb-md-30, .py-md-30 {
        padding-bottom: 30px !important
    }

    .pl-md-30, .px-md-30 {
        padding-left: 30px !important
    }

    [dir='rtl'] .pr-md-30 {
        padding-left: 30px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-30 {
        padding-left: 0 !important;
        padding-right: 30px !important
    }

    .p-md-35 {
        padding: 35px !important
    }

    .pt-md-35, .py-md-35 {
        padding-top: 35px !important
    }

    .pr-md-35, .px-md-35 {
        padding-right: 35px !important
    }

    .pb-md-35, .py-md-35 {
        padding-bottom: 35px !important
    }

    .pl-md-35, .px-md-35 {
        padding-left: 35px !important
    }

    [dir='rtl'] .pr-md-35 {
        padding-left: 35px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-35 {
        padding-left: 0 !important;
        padding-right: 35px !important
    }

    .p-md-40 {
        padding: 40px !important
    }

    .pt-md-40, .py-md-40 {
        padding-top: 40px !important
    }

    .pr-md-40, .px-md-40 {
        padding-right: 40px !important
    }

    .pb-md-40, .py-md-40 {
        padding-bottom: 40px !important
    }

    .pl-md-40, .px-md-40 {
        padding-left: 40px !important
    }

    [dir='rtl'] .pr-md-40 {
        padding-left: 40px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-40 {
        padding-left: 0 !important;
        padding-right: 40px !important
    }

    .p-md-45 {
        padding: 45px !important
    }

    .pt-md-45, .py-md-45 {
        padding-top: 45px !important
    }

    .pr-md-45, .px-md-45 {
        padding-right: 45px !important
    }

    .pb-md-45, .py-md-45 {
        padding-bottom: 45px !important
    }

    .pl-md-45, .px-md-45 {
        padding-left: 45px !important
    }

    [dir='rtl'] .pr-md-45 {
        padding-left: 45px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-45 {
        padding-left: 0 !important;
        padding-right: 45px !important
    }

    .p-md-50 {
        padding: 50px !important
    }

    .pt-md-50, .py-md-50 {
        padding-top: 50px !important
    }

    .pr-md-50, .px-md-50 {
        padding-right: 50px !important
    }

    .pb-md-50, .py-md-50 {
        padding-bottom: 50px !important
    }

    .pl-md-50, .px-md-50 {
        padding-left: 50px !important
    }

    [dir='rtl'] .pr-md-50 {
        padding-left: 50px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-50 {
        padding-left: 0 !important;
        padding-right: 50px !important
    }

    .p-md-55 {
        padding: 55px !important
    }

    .pt-md-55, .py-md-55 {
        padding-top: 55px !important
    }

    .pr-md-55, .px-md-55 {
        padding-right: 55px !important
    }

    .pb-md-55, .py-md-55 {
        padding-bottom: 55px !important
    }

    .pl-md-55, .px-md-55 {
        padding-left: 55px !important
    }

    [dir='rtl'] .pr-md-55 {
        padding-left: 55px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-55 {
        padding-left: 0 !important;
        padding-right: 55px !important
    }

    .p-md-60 {
        padding: 60px !important
    }

    .pt-md-60, .py-md-60 {
        padding-top: 60px !important
    }

    .pr-md-60, .px-md-60 {
        padding-right: 60px !important
    }

    .pb-md-60, .py-md-60 {
        padding-bottom: 60px !important
    }

    .pl-md-60, .px-md-60 {
        padding-left: 60px !important
    }

    [dir='rtl'] .pr-md-60 {
        padding-left: 60px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-60 {
        padding-left: 0 !important;
        padding-right: 60px !important
    }

    .p-md-65 {
        padding: 65px !important
    }

    .pt-md-65, .py-md-65 {
        padding-top: 65px !important
    }

    .pr-md-65, .px-md-65 {
        padding-right: 65px !important
    }

    .pb-md-65, .py-md-65 {
        padding-bottom: 65px !important
    }

    .pl-md-65, .px-md-65 {
        padding-left: 65px !important
    }

    [dir='rtl'] .pr-md-65 {
        padding-left: 65px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-65 {
        padding-left: 0 !important;
        padding-right: 65px !important
    }

    .p-md-70 {
        padding: 70px !important
    }

    .pt-md-70, .py-md-70 {
        padding-top: 70px !important
    }

    .pr-md-70, .px-md-70 {
        padding-right: 70px !important
    }

    .pb-md-70, .py-md-70 {
        padding-bottom: 70px !important
    }

    .pl-md-70, .px-md-70 {
        padding-left: 70px !important
    }

    [dir='rtl'] .pr-md-70 {
        padding-left: 70px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-70 {
        padding-left: 0 !important;
        padding-right: 70px !important
    }

    .p-md-75 {
        padding: 75px !important
    }

    .pt-md-75, .py-md-75 {
        padding-top: 75px !important
    }

    .pr-md-75, .px-md-75 {
        padding-right: 75px !important
    }

    .pb-md-75, .py-md-75 {
        padding-bottom: 75px !important
    }

    .pl-md-75, .px-md-75 {
        padding-left: 75px !important
    }

    [dir='rtl'] .pr-md-75 {
        padding-left: 75px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-75 {
        padding-left: 0 !important;
        padding-right: 75px !important
    }

    .p-md-80 {
        padding: 80px !important
    }

    .pt-md-80, .py-md-80 {
        padding-top: 80px !important
    }

    .pr-md-80, .px-md-80 {
        padding-right: 80px !important
    }

    .pb-md-80, .py-md-80 {
        padding-bottom: 80px !important
    }

    .pl-md-80, .px-md-80 {
        padding-left: 80px !important
    }

    [dir='rtl'] .pr-md-80 {
        padding-left: 80px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-80 {
        padding-left: 0 !important;
        padding-right: 80px !important
    }

    .p-md-85 {
        padding: 85px !important
    }

    .pt-md-85, .py-md-85 {
        padding-top: 85px !important
    }

    .pr-md-85, .px-md-85 {
        padding-right: 85px !important
    }

    .pb-md-85, .py-md-85 {
        padding-bottom: 85px !important
    }

    .pl-md-85, .px-md-85 {
        padding-left: 85px !important
    }

    [dir='rtl'] .pr-md-85 {
        padding-left: 85px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-85 {
        padding-left: 0 !important;
        padding-right: 85px !important
    }

    .p-md-90 {
        padding: 90px !important
    }

    .pt-md-90, .py-md-90 {
        padding-top: 90px !important
    }

    .pr-md-90, .px-md-90 {
        padding-right: 90px !important
    }

    .pb-md-90, .py-md-90 {
        padding-bottom: 90px !important
    }

    .pl-md-90, .px-md-90 {
        padding-left: 90px !important
    }

    [dir='rtl'] .pr-md-90 {
        padding-left: 90px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-90 {
        padding-left: 0 !important;
        padding-right: 90px !important
    }

    .p-md-95 {
        padding: 95px !important
    }

    .pt-md-95, .py-md-95 {
        padding-top: 95px !important
    }

    .pr-md-95, .px-md-95 {
        padding-right: 95px !important
    }

    .pb-md-95, .py-md-95 {
        padding-bottom: 95px !important
    }

    .pl-md-95, .px-md-95 {
        padding-left: 95px !important
    }

    [dir='rtl'] .pr-md-95 {
        padding-left: 95px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-95 {
        padding-left: 0 !important;
        padding-right: 95px !important
    }

    .p-md-100 {
        padding: 100px !important
    }

    .pt-md-100, .py-md-100 {
        padding-top: 100px !important
    }

    .pr-md-100, .px-md-100 {
        padding-right: 100px !important
    }

    .pb-md-100, .py-md-100 {
        padding-bottom: 100px !important
    }

    .pl-md-100, .px-md-100 {
        padding-left: 100px !important
    }

    [dir='rtl'] .pr-md-100 {
        padding-left: 100px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-md-100 {
        padding-left: 0 !important;
        padding-right: 100px !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto, .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto, .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto, .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto, .mx-md-auto {
        margin-left: auto !important
    }

    [dir='rtl'] .mr-md-auto {
        margin-left: auto !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-md-auto {
        margin-left: 0 !important;
        margin-right: auto !important
    }
}

@media (min-width: 1025px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0, .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0, .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0, .mx-lg-0 {
        margin-left: 0 !important
    }

    [dir='rtl'] .mr-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .m-lg-1 {
        margin: 1px !important
    }

    .mt-lg-1, .my-lg-1 {
        margin-top: 1px !important
    }

    .mr-lg-1, .mx-lg-1 {
        margin-right: 1px !important
    }

    .mb-lg-1, .my-lg-1 {
        margin-bottom: 1px !important
    }

    .ml-lg-1, .mx-lg-1 {
        margin-left: 1px !important
    }

    [dir='rtl'] .mr-lg-1 {
        margin-left: 1px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-1 {
        margin-left: 0 !important;
        margin-right: 1px !important
    }

    .m-lg-2 {
        margin: 2px !important
    }

    .mt-lg-2, .my-lg-2 {
        margin-top: 2px !important
    }

    .mr-lg-2, .mx-lg-2 {
        margin-right: 2px !important
    }

    .mb-lg-2, .my-lg-2 {
        margin-bottom: 2px !important
    }

    .ml-lg-2, .mx-lg-2 {
        margin-left: 2px !important
    }

    [dir='rtl'] .mr-lg-2 {
        margin-left: 2px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-2 {
        margin-left: 0 !important;
        margin-right: 2px !important
    }

    .m-lg-3 {
        margin: 3px !important
    }

    .mt-lg-3, .my-lg-3 {
        margin-top: 3px !important
    }

    .mr-lg-3, .mx-lg-3 {
        margin-right: 3px !important
    }

    .mb-lg-3, .my-lg-3 {
        margin-bottom: 3px !important
    }

    .ml-lg-3, .mx-lg-3 {
        margin-left: 3px !important
    }

    [dir='rtl'] .mr-lg-3 {
        margin-left: 3px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-3 {
        margin-left: 0 !important;
        margin-right: 3px !important
    }

    .m-lg-4 {
        margin: 4px !important
    }

    .mt-lg-4, .my-lg-4 {
        margin-top: 4px !important
    }

    .mr-lg-4, .mx-lg-4 {
        margin-right: 4px !important
    }

    .mb-lg-4, .my-lg-4 {
        margin-bottom: 4px !important
    }

    .ml-lg-4, .mx-lg-4 {
        margin-left: 4px !important
    }

    [dir='rtl'] .mr-lg-4 {
        margin-left: 4px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-4 {
        margin-left: 0 !important;
        margin-right: 4px !important
    }

    .m-lg-5 {
        margin: 5px !important
    }

    .mt-lg-5, .my-lg-5 {
        margin-top: 5px !important
    }

    .mr-lg-5, .mx-lg-5 {
        margin-right: 5px !important
    }

    .mb-lg-5, .my-lg-5 {
        margin-bottom: 5px !important
    }

    .ml-lg-5, .mx-lg-5 {
        margin-left: 5px !important
    }

    [dir='rtl'] .mr-lg-5 {
        margin-left: 5px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-5 {
        margin-left: 0 !important;
        margin-right: 5px !important
    }

    .m-lg-6 {
        margin: 6px !important
    }

    .mt-lg-6, .my-lg-6 {
        margin-top: 6px !important
    }

    .mr-lg-6, .mx-lg-6 {
        margin-right: 6px !important
    }

    .mb-lg-6, .my-lg-6 {
        margin-bottom: 6px !important
    }

    .ml-lg-6, .mx-lg-6 {
        margin-left: 6px !important
    }

    [dir='rtl'] .mr-lg-6 {
        margin-left: 6px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-6 {
        margin-left: 0 !important;
        margin-right: 6px !important
    }

    .m-lg-7 {
        margin: 7px !important
    }

    .mt-lg-7, .my-lg-7 {
        margin-top: 7px !important
    }

    .mr-lg-7, .mx-lg-7 {
        margin-right: 7px !important
    }

    .mb-lg-7, .my-lg-7 {
        margin-bottom: 7px !important
    }

    .ml-lg-7, .mx-lg-7 {
        margin-left: 7px !important
    }

    [dir='rtl'] .mr-lg-7 {
        margin-left: 7px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-7 {
        margin-left: 0 !important;
        margin-right: 7px !important
    }

    .m-lg-8 {
        margin: 8px !important
    }

    .mt-lg-8, .my-lg-8 {
        margin-top: 8px !important
    }

    .mr-lg-8, .mx-lg-8 {
        margin-right: 8px !important
    }

    .mb-lg-8, .my-lg-8 {
        margin-bottom: 8px !important
    }

    .ml-lg-8, .mx-lg-8 {
        margin-left: 8px !important
    }

    [dir='rtl'] .mr-lg-8 {
        margin-left: 8px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-8 {
        margin-left: 0 !important;
        margin-right: 8px !important
    }

    .m-lg-9 {
        margin: 9px !important
    }

    .mt-lg-9, .my-lg-9 {
        margin-top: 9px !important
    }

    .mr-lg-9, .mx-lg-9 {
        margin-right: 9px !important
    }

    .mb-lg-9, .my-lg-9 {
        margin-bottom: 9px !important
    }

    .ml-lg-9, .mx-lg-9 {
        margin-left: 9px !important
    }

    [dir='rtl'] .mr-lg-9 {
        margin-left: 9px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-9 {
        margin-left: 0 !important;
        margin-right: 9px !important
    }

    .m-lg-10 {
        margin: 10px !important
    }

    .mt-lg-10, .my-lg-10 {
        margin-top: 10px !important
    }

    .mr-lg-10, .mx-lg-10 {
        margin-right: 10px !important
    }

    .mb-lg-10, .my-lg-10 {
        margin-bottom: 10px !important
    }

    .ml-lg-10, .mx-lg-10 {
        margin-left: 10px !important
    }

    [dir='rtl'] .mr-lg-10 {
        margin-left: 10px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-10 {
        margin-left: 0 !important;
        margin-right: 10px !important
    }

    .m-lg-15 {
        margin: 15px !important
    }

    .mt-lg-15, .my-lg-15 {
        margin-top: 15px !important
    }

    .mr-lg-15, .mx-lg-15 {
        margin-right: 15px !important
    }

    .mb-lg-15, .my-lg-15 {
        margin-bottom: 15px !important
    }

    .ml-lg-15, .mx-lg-15 {
        margin-left: 15px !important
    }

    [dir='rtl'] .mr-lg-15 {
        margin-left: 15px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-15 {
        margin-left: 0 !important;
        margin-right: 15px !important
    }

    .m-lg-20 {
        margin: 20px !important
    }

    .mt-lg-20, .my-lg-20 {
        margin-top: 20px !important
    }

    .mr-lg-20, .mx-lg-20 {
        margin-right: 20px !important
    }

    .mb-lg-20, .my-lg-20 {
        margin-bottom: 20px !important
    }

    .ml-lg-20, .mx-lg-20 {
        margin-left: 20px !important
    }

    [dir='rtl'] .mr-lg-20 {
        margin-left: 20px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-20 {
        margin-left: 0 !important;
        margin-right: 20px !important
    }

    .m-lg-25 {
        margin: 25px !important
    }

    .my-lg-25 {
        margin-top: 25px !important
    }

    .mr-lg-25, .mx-lg-25 {
        margin-right: 25px !important
    }

    .mb-lg-25, .my-lg-25 {
        margin-bottom: 25px !important
    }

    .ml-lg-25, .mx-lg-25 {
        margin-left: 25px !important
    }

    [dir='rtl'] .mr-lg-25 {
        margin-left: 25px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-25 {
        margin-left: 0 !important;
        margin-right: 25px !important
    }

    .m-lg-30 {
        margin: 30px !important
    }

    .mt-lg-30, .my-lg-30 {
        margin-top: 30px !important
    }

    .mr-lg-30, .mx-lg-30 {
        margin-right: 30px !important
    }

    .mb-lg-30, .my-lg-30 {
        margin-bottom: 30px !important
    }

    .ml-lg-30, .mx-lg-30 {
        margin-left: 30px !important
    }

    [dir='rtl'] .mr-lg-30 {
        margin-left: 30px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-30 {
        margin-left: 0 !important;
        margin-right: 30px !important
    }

    .m-lg-35 {
        margin: 35px !important
    }

    .mt-lg-35, .my-lg-35 {
        margin-top: 35px !important
    }

    .mr-lg-35, .mx-lg-35 {
        margin-right: 35px !important
    }

    .mb-lg-35, .my-lg-35 {
        margin-bottom: 35px !important
    }

    .ml-lg-35, .mx-lg-35 {
        margin-left: 35px !important
    }

    [dir='rtl'] .mr-lg-35 {
        margin-left: 35px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-35 {
        margin-left: 0 !important;
        margin-right: 35px !important
    }

    .m-lg-40 {
        margin: 40px !important
    }

    .mt-lg-40, .my-lg-40 {
        margin-top: 40px !important
    }

    .mr-lg-40, .mx-lg-40 {
        margin-right: 40px !important
    }

    .mb-lg-40, .my-lg-40 {
        margin-bottom: 40px !important
    }

    .ml-lg-40, .mx-lg-40 {
        margin-left: 40px !important
    }

    [dir='rtl'] .mr-lg-40 {
        margin-left: 40px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-40 {
        margin-left: 0 !important;
        margin-right: 40px !important
    }

    .m-lg-45 {
        margin: 45px !important
    }

    .mt-lg-45, .my-lg-45 {
        margin-top: 45px !important
    }

    .mr-lg-45, .mx-lg-45 {
        margin-right: 45px !important
    }

    .mb-lg-45, .my-lg-45 {
        margin-bottom: 45px !important
    }

    .ml-lg-45, .mx-lg-45 {
        margin-left: 45px !important
    }

    [dir='rtl'] .mr-lg-45 {
        margin-left: 45px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-45 {
        margin-left: 0 !important;
        margin-right: 45px !important
    }

    .m-lg-50 {
        margin: 50px !important
    }

    .mt-lg-50, .my-lg-50 {
        margin-top: 50px !important
    }

    .mr-lg-50, .mx-lg-50 {
        margin-right: 50px !important
    }

    .mb-lg-50, .my-lg-50 {
        margin-bottom: 50px !important
    }

    .ml-lg-50, .mx-lg-50 {
        margin-left: 50px !important
    }

    [dir='rtl'] .mr-lg-50 {
        margin-left: 50px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-50 {
        margin-left: 0 !important;
        margin-right: 50px !important
    }

    .m-lg-55 {
        margin: 55px !important
    }

    .mt-lg-55, .my-lg-55 {
        margin-top: 55px !important
    }

    .mr-lg-55, .mx-lg-55 {
        margin-right: 55px !important
    }

    .mb-lg-55, .my-lg-55 {
        margin-bottom: 55px !important
    }

    .ml-lg-55, .mx-lg-55 {
        margin-left: 55px !important
    }

    [dir='rtl'] .mr-lg-55 {
        margin-left: 55px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-55 {
        margin-left: 0 !important;
        margin-right: 55px !important
    }

    .m-lg-60 {
        margin: 60px !important
    }

    .mt-lg-60, .my-lg-60 {
        margin-top: 60px !important
    }

    .mr-lg-60, .mx-lg-60 {
        margin-right: 60px !important
    }

    .mb-lg-60, .my-lg-60 {
        margin-bottom: 60px !important
    }

    .ml-lg-60, .mx-lg-60 {
        margin-left: 60px !important
    }

    [dir='rtl'] .mr-lg-60 {
        margin-left: 60px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-60 {
        margin-left: 0 !important;
        margin-right: 60px !important
    }

    .m-lg-65 {
        margin: 65px !important
    }

    .mt-lg-65, .my-lg-65 {
        margin-top: 65px !important
    }

    .mr-lg-65, .mx-lg-65 {
        margin-right: 65px !important
    }

    .mb-lg-65, .my-lg-65 {
        margin-bottom: 65px !important
    }

    .ml-lg-65, .mx-lg-65 {
        margin-left: 65px !important
    }

    [dir='rtl'] .mr-lg-65 {
        margin-left: 65px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-65 {
        margin-left: 0 !important;
        margin-right: 65px !important
    }

    .m-lg-70 {
        margin: 70px !important
    }

    .mt-lg-70, .my-lg-70 {
        margin-top: 70px !important
    }

    .mr-lg-70, .mx-lg-70 {
        margin-right: 70px !important
    }

    .mb-lg-70, .my-lg-70 {
        margin-bottom: 70px !important
    }

    .ml-lg-70, .mx-lg-70 {
        margin-left: 70px !important
    }

    [dir='rtl'] .mr-lg-70 {
        margin-left: 70px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-70 {
        margin-left: 0 !important;
        margin-right: 70px !important
    }

    .m-lg-75 {
        margin: 75px !important
    }

    .mt-lg-75, .my-lg-75 {
        margin-top: 75px !important
    }

    .mr-lg-75, .mx-lg-75 {
        margin-right: 75px !important
    }

    .mb-lg-75, .my-lg-75 {
        margin-bottom: 75px !important
    }

    .ml-lg-75, .mx-lg-75 {
        margin-left: 75px !important
    }

    [dir='rtl'] .mr-lg-75 {
        margin-left: 75px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-75 {
        margin-left: 0 !important;
        margin-right: 75px !important
    }

    .m-lg-80 {
        margin: 80px !important
    }

    .mt-lg-80, .my-lg-80 {
        margin-top: 80px !important
    }

    .mr-lg-80, .mx-lg-80 {
        margin-right: 80px !important
    }

    .mb-lg-80, .my-lg-80 {
        margin-bottom: 80px !important
    }

    .ml-lg-80, .mx-lg-80 {
        margin-left: 80px !important
    }

    [dir='rtl'] .mr-lg-80 {
        margin-left: 80px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-80 {
        margin-left: 0 !important;
        margin-right: 80px !important
    }

    .m-lg-85 {
        margin: 85px !important
    }

    .mt-lg-85, .my-lg-85 {
        margin-top: 85px !important
    }

    .mr-lg-85, .mx-lg-85 {
        margin-right: 85px !important
    }

    .mb-lg-85, .my-lg-85 {
        margin-bottom: 85px !important
    }

    .ml-lg-85, .mx-lg-85 {
        margin-left: 85px !important
    }

    [dir='rtl'] .mr-lg-85 {
        margin-left: 85px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-85 {
        margin-left: 0 !important;
        margin-right: 85px !important
    }

    .m-lg-90 {
        margin: 90px !important
    }

    .mt-lg-90, .my-lg-90 {
        margin-top: 90px !important
    }

    .mr-lg-90, .mx-lg-90 {
        margin-right: 90px !important
    }

    .mb-lg-90, .my-lg-90 {
        margin-bottom: 90px !important
    }

    .ml-lg-90, .mx-lg-90 {
        margin-left: 90px !important
    }

    [dir='rtl'] .mr-lg-90 {
        margin-left: 90px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-90 {
        margin-left: 0 !important;
        margin-right: 90px !important
    }

    .m-lg-95 {
        margin: 95px !important
    }

    .mt-lg-95, .my-lg-95 {
        margin-top: 95px !important
    }

    .mr-lg-95, .mx-lg-95 {
        margin-right: 95px !important
    }

    .mb-lg-95, .my-lg-95 {
        margin-bottom: 95px !important
    }

    .ml-lg-95, .mx-lg-95 {
        margin-left: 95px !important
    }

    [dir='rtl'] .mr-lg-95 {
        margin-left: 95px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-95 {
        margin-left: 0 !important;
        margin-right: 95px !important
    }

    .m-lg-100 {
        margin: 100px !important
    }

    .mt-lg-100, .my-lg-100 {
        margin-top: 100px !important
    }

    .mr-lg-100, .mx-lg-100 {
        margin-right: 100px !important
    }

    .mb-lg-100, .my-lg-100 {
        margin-bottom: 100px !important
    }

    .ml-lg-100, .mx-lg-100 {
        margin-left: 100px !important
    }

    [dir='rtl'] .mr-lg-100 {
        margin-left: 100px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-100 {
        margin-left: 0 !important;
        margin-right: 100px !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0, .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0, .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0, .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0, .px-lg-0 {
        padding-left: 0 !important
    }

    [dir='rtl'] .pr-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .p-lg-1 {
        padding: 1px !important
    }

    .pt-lg-1, .py-lg-1 {
        padding-top: 1px !important
    }

    .pr-lg-1, .px-lg-1 {
        padding-right: 1px !important
    }

    .pb-lg-1, .py-lg-1 {
        padding-bottom: 1px !important
    }

    .pl-lg-1, .px-lg-1 {
        padding-left: 1px !important
    }

    [dir='rtl'] .pr-lg-1 {
        padding-left: 1px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-1 {
        padding-left: 0 !important;
        padding-right: 1px !important
    }

    .p-lg-2 {
        padding: 2px !important
    }

    .pt-lg-2, .py-lg-2 {
        padding-top: 2px !important
    }

    .pr-lg-2, .px-lg-2 {
        padding-right: 2px !important
    }

    .pb-lg-2, .py-lg-2 {
        padding-bottom: 2px !important
    }

    .pl-lg-2, .px-lg-2 {
        padding-left: 2px !important
    }

    [dir='rtl'] .pr-lg-2 {
        padding-left: 2px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-2 {
        padding-left: 0 !important;
        padding-right: 2px !important
    }

    .p-lg-3 {
        padding: 3px !important
    }

    .pt-lg-3, .py-lg-3 {
        padding-top: 3px !important
    }

    .pr-lg-3, .px-lg-3 {
        padding-right: 3px !important
    }

    .pb-lg-3, .py-lg-3 {
        padding-bottom: 3px !important
    }

    .pl-lg-3, .px-lg-3 {
        padding-left: 3px !important
    }

    [dir='rtl'] .pr-lg-3 {
        padding-left: 3px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-3 {
        padding-left: 0 !important;
        padding-right: 3px !important
    }

    .p-lg-4 {
        padding: 4px !important
    }

    .pt-lg-4, .py-lg-4 {
        padding-top: 4px !important
    }

    .pr-lg-4, .px-lg-4 {
        padding-right: 4px !important
    }

    .pb-lg-4, .py-lg-4 {
        padding-bottom: 4px !important
    }

    .pl-lg-4, .px-lg-4 {
        padding-left: 4px !important
    }

    [dir='rtl'] .pr-lg-4 {
        padding-left: 4px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-4 {
        padding-left: 0 !important;
        padding-right: 4px !important
    }

    .p-lg-5 {
        padding: 5px !important
    }

    .pt-lg-5, .py-lg-5 {
        padding-top: 5px !important
    }

    .pr-lg-5, .px-lg-5 {
        padding-right: 5px !important
    }

    .pb-lg-5, .py-lg-5 {
        padding-bottom: 5px !important
    }

    .pl-lg-5, .px-lg-5 {
        padding-left: 5px !important
    }

    [dir='rtl'] .pr-lg-5 {
        padding-left: 5px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-5 {
        padding-left: 0 !important;
        padding-right: 5px !important
    }

    .p-lg-6 {
        padding: 6px !important
    }

    .pt-lg-6, .py-lg-6 {
        padding-top: 6px !important
    }

    .pr-lg-6, .px-lg-6 {
        padding-right: 6px !important
    }

    .pb-lg-6, .py-lg-6 {
        padding-bottom: 6px !important
    }

    .pl-lg-6, .px-lg-6 {
        padding-left: 6px !important
    }

    [dir='rtl'] .pr-lg-6 {
        padding-left: 6px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-6 {
        padding-left: 0 !important;
        padding-right: 6px !important
    }

    .p-lg-7 {
        padding: 7px !important
    }

    .pt-lg-7, .py-lg-7 {
        padding-top: 7px !important
    }

    .pr-lg-7, .px-lg-7 {
        padding-right: 7px !important
    }

    .pb-lg-7, .py-lg-7 {
        padding-bottom: 7px !important
    }

    .pl-lg-7, .px-lg-7 {
        padding-left: 7px !important
    }

    [dir='rtl'] .pr-lg-7 {
        padding-left: 7px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-7 {
        padding-left: 0 !important;
        padding-right: 7px !important
    }

    .p-lg-8 {
        padding: 8px !important
    }

    .pt-lg-8, .py-lg-8 {
        padding-top: 8px !important
    }

    .pr-lg-8, .px-lg-8 {
        padding-right: 8px !important
    }

    .pb-lg-8, .py-lg-8 {
        padding-bottom: 8px !important
    }

    .pl-lg-8, .px-lg-8 {
        padding-left: 8px !important
    }

    [dir='rtl'] .pr-lg-8 {
        padding-left: 8px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-8 {
        padding-left: 0 !important;
        padding-right: 8px !important
    }

    .p-lg-9 {
        padding: 9px !important
    }

    .pt-lg-9, .py-lg-9 {
        padding-top: 9px !important
    }

    .pr-lg-9, .px-lg-9 {
        padding-right: 9px !important
    }

    .pb-lg-9, .py-lg-9 {
        padding-bottom: 9px !important
    }

    .pl-lg-9, .px-lg-9 {
        padding-left: 9px !important
    }

    [dir='rtl'] .pr-lg-9 {
        padding-left: 9px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-9 {
        padding-left: 0 !important;
        padding-right: 9px !important
    }

    .p-lg-10 {
        padding: 10px !important
    }

    .pt-lg-10, .py-lg-10 {
        padding-top: 10px !important
    }

    .pr-lg-10, .px-lg-10 {
        padding-right: 10px !important
    }

    .pb-lg-10, .py-lg-10 {
        padding-bottom: 10px !important
    }

    .pl-lg-10, .px-lg-10 {
        padding-left: 10px !important
    }

    [dir='rtl'] .pr-lg-10 {
        padding-left: 10px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-10 {
        padding-left: 0 !important;
        padding-right: 10px !important
    }

    .p-lg-15 {
        padding: 15px !important
    }

    .pt-lg-15, .py-lg-15 {
        padding-top: 15px !important
    }

    .pr-lg-15, .px-lg-15 {
        padding-right: 15px !important
    }

    .pb-lg-15, .py-lg-15 {
        padding-bottom: 15px !important
    }

    .pl-lg-15, .px-lg-15 {
        padding-left: 15px !important
    }

    [dir='rtl'] .pr-lg-15 {
        padding-left: 15px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-15 {
        padding-left: 0 !important;
        padding-right: 15px !important
    }

    .p-lg-20 {
        padding: 20px !important
    }

    .pt-lg-20, .py-lg-20 {
        padding-top: 20px !important
    }

    .pr-lg-20, .px-lg-20 {
        padding-right: 20px !important
    }

    .pb-lg-20, .py-lg-20 {
        padding-bottom: 20px !important
    }

    .pl-lg-20, .px-lg-20 {
        padding-left: 20px !important
    }

    [dir='rtl'] .pr-lg-20 {
        padding-left: 20px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-20 {
        padding-left: 0 !important;
        padding-right: 20px !important
    }

    .p-lg-25 {
        padding: 25px !important
    }

    .pt-lg-25, .py-lg-25 {
        padding-top: 25px !important
    }

    .pr-lg-25, .px-lg-25 {
        padding-right: 25px !important
    }

    .pb-lg-25, .py-lg-25 {
        padding-bottom: 25px !important
    }

    .pl-lg-25, .px-lg-25 {
        padding-left: 25px !important
    }

    [dir='rtl'] .pr-lg-25 {
        padding-left: 25px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-25 {
        padding-left: 0 !important;
        padding-right: 25px !important
    }

    .p-lg-30 {
        padding: 30px !important
    }

    .pt-lg-30, .py-lg-30 {
        padding-top: 30px !important
    }

    .pr-lg-30, .px-lg-30 {
        padding-right: 30px !important
    }

    .pb-lg-30, .py-lg-30 {
        padding-bottom: 30px !important
    }

    .pl-lg-30, .px-lg-30 {
        padding-left: 30px !important
    }

    [dir='rtl'] .pr-lg-30 {
        padding-left: 30px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-30 {
        padding-left: 0 !important;
        padding-right: 30px !important
    }

    .p-lg-35 {
        padding: 35px !important
    }

    .pt-lg-35, .py-lg-35 {
        padding-top: 35px !important
    }

    .pr-lg-35, .px-lg-35 {
        padding-right: 35px !important
    }

    .pb-lg-35, .py-lg-35 {
        padding-bottom: 35px !important
    }

    .pl-lg-35, .px-lg-35 {
        padding-left: 35px !important
    }

    [dir='rtl'] .pr-lg-35 {
        padding-left: 35px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-35 {
        padding-left: 0 !important;
        padding-right: 35px !important
    }

    .p-lg-40 {
        padding: 40px !important
    }

    .pt-lg-40, .py-lg-40 {
        padding-top: 40px !important
    }

    .pr-lg-40, .px-lg-40 {
        padding-right: 40px !important
    }

    .pb-lg-40, .py-lg-40 {
        padding-bottom: 40px !important
    }

    .pl-lg-40, .px-lg-40 {
        padding-left: 40px !important
    }

    [dir='rtl'] .pr-lg-40 {
        padding-left: 40px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-40 {
        padding-left: 0 !important;
        padding-right: 40px !important
    }

    .p-lg-45 {
        padding: 45px !important
    }

    .pt-lg-45, .py-lg-45 {
        padding-top: 45px !important
    }

    .pr-lg-45, .px-lg-45 {
        padding-right: 45px !important
    }

    .pb-lg-45, .py-lg-45 {
        padding-bottom: 45px !important
    }

    .pl-lg-45, .px-lg-45 {
        padding-left: 45px !important
    }

    [dir='rtl'] .pr-lg-45 {
        padding-left: 45px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-45 {
        padding-left: 0 !important;
        padding-right: 45px !important
    }

    .p-lg-50 {
        padding: 50px !important
    }

    .pt-lg-50, .py-lg-50 {
        padding-top: 50px !important
    }

    .pr-lg-50, .px-lg-50 {
        padding-right: 50px !important
    }

    .pb-lg-50, .py-lg-50 {
        padding-bottom: 50px !important
    }

    .pl-lg-50, .px-lg-50 {
        padding-left: 50px !important
    }

    [dir='rtl'] .pr-lg-50 {
        padding-left: 50px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-50 {
        padding-left: 0 !important;
        padding-right: 50px !important
    }

    .p-lg-55 {
        padding: 55px !important
    }

    .pt-lg-55, .py-lg-55 {
        padding-top: 55px !important
    }

    .pr-lg-55, .px-lg-55 {
        padding-right: 55px !important
    }

    .pb-lg-55, .py-lg-55 {
        padding-bottom: 55px !important
    }

    .pl-lg-55, .px-lg-55 {
        padding-left: 55px !important
    }

    [dir='rtl'] .pr-lg-55 {
        padding-left: 55px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-55 {
        padding-left: 0 !important;
        padding-right: 55px !important
    }

    .p-lg-60 {
        padding: 60px !important
    }

    .pt-lg-60, .py-lg-60 {
        padding-top: 60px !important
    }

    .pr-lg-60, .px-lg-60 {
        padding-right: 60px !important
    }

    .pb-lg-60, .py-lg-60 {
        padding-bottom: 60px !important
    }

    .pl-lg-60, .px-lg-60 {
        padding-left: 60px !important
    }

    [dir='rtl'] .pr-lg-60 {
        padding-left: 60px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-60 {
        padding-left: 0 !important;
        padding-right: 60px !important
    }

    .p-lg-65 {
        padding: 65px !important
    }

    .pt-lg-65, .py-lg-65 {
        padding-top: 65px !important
    }

    .pr-lg-65, .px-lg-65 {
        padding-right: 65px !important
    }

    .pb-lg-65, .py-lg-65 {
        padding-bottom: 65px !important
    }

    .pl-lg-65, .px-lg-65 {
        padding-left: 65px !important
    }

    [dir='rtl'] .pr-lg-65 {
        padding-left: 65px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-65 {
        padding-left: 0 !important;
        padding-right: 65px !important
    }

    .p-lg-70 {
        padding: 70px !important
    }

    .pt-lg-70, .py-lg-70 {
        padding-top: 70px !important
    }

    .pr-lg-70, .px-lg-70 {
        padding-right: 70px !important
    }

    .pb-lg-70, .py-lg-70 {
        padding-bottom: 70px !important
    }

    .pl-lg-70, .px-lg-70 {
        padding-left: 70px !important
    }

    [dir='rtl'] .pr-lg-70 {
        padding-left: 70px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-70 {
        padding-left: 0 !important;
        padding-right: 70px !important
    }

    .p-lg-75 {
        padding: 75px !important
    }

    .pt-lg-75, .py-lg-75 {
        padding-top: 75px !important
    }

    .pr-lg-75, .px-lg-75 {
        padding-right: 75px !important
    }

    .pb-lg-75, .py-lg-75 {
        padding-bottom: 75px !important
    }

    .pl-lg-75, .px-lg-75 {
        padding-left: 75px !important
    }

    [dir='rtl'] .pr-lg-75 {
        padding-left: 75px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-75 {
        padding-left: 0 !important;
        padding-right: 75px !important
    }

    .p-lg-80 {
        padding: 80px !important
    }

    .pt-lg-80, .py-lg-80 {
        padding-top: 80px !important
    }

    .pr-lg-80, .px-lg-80 {
        padding-right: 80px !important
    }

    .pb-lg-80, .py-lg-80 {
        padding-bottom: 80px !important
    }

    .pl-lg-80, .px-lg-80 {
        padding-left: 80px !important
    }

    [dir='rtl'] .pr-lg-80 {
        padding-left: 80px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-80 {
        padding-left: 0 !important;
        padding-right: 80px !important
    }

    .p-lg-85 {
        padding: 85px !important
    }

    .pt-lg-85, .py-lg-85 {
        padding-top: 85px !important
    }

    .pr-lg-85, .px-lg-85 {
        padding-right: 85px !important
    }

    .pb-lg-85, .py-lg-85 {
        padding-bottom: 85px !important
    }

    .pl-lg-85, .px-lg-85 {
        padding-left: 85px !important
    }

    [dir='rtl'] .pr-lg-85 {
        padding-left: 85px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-85 {
        padding-left: 0 !important;
        padding-right: 85px !important
    }

    .p-lg-90 {
        padding: 90px !important
    }

    .pt-lg-90, .py-lg-90 {
        padding-top: 90px !important
    }

    .pr-lg-90, .px-lg-90 {
        padding-right: 90px !important
    }

    .pb-lg-90, .py-lg-90 {
        padding-bottom: 90px !important
    }

    .pl-lg-90, .px-lg-90 {
        padding-left: 90px !important
    }

    [dir='rtl'] .pr-lg-90 {
        padding-left: 90px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-90 {
        padding-left: 0 !important;
        padding-right: 90px !important
    }

    .p-lg-95 {
        padding: 95px !important
    }

    .pt-lg-95, .py-lg-95 {
        padding-top: 95px !important
    }

    .pr-lg-95, .px-lg-95 {
        padding-right: 95px !important
    }

    .pb-lg-95, .py-lg-95 {
        padding-bottom: 95px !important
    }

    .pl-lg-95, .px-lg-95 {
        padding-left: 95px !important
    }

    [dir='rtl'] .pr-lg-95 {
        padding-left: 95px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-95 {
        padding-left: 0 !important;
        padding-right: 95px !important
    }

    .p-lg-100 {
        padding: 100px !important
    }

    .pt-lg-100, .py-lg-100 {
        padding-top: 100px !important
    }

    .pr-lg-100, .px-lg-100 {
        padding-right: 100px !important
    }

    .pb-lg-100, .py-lg-100 {
        padding-bottom: 100px !important
    }

    .pl-lg-100, .px-lg-100 {
        padding-left: 100px !important
    }

    [dir='rtl'] .pr-lg-100 {
        padding-left: 100px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-lg-100 {
        padding-left: 0 !important;
        padding-right: 100px !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto, .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto, .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto, .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto, .mx-lg-auto {
        margin-left: auto !important
    }

    [dir='rtl'] .mr-lg-auto {
        margin-left: auto !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-lg-auto {
        margin-left: 0 !important;
        margin-right: auto !important
    }
}

@media (min-width: 1260px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0, .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0, .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0, .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0, .mx-xl-0 {
        margin-left: 0 !important
    }

    [dir='rtl'] .mr-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .m-xl-1 {
        margin: 1px !important
    }

    .mt-xl-1, .my-xl-1 {
        margin-top: 1px !important
    }

    .mr-xl-1, .mx-xl-1 {
        margin-right: 1px !important
    }

    .mb-xl-1, .my-xl-1 {
        margin-bottom: 1px !important
    }

    .ml-xl-1, .mx-xl-1 {
        margin-left: 1px !important
    }

    [dir='rtl'] .mr-xl-1 {
        margin-left: 1px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-1 {
        margin-left: 0 !important;
        margin-right: 1px !important
    }

    .m-xl-2 {
        margin: 2px !important
    }

    .mt-xl-2, .my-xl-2 {
        margin-top: 2px !important
    }

    .mr-xl-2, .mx-xl-2 {
        margin-right: 2px !important
    }

    .mb-xl-2, .my-xl-2 {
        margin-bottom: 2px !important
    }

    .ml-xl-2, .mx-xl-2 {
        margin-left: 2px !important
    }

    [dir='rtl'] .mr-xl-2 {
        margin-left: 2px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-2 {
        margin-left: 0 !important;
        margin-right: 2px !important
    }

    .m-xl-3 {
        margin: 3px !important
    }

    .mt-xl-3, .my-xl-3 {
        margin-top: 3px !important
    }

    .mr-xl-3, .mx-xl-3 {
        margin-right: 3px !important
    }

    .mb-xl-3, .my-xl-3 {
        margin-bottom: 3px !important
    }

    .ml-xl-3, .mx-xl-3 {
        margin-left: 3px !important
    }

    [dir='rtl'] .mr-xl-3 {
        margin-left: 3px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-3 {
        margin-left: 0 !important;
        margin-right: 3px !important
    }

    .m-xl-4 {
        margin: 4px !important
    }

    .mt-xl-4, .my-xl-4 {
        margin-top: 4px !important
    }

    .mr-xl-4, .mx-xl-4 {
        margin-right: 4px !important
    }

    .mb-xl-4, .my-xl-4 {
        margin-bottom: 4px !important
    }

    .ml-xl-4, .mx-xl-4 {
        margin-left: 4px !important
    }

    [dir='rtl'] .mr-xl-4 {
        margin-left: 4px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-4 {
        margin-left: 0 !important;
        margin-right: 4px !important
    }

    .m-xl-5 {
        margin: 5px !important
    }

    .mt-xl-5, .my-xl-5 {
        margin-top: 5px !important
    }

    .mr-xl-5, .mx-xl-5 {
        margin-right: 5px !important
    }

    .mb-xl-5, .my-xl-5 {
        margin-bottom: 5px !important
    }

    .ml-xl-5, .mx-xl-5 {
        margin-left: 5px !important
    }

    [dir='rtl'] .mr-xl-5 {
        margin-left: 5px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-5 {
        margin-left: 0 !important;
        margin-right: 5px !important
    }

    .m-xl-6 {
        margin: 6px !important
    }

    .mt-xl-6, .my-xl-6 {
        margin-top: 6px !important
    }

    .mr-xl-6, .mx-xl-6 {
        margin-right: 6px !important
    }

    .mb-xl-6, .my-xl-6 {
        margin-bottom: 6px !important
    }

    .ml-xl-6, .mx-xl-6 {
        margin-left: 6px !important
    }

    [dir='rtl'] .mr-xl-6 {
        margin-left: 6px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-6 {
        margin-left: 0 !important;
        margin-right: 6px !important
    }

    .m-xl-7 {
        margin: 7px !important
    }

    .mt-xl-7, .my-xl-7 {
        margin-top: 7px !important
    }

    .mr-xl-7, .mx-xl-7 {
        margin-right: 7px !important
    }

    .mb-xl-7, .my-xl-7 {
        margin-bottom: 7px !important
    }

    .ml-xl-7, .mx-xl-7 {
        margin-left: 7px !important
    }

    [dir='rtl'] .mr-xl-7 {
        margin-left: 7px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-7 {
        margin-left: 0 !important;
        margin-right: 7px !important
    }

    .m-xl-8 {
        margin: 8px !important
    }

    .mt-xl-8, .my-xl-8 {
        margin-top: 8px !important
    }

    .mr-xl-8, .mx-xl-8 {
        margin-right: 8px !important
    }

    .mb-xl-8, .my-xl-8 {
        margin-bottom: 8px !important
    }

    .ml-xl-8, .mx-xl-8 {
        margin-left: 8px !important
    }

    [dir='rtl'] .mr-xl-8 {
        margin-left: 8px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-8 {
        margin-left: 0 !important;
        margin-right: 8px !important
    }

    .m-xl-9 {
        margin: 9px !important
    }

    .mt-xl-9, .my-xl-9 {
        margin-top: 9px !important
    }

    .mr-xl-9, .mx-xl-9 {
        margin-right: 9px !important
    }

    .mb-xl-9, .my-xl-9 {
        margin-bottom: 9px !important
    }

    .ml-xl-9, .mx-xl-9 {
        margin-left: 9px !important
    }

    [dir='rtl'] .mr-xl-9 {
        margin-left: 9px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-9 {
        margin-left: 0 !important;
        margin-right: 9px !important
    }

    .m-xl-10 {
        margin: 10px !important
    }

    .mt-xl-10, .my-xl-10 {
        margin-top: 10px !important
    }

    .mr-xl-10, .mx-xl-10 {
        margin-right: 10px !important
    }

    .mb-xl-10, .my-xl-10 {
        margin-bottom: 10px !important
    }

    .ml-xl-10, .mx-xl-10 {
        margin-left: 10px !important
    }

    [dir='rtl'] .mr-xl-10 {
        margin-left: 10px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-10 {
        margin-left: 0 !important;
        margin-right: 10px !important
    }

    .m-xl-15 {
        margin: 15px !important
    }

    .mt-xl-15, .my-xl-15 {
        margin-top: 15px !important
    }

    .mr-xl-15, .mx-xl-15 {
        margin-right: 15px !important
    }

    .mb-xl-15, .my-xl-15 {
        margin-bottom: 15px !important
    }

    .ml-xl-15, .mx-xl-15 {
        margin-left: 15px !important
    }

    [dir='rtl'] .mr-xl-15 {
        margin-left: 15px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-15 {
        margin-left: 0 !important;
        margin-right: 15px !important
    }

    .m-xl-20 {
        margin: 20px !important
    }

    .mt-xl-20, .my-xl-20 {
        margin-top: 20px !important
    }

    .mr-xl-20, .mx-xl-20 {
        margin-right: 20px !important
    }

    .mb-xl-20, .my-xl-20 {
        margin-bottom: 20px !important
    }

    .ml-xl-20, .mx-xl-20 {
        margin-left: 20px !important
    }

    [dir='rtl'] .mr-xl-20 {
        margin-left: 20px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-20 {
        margin-left: 0 !important;
        margin-right: 20px !important
    }

    .m-xl-25 {
        margin: 25px !important
    }

    .mt-xl-25, .my-xl-25 {
        margin-top: 25px !important
    }

    .mr-xl-25, .mx-xl-25 {
        margin-right: 25px !important
    }

    .mb-xl-25, .my-xl-25 {
        margin-bottom: 25px !important
    }

    .ml-xl-25, .mx-xl-25 {
        margin-left: 25px !important
    }

    [dir='rtl'] .mr-xl-25 {
        margin-left: 25px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-25 {
        margin-left: 0 !important;
        margin-right: 25px !important
    }

    .m-xl-30 {
        margin: 30px !important
    }

    .mt-xl-30, .my-xl-30 {
        margin-top: 30px !important
    }

    .mr-xl-30, .mx-xl-30 {
        margin-right: 30px !important
    }

    .mb-xl-30, .my-xl-30 {
        margin-bottom: 30px !important
    }

    .ml-xl-30, .mx-xl-30 {
        margin-left: 30px !important
    }

    [dir='rtl'] .mr-xl-30 {
        margin-left: 30px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-30 {
        margin-left: 0 !important;
        margin-right: 30px !important
    }

    .m-xl-35 {
        margin: 35px !important
    }

    .mt-xl-35, .my-xl-35 {
        margin-top: 35px !important
    }

    .mr-xl-35, .mx-xl-35 {
        margin-right: 35px !important
    }

    .mb-xl-35, .my-xl-35 {
        margin-bottom: 35px !important
    }

    .ml-xl-35, .mx-xl-35 {
        margin-left: 35px !important
    }

    [dir='rtl'] .mr-xl-35 {
        margin-left: 35px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-35 {
        margin-left: 0 !important;
        margin-right: 35px !important
    }

    .m-xl-40 {
        margin: 40px !important
    }

    .mt-xl-40, .my-xl-40 {
        margin-top: 40px !important
    }

    .mr-xl-40, .mx-xl-40 {
        margin-right: 40px !important
    }

    .mb-xl-40, .my-xl-40 {
        margin-bottom: 40px !important
    }

    .ml-xl-40, .mx-xl-40 {
        margin-left: 40px !important
    }

    [dir='rtl'] .mr-xl-40 {
        margin-left: 40px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-40 {
        margin-left: 0 !important;
        margin-right: 40px !important
    }

    .m-xl-45 {
        margin: 45px !important
    }

    .mt-xl-45, .my-xl-45 {
        margin-top: 45px !important
    }

    .mr-xl-45, .mx-xl-45 {
        margin-right: 45px !important
    }

    .mb-xl-45, .my-xl-45 {
        margin-bottom: 45px !important
    }

    .ml-xl-45, .mx-xl-45 {
        margin-left: 45px !important
    }

    [dir='rtl'] .mr-xl-45 {
        margin-left: 45px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-45 {
        margin-left: 0 !important;
        margin-right: 45px !important
    }

    .m-xl-50 {
        margin: 50px !important
    }

    .mt-xl-50, .my-xl-50 {
        margin-top: 50px !important
    }

    .mr-xl-50, .mx-xl-50 {
        margin-right: 50px !important
    }

    .mb-xl-50, .my-xl-50 {
        margin-bottom: 50px !important
    }

    .ml-xl-50, .mx-xl-50 {
        margin-left: 50px !important
    }

    [dir='rtl'] .mr-xl-50 {
        margin-left: 50px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-50 {
        margin-left: 0 !important;
        margin-right: 50px !important
    }

    .m-xl-55 {
        margin: 55px !important
    }

    .mt-xl-55, .my-xl-55 {
        margin-top: 55px !important
    }

    .mr-xl-55, .mx-xl-55 {
        margin-right: 55px !important
    }

    .mb-xl-55, .my-xl-55 {
        margin-bottom: 55px !important
    }

    .ml-xl-55, .mx-xl-55 {
        margin-left: 55px !important
    }

    [dir='rtl'] .mr-xl-55 {
        margin-left: 55px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-55 {
        margin-left: 0 !important;
        margin-right: 55px !important
    }

    .m-xl-60 {
        margin: 60px !important
    }

    .mt-xl-60, .my-xl-60 {
        margin-top: 60px !important
    }

    .mr-xl-60, .mx-xl-60 {
        margin-right: 60px !important
    }

    .mb-xl-60, .my-xl-60 {
        margin-bottom: 60px !important
    }

    .ml-xl-60, .mx-xl-60 {
        margin-left: 60px !important
    }

    [dir='rtl'] .mr-xl-60 {
        margin-left: 60px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-60 {
        margin-left: 0 !important;
        margin-right: 60px !important
    }

    .m-xl-65 {
        margin: 65px !important
    }

    .mt-xl-65, .my-xl-65 {
        margin-top: 65px !important
    }

    .mr-xl-65, .mx-xl-65 {
        margin-right: 65px !important
    }

    .mb-xl-65, .my-xl-65 {
        margin-bottom: 65px !important
    }

    .ml-xl-65, .mx-xl-65 {
        margin-left: 65px !important
    }

    [dir='rtl'] .mr-xl-65 {
        margin-left: 65px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-65 {
        margin-left: 0 !important;
        margin-right: 65px !important
    }

    .m-xl-70 {
        margin: 70px !important
    }

    .mt-xl-70, .my-xl-70 {
        margin-top: 70px !important
    }

    .mr-xl-70, .mx-xl-70 {
        margin-right: 70px !important
    }

    .mb-xl-70, .my-xl-70 {
        margin-bottom: 70px !important
    }

    .ml-xl-70, .mx-xl-70 {
        margin-left: 70px !important
    }

    [dir='rtl'] .mr-xl-70 {
        margin-left: 70px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-70 {
        margin-left: 0 !important;
        margin-right: 70px !important
    }

    .m-xl-75 {
        margin: 75px !important
    }

    .mt-xl-75, .my-xl-75 {
        margin-top: 75px !important
    }

    .mr-xl-75, .mx-xl-75 {
        margin-right: 75px !important
    }

    .mb-xl-75, .my-xl-75 {
        margin-bottom: 75px !important
    }

    .ml-xl-75, .mx-xl-75 {
        margin-left: 75px !important
    }

    [dir='rtl'] .mr-xl-75 {
        margin-left: 75px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-75 {
        margin-left: 0 !important;
        margin-right: 75px !important
    }

    .m-xl-80 {
        margin: 80px !important
    }

    .mt-xl-80, .my-xl-80 {
        margin-top: 80px !important
    }

    .mr-xl-80, .mx-xl-80 {
        margin-right: 80px !important
    }

    .mb-xl-80, .my-xl-80 {
        margin-bottom: 80px !important
    }

    .ml-xl-80, .mx-xl-80 {
        margin-left: 80px !important
    }

    [dir='rtl'] .mr-xl-80 {
        margin-left: 80px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-80 {
        margin-left: 0 !important;
        margin-right: 80px !important
    }

    .m-xl-85 {
        margin: 85px !important
    }

    .mt-xl-85, .my-xl-85 {
        margin-top: 85px !important
    }

    .mr-xl-85, .mx-xl-85 {
        margin-right: 85px !important
    }

    .mb-xl-85, .my-xl-85 {
        margin-bottom: 85px !important
    }

    .ml-xl-85, .mx-xl-85 {
        margin-left: 85px !important
    }

    [dir='rtl'] .mr-xl-85 {
        margin-left: 85px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-85 {
        margin-left: 0 !important;
        margin-right: 85px !important
    }

    .m-xl-90 {
        margin: 90px !important
    }

    .mt-xl-90, .my-xl-90 {
        margin-top: 90px !important
    }

    .mr-xl-90, .mx-xl-90 {
        margin-right: 90px !important
    }

    .mb-xl-90, .my-xl-90 {
        margin-bottom: 90px !important
    }

    .ml-xl-90, .mx-xl-90 {
        margin-left: 90px !important
    }

    [dir='rtl'] .mr-xl-90 {
        margin-left: 90px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-90 {
        margin-left: 0 !important;
        margin-right: 90px !important
    }

    .m-xl-95 {
        margin: 95px !important
    }

    .mt-xl-95, .my-xl-95 {
        margin-top: 95px !important
    }

    .mr-xl-95, .mx-xl-95 {
        margin-right: 95px !important
    }

    .mb-xl-95, .my-xl-95 {
        margin-bottom: 95px !important
    }

    .ml-xl-95, .mx-xl-95 {
        margin-left: 95px !important
    }

    [dir='rtl'] .mr-xl-95 {
        margin-left: 95px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-95 {
        margin-left: 0 !important;
        margin-right: 95px !important
    }

    .m-xl-100 {
        margin: 100px !important
    }

    .mt-xl-100, .my-xl-100 {
        margin-top: 100px !important
    }

    .mr-xl-100, .mx-xl-100 {
        margin-right: 100px !important
    }

    .mb-xl-100, .my-xl-100 {
        margin-bottom: 100px !important
    }

    .ml-xl-100, .mx-xl-100 {
        margin-left: 100px !important
    }

    [dir='rtl'] .mr-xl-100 {
        margin-left: 100px !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-100 {
        margin-left: 0 !important;
        margin-right: 100px !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0, .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0, .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0, .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0, .px-xl-0 {
        padding-left: 0 !important
    }

    [dir='rtl'] .pr-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .p-xl-1 {
        padding: 1px !important
    }

    .pt-xl-1, .py-xl-1 {
        padding-top: 1px !important
    }

    .pr-xl-1, .px-xl-1 {
        padding-right: 1px !important
    }

    .pb-xl-1, .py-xl-1 {
        padding-bottom: 1px !important
    }

    .pl-xl-1, .px-xl-1 {
        padding-left: 1px !important
    }

    [dir='rtl'] .pr-xl-1 {
        padding-left: 1px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-1 {
        padding-left: 0 !important;
        padding-right: 1px !important
    }

    .p-xl-2 {
        padding: 2px !important
    }

    .pt-xl-2, .py-xl-2 {
        padding-top: 2px !important
    }

    .pr-xl-2, .px-xl-2 {
        padding-right: 2px !important
    }

    .pb-xl-2, .py-xl-2 {
        padding-bottom: 2px !important
    }

    .pl-xl-2, .px-xl-2 {
        padding-left: 2px !important
    }

    [dir='rtl'] .pr-xl-2 {
        padding-left: 2px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-2 {
        padding-left: 0 !important;
        padding-right: 2px !important
    }

    .p-xl-3 {
        padding: 3px !important
    }

    .pt-xl-3, .py-xl-3 {
        padding-top: 3px !important
    }

    .pr-xl-3, .px-xl-3 {
        padding-right: 3px !important
    }

    .pb-xl-3, .py-xl-3 {
        padding-bottom: 3px !important
    }

    .pl-xl-3, .px-xl-3 {
        padding-left: 3px !important
    }

    [dir='rtl'] .pr-xl-3 {
        padding-left: 3px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-3 {
        padding-left: 0 !important;
        padding-right: 3px !important
    }

    .p-xl-4 {
        padding: 4px !important
    }

    .pt-xl-4, .py-xl-4 {
        padding-top: 4px !important
    }

    .pr-xl-4, .px-xl-4 {
        padding-right: 4px !important
    }

    .pb-xl-4, .py-xl-4 {
        padding-bottom: 4px !important
    }

    .pl-xl-4, .px-xl-4 {
        padding-left: 4px !important
    }

    [dir='rtl'] .pr-xl-4 {
        padding-left: 4px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-4 {
        padding-left: 0 !important;
        padding-right: 4px !important
    }

    .p-xl-5 {
        padding: 5px !important
    }

    .pt-xl-5, .py-xl-5 {
        padding-top: 5px !important
    }

    .pr-xl-5, .px-xl-5 {
        padding-right: 5px !important
    }

    .pb-xl-5, .py-xl-5 {
        padding-bottom: 5px !important
    }

    .pl-xl-5, .px-xl-5 {
        padding-left: 5px !important
    }

    [dir='rtl'] .pr-xl-5 {
        padding-left: 5px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-5 {
        padding-left: 0 !important;
        padding-right: 5px !important
    }

    .p-xl-6 {
        padding: 6px !important
    }

    .pt-xl-6, .py-xl-6 {
        padding-top: 6px !important
    }

    .pr-xl-6, .px-xl-6 {
        padding-right: 6px !important
    }

    .pb-xl-6, .py-xl-6 {
        padding-bottom: 6px !important
    }

    .pl-xl-6, .px-xl-6 {
        padding-left: 6px !important
    }

    [dir='rtl'] .pr-xl-6 {
        padding-left: 6px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-6 {
        padding-left: 0 !important;
        padding-right: 6px !important
    }

    .p-xl-7 {
        padding: 7px !important
    }

    .pt-xl-7, .py-xl-7 {
        padding-top: 7px !important
    }

    .pr-xl-7, .px-xl-7 {
        padding-right: 7px !important
    }

    .pb-xl-7, .py-xl-7 {
        padding-bottom: 7px !important
    }

    .pl-xl-7, .px-xl-7 {
        padding-left: 7px !important
    }

    [dir='rtl'] .pr-xl-7 {
        padding-left: 7px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-7 {
        padding-left: 0 !important;
        padding-right: 7px !important
    }

    .p-xl-8 {
        padding: 8px !important
    }

    .pt-xl-8, .py-xl-8 {
        padding-top: 8px !important
    }

    .pr-xl-8, .px-xl-8 {
        padding-right: 8px !important
    }

    .pb-xl-8, .py-xl-8 {
        padding-bottom: 8px !important
    }

    .pl-xl-8, .px-xl-8 {
        padding-left: 8px !important
    }

    [dir='rtl'] .pr-xl-8 {
        padding-left: 8px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-8 {
        padding-left: 0 !important;
        padding-right: 8px !important
    }

    .p-xl-9 {
        padding: 9px !important
    }

    .pt-xl-9, .py-xl-9 {
        padding-top: 9px !important
    }

    .pr-xl-9, .px-xl-9 {
        padding-right: 9px !important
    }

    .pb-xl-9, .py-xl-9 {
        padding-bottom: 9px !important
    }

    .pl-xl-9, .px-xl-9 {
        padding-left: 9px !important
    }

    [dir='rtl'] .pr-xl-9 {
        padding-left: 9px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-9 {
        padding-left: 0 !important;
        padding-right: 9px !important
    }

    .p-xl-10 {
        padding: 10px !important
    }

    .pt-xl-10, .py-xl-10 {
        padding-top: 10px !important
    }

    .pr-xl-10, .px-xl-10 {
        padding-right: 10px !important
    }

    .pb-xl-10, .py-xl-10 {
        padding-bottom: 10px !important
    }

    .pl-xl-10, .px-xl-10 {
        padding-left: 10px !important
    }

    [dir='rtl'] .pr-xl-10 {
        padding-left: 10px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-10 {
        padding-left: 0 !important;
        padding-right: 10px !important
    }

    .p-xl-15 {
        padding: 15px !important
    }

    .pt-xl-15, .py-xl-15 {
        padding-top: 15px !important
    }

    .pr-xl-15, .px-xl-15 {
        padding-right: 15px !important
    }

    .pb-xl-15, .py-xl-15 {
        padding-bottom: 15px !important
    }

    .pl-xl-15, .px-xl-15 {
        padding-left: 15px !important
    }

    [dir='rtl'] .pr-xl-15 {
        padding-left: 15px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-15 {
        padding-left: 0 !important;
        padding-right: 15px !important
    }

    .p-xl-20 {
        padding: 20px !important
    }

    .pt-xl-20, .py-xl-20 {
        padding-top: 20px !important
    }

    .pr-xl-20, .px-xl-20 {
        padding-right: 20px !important
    }

    .pb-xl-20, .py-xl-20 {
        padding-bottom: 20px !important
    }

    .pl-xl-20, .px-xl-20 {
        padding-left: 20px !important
    }

    [dir='rtl'] .pr-xl-20 {
        padding-left: 20px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-20 {
        padding-left: 0 !important;
        padding-right: 20px !important
    }

    .p-xl-25 {
        padding: 25px !important
    }

    .pt-xl-25, .py-xl-25 {
        padding-top: 25px !important
    }

    .pr-xl-25, .px-xl-25 {
        padding-right: 25px !important
    }

    .pb-xl-25, .py-xl-25 {
        padding-bottom: 25px !important
    }

    .pl-xl-25, .px-xl-25 {
        padding-left: 25px !important
    }

    [dir='rtl'] .pr-xl-25 {
        padding-left: 25px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-25 {
        padding-left: 0 !important;
        padding-right: 25px !important
    }

    .p-xl-30 {
        padding: 30px !important
    }

    .pt-xl-30, .py-xl-30 {
        padding-top: 30px !important
    }

    .pr-xl-30, .px-xl-30 {
        padding-right: 30px !important
    }

    .pb-xl-30, .py-xl-30 {
        padding-bottom: 30px !important
    }

    .pl-xl-30, .px-xl-30 {
        padding-left: 30px !important
    }

    [dir='rtl'] .pr-xl-30 {
        padding-left: 30px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-30 {
        padding-left: 0 !important;
        padding-right: 30px !important
    }

    .p-xl-35 {
        padding: 35px !important
    }

    .pt-xl-35, .py-xl-35 {
        padding-top: 35px !important
    }

    .pr-xl-35, .px-xl-35 {
        padding-right: 35px !important
    }

    .pb-xl-35, .py-xl-35 {
        padding-bottom: 35px !important
    }

    .pl-xl-35, .px-xl-35 {
        padding-left: 35px !important
    }

    [dir='rtl'] .pr-xl-35 {
        padding-left: 35px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-35 {
        padding-left: 0 !important;
        padding-right: 35px !important
    }

    .p-xl-40 {
        padding: 40px !important
    }

    .pt-xl-40, .py-xl-40 {
        padding-top: 40px !important
    }

    .pr-xl-40, .px-xl-40 {
        padding-right: 40px !important
    }

    .pb-xl-40, .py-xl-40 {
        padding-bottom: 40px !important
    }

    .pl-xl-40, .px-xl-40 {
        padding-left: 40px !important
    }

    [dir='rtl'] .pr-xl-40 {
        padding-left: 40px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-40 {
        padding-left: 0 !important;
        padding-right: 40px !important
    }

    .p-xl-45 {
        padding: 45px !important
    }

    .pt-xl-45, .py-xl-45 {
        padding-top: 45px !important
    }

    .pr-xl-45, .px-xl-45 {
        padding-right: 45px !important
    }

    .pb-xl-45, .py-xl-45 {
        padding-bottom: 45px !important
    }

    .pl-xl-45, .px-xl-45 {
        padding-left: 45px !important
    }

    [dir='rtl'] .pr-xl-45 {
        padding-left: 45px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-45 {
        padding-left: 0 !important;
        padding-right: 45px !important
    }

    .p-xl-50 {
        padding: 50px !important
    }

    .pt-xl-50, .py-xl-50 {
        padding-top: 50px !important
    }

    .pr-xl-50, .px-xl-50 {
        padding-right: 50px !important
    }

    .pb-xl-50, .py-xl-50 {
        padding-bottom: 50px !important
    }

    .pl-xl-50, .px-xl-50 {
        padding-left: 50px !important
    }

    [dir='rtl'] .pr-xl-50 {
        padding-left: 50px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-50 {
        padding-left: 0 !important;
        padding-right: 50px !important
    }

    .p-xl-55 {
        padding: 55px !important
    }

    .pt-xl-55, .py-xl-55 {
        padding-top: 55px !important
    }

    .pr-xl-55, .px-xl-55 {
        padding-right: 55px !important
    }

    .pb-xl-55, .py-xl-55 {
        padding-bottom: 55px !important
    }

    .pl-xl-55, .px-xl-55 {
        padding-left: 55px !important
    }

    [dir='rtl'] .pr-xl-55 {
        padding-left: 55px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-55 {
        padding-left: 0 !important;
        padding-right: 55px !important
    }

    .p-xl-60 {
        padding: 60px !important
    }

    .pt-xl-60, .py-xl-60 {
        padding-top: 60px !important
    }

    .pr-xl-60, .px-xl-60 {
        padding-right: 60px !important
    }

    .pb-xl-60, .py-xl-60 {
        padding-bottom: 60px !important
    }

    .pl-xl-60, .px-xl-60 {
        padding-left: 60px !important
    }

    [dir='rtl'] .pr-xl-60 {
        padding-left: 60px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-60 {
        padding-left: 0 !important;
        padding-right: 60px !important
    }

    .p-xl-65 {
        padding: 65px !important
    }

    .pt-xl-65, .py-xl-65 {
        padding-top: 65px !important
    }

    .pr-xl-65, .px-xl-65 {
        padding-right: 65px !important
    }

    .pb-xl-65, .py-xl-65 {
        padding-bottom: 65px !important
    }

    .pl-xl-65, .px-xl-65 {
        padding-left: 65px !important
    }

    [dir='rtl'] .pr-xl-65 {
        padding-left: 65px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-65 {
        padding-left: 0 !important;
        padding-right: 65px !important
    }

    .p-xl-70 {
        padding: 70px !important
    }

    .pt-xl-70, .py-xl-70 {
        padding-top: 70px !important
    }

    .pr-xl-70, .px-xl-70 {
        padding-right: 70px !important
    }

    .pb-xl-70, .py-xl-70 {
        padding-bottom: 70px !important
    }

    .pl-xl-70, .px-xl-70 {
        padding-left: 70px !important
    }

    [dir='rtl'] .pr-xl-70 {
        padding-left: 70px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-70 {
        padding-left: 0 !important;
        padding-right: 70px !important
    }

    .p-xl-75 {
        padding: 75px !important
    }

    .pt-xl-75, .py-xl-75 {
        padding-top: 75px !important
    }

    .pr-xl-75, .px-xl-75 {
        padding-right: 75px !important
    }

    .pb-xl-75, .py-xl-75 {
        padding-bottom: 75px !important
    }

    .pl-xl-75, .px-xl-75 {
        padding-left: 75px !important
    }

    [dir='rtl'] .pr-xl-75 {
        padding-left: 75px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-75 {
        padding-left: 0 !important;
        padding-right: 75px !important
    }

    .p-xl-80 {
        padding: 80px !important
    }

    .pt-xl-80, .py-xl-80 {
        padding-top: 80px !important
    }

    .pr-xl-80, .px-xl-80 {
        padding-right: 80px !important
    }

    .pb-xl-80, .py-xl-80 {
        padding-bottom: 80px !important
    }

    .pl-xl-80, .px-xl-80 {
        padding-left: 80px !important
    }

    [dir='rtl'] .pr-xl-80 {
        padding-left: 80px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-80 {
        padding-left: 0 !important;
        padding-right: 80px !important
    }

    .p-xl-85 {
        padding: 85px !important
    }

    .pt-xl-85, .py-xl-85 {
        padding-top: 85px !important
    }

    .pr-xl-85, .px-xl-85 {
        padding-right: 85px !important
    }

    .pb-xl-85, .py-xl-85 {
        padding-bottom: 85px !important
    }

    .pl-xl-85, .px-xl-85 {
        padding-left: 85px !important
    }

    [dir='rtl'] .pr-xl-85 {
        padding-left: 85px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-85 {
        padding-left: 0 !important;
        padding-right: 85px !important
    }

    .p-xl-90 {
        padding: 90px !important
    }

    .pt-xl-90, .py-xl-90 {
        padding-top: 90px !important
    }

    .pr-xl-90, .px-xl-90 {
        padding-right: 90px !important
    }

    .pb-xl-90, .py-xl-90 {
        padding-bottom: 90px !important
    }

    .pl-xl-90, .px-xl-90 {
        padding-left: 90px !important
    }

    [dir='rtl'] .pr-xl-90 {
        padding-left: 90px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-90 {
        padding-left: 0 !important;
        padding-right: 90px !important
    }

    .p-xl-95 {
        padding: 95px !important
    }

    .pt-xl-95, .py-xl-95 {
        padding-top: 95px !important
    }

    .pr-xl-95, .px-xl-95 {
        padding-right: 95px !important
    }

    .pb-xl-95, .py-xl-95 {
        padding-bottom: 95px !important
    }

    .pl-xl-95, .px-xl-95 {
        padding-left: 95px !important
    }

    [dir='rtl'] .pr-xl-95 {
        padding-left: 95px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-95 {
        padding-left: 0 !important;
        padding-right: 95px !important
    }

    .p-xl-100 {
        padding: 100px !important
    }

    .pt-xl-100, .py-xl-100 {
        padding-top: 100px !important
    }

    .pr-xl-100, .px-xl-100 {
        padding-right: 100px !important
    }

    .pb-xl-100, .py-xl-100 {
        padding-bottom: 100px !important
    }

    .pl-xl-100, .px-xl-100 {
        padding-left: 100px !important
    }

    [dir='rtl'] .pr-xl-100 {
        padding-left: 100px !important;
        padding-right: 0 !important
    }

    [dir='rtl'] .pl-xl-100 {
        padding-left: 0 !important;
        padding-right: 100px !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto, .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto, .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto, .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto, .mx-xl-auto {
        margin-left: auto !important
    }

    [dir='rtl'] .mr-xl-auto {
        margin-left: auto !important;
        margin-right: 0 !important
    }

    [dir='rtl'] .ml-xl-auto {
        margin-left: 0 !important;
        margin-right: auto !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.text-justify {
    text-align: justify !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-wrap {
    white-space: normal !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

[dir='rtl'] .text-left {
    text-align: right !important
}

[dir='rtl'] .text-right {
    text-align: left !important
}

@media (min-width: 541px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }

    [dir='rtl'] .text-sm-left {
        text-align: right !important
    }

    [dir='rtl'] .text-sm-right {
        text-align: left !important
    }
}

@media (min-width: 778px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }

    [dir='rtl'] .text-md-left {
        text-align: right !important
    }

    [dir='rtl'] .text-md-right {
        text-align: left !important
    }
}

@media (min-width: 1025px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }

    [dir='rtl'] .text-lg-left {
        text-align: right !important
    }

    [dir='rtl'] .text-lg-right {
        text-align: left !important
    }
}

@media (min-width: 1260px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    [dir='rtl'] .text-xl-left {
        text-align: right !important
    }

    [dir='rtl'] .text-xl-right {
        text-align: left !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #007bff !important
}

a.text-primary:hover, a.text-primary:focus {
    color: #0062cc !important
}

.text-secondary {
    color: #6c757d !important
}

a.text-secondary:hover, a.text-secondary:focus {
    color: #545b62 !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:hover, a.text-success:focus {
    color: #1e7e34 !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:hover, a.text-info:focus {
    color: #117a8b !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:hover, a.text-danger:focus {
    color: #bd2130 !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:hover, a.text-light:focus {
    color: #dae0e5 !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:hover, a.text-dark:focus {
    color: #1d2124 !important
}

.text-body {
    color: #212529 !important
}

.text-muted {
    color: #6c757d !important
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

/*END: Bootstrap*/

/*START: Preload styles */
main,
.header__sidebar,
.header__nav,
.header__line-bottom,
.header__border {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-out;
}

html:not(.css-theme-loaded) main,
html:not(.css-theme-loaded) .header__sidebar,
html:not(.css-theme-loaded) .header__nav,
html:not(.css-theme-loaded) .header__line-bottom,
html:not(.css-theme-loaded) .header__border {
    /*opacity: 0; Garphild*/
}

.text-font-loaded body {
    font-family: 'Archivo', sans-serif;
}

html:not(.text-font-loaded) body {
    font-family: Arial;
}

html:not(.css-theme-loaded) body {
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
    background-color: #ffffff;
    color: #858585;
    overflow-x: hidden;
}

html:not(.css-theme-loaded) [class*='icon-'] {
    width: 20px;
    height: 20px;
}

html:not(.css-theme-loaded) a,
html:not(.css-theme-loaded) a:visited {
    color: #141414;
    text-decoration: none !important;
}

html:not(.css-theme-loaded) .footer__section-content ul a {
    color: #858585;
}

html:not(.css-theme-loaded) label:not([class]) {
    margin-bottom: 2px;
}

html:not(.css-theme-loaded) .fs {
    font-size: 13px !important;
    line-height: 17px !important;
}

html:not(.css-theme-loaded) .fs-lg {
    font-size: 15px !important;
    line-height: 23px !important;
}

html:not(.css-theme-loaded) h1,
html:not(.css-theme-loaded) .h1 {
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0.05em;
}

html:not(.css-theme-loaded) h2,
html:not(.css-theme-loaded) .h2 {
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 0.05em;
}

html:not(.css-theme-loaded) h3,
html:not(.css-theme-loaded) .h3 {
    font-size: 24px;
    line-height: 31px;
}

html:not(.css-theme-loaded) h4,
html:not(.css-theme-loaded) .h4 {
    font-size: 18px;
    line-height: 24px;
}

html:not(.css-theme-loaded) h5,
html:not(.css-theme-loaded) .h5 {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.05em;
}

html:not(.css-theme-loaded) h6,
html:not(.css-theme-loaded) .h6 {
    font-size: 13px;
    line-height: 17px;
}

html:not(.css-theme-loaded) ul,
html:not(.css-theme-loaded) ol {
    font-size: 13px;
    line-height: 23px;
}

html:not(.css-theme-loaded) .list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

html:not(.css-theme-loaded) .list-lg {
    font-size: 15px;
    line-height: 23px;
}

html:not(.css-theme-loaded) .d-none-important,
html:not(.css-theme-loaded) .btn > *:not(:first-child),
html:not(.css-theme-loaded) .promobox__list,
html:not(.css-theme-loaded) .slider__prev,
html:not(.css-theme-loaded) .slider__next,
html:not(.css-theme-loaded) .fixed-sidebar__section,
html:not(.css-theme-loaded) .product-options,
html:not(.css-theme-loaded) .weglot-container {
    display: none !important;
}

@media (min-width: 1025px) {
    html:not(.css-theme-loaded) .social-media .icon {
        width: 14px;
        min-width: 14px;
    }
}

html:not(.css-theme-loaded) .btn:not([class*="btn--invert"]):not([class*="btn--secondary"]):not([class*="btn--dynamic-checkout"]):not([class*="btn--text"]),
html:not(.css-theme-loaded) .spr-summary-actions-newreview {
    background-color: #ffffff;
    border-color: #141414;
    color: #141414;
}

html:not(.css-theme-loaded) .btn i {
    background-color: transparent !important;
}

html:not(.css-theme-loaded) .btn {
    letter-spacing: 0.05em;
}

@media (min-width: 1025px) {
    html:not(.css-theme-loaded) .btn {
        font-size: 13px;
        line-height: 14px;
    }
}

@media (max-width: 1024px) {
    html:not(.css-theme-loaded) .btn {
        letter-spacing: 0.05em;
        font-size: 15px;
        line-height: 24px;
    }
}

html:not(.css-theme-loaded) .border {
    border-color: #e5e5e5 !important;
}

html:not(.css-theme-loaded) .border-top {
    border-top-color: #e5e5e5 !important;
}

html:not(.css-theme-loaded) .border-bottom {
    border-bottom-color: #e5e5e5 !important;
}

html:not(.css-theme-loaded) .border-left {
    border-left-color: #e5e5e5 !important;
}

html:not(.css-theme-loaded) .border-right {
    border-right-color: #e5e5e5 !important;
}

html:not(.css-theme-loaded) .absolute-stretch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

html:not(.css-theme-loaded) img {
    max-width: 100%;
}

html:not(.css-theme-loaded) .position-relative {
    position: relative;
}

html:not(.css-theme-loaded) .position-absolute {
    position: absolute;
}

html:not(.css-theme-loaded) .top-0 {
    top: 0;
}

html:not(.css-theme-loaded) .left-0 {
    left: 0;
}

html:not(.css-theme-loaded) .w-100 {
    width: 100%;
}

html:not(.css-theme-loaded) input[type="text"],
html:not(.css-theme-loaded) input[type="email"],
html:not(.css-theme-loaded) input[type="search"],
html:not(.css-theme-loaded) input[type="number"],
html:not(.css-theme-loaded) input[type="password"] {
    min-height: 35px;
    padding: 0 15px;
    margin-bottom: 20px;
    border: solid 1px #e5e5e5;
}

html:not(.css-theme-loaded) h1:not(.logo),
html:not(.css-theme-loaded) .h1:not(.logo),
html:not(.css-theme-loaded) h2,
html:not(.css-theme-loaded) .h2,
html:not(.css-theme-loaded) h3,
html:not(.css-theme-loaded) .h3,
html:not(.css-theme-loaded) h4,
html:not(.css-theme-loaded) .h4,
html:not(.css-theme-loaded) h5,
html:not(.css-theme-loaded) .h5,
html:not(.css-theme-loaded) h6:not(.logo),
html:not(.css-theme-loaded) .h6:not(.logo),
html:not(.css-theme-loaded) .header__btn-currency {
    color: #141414;
}

@media (min-width: 1025px) {
    html:not(.css-theme-loaded) .position-lg-relative {
        position: relative;
    }
}

html:not(.css-theme-loaded) .header__logo--center + .header__sidebar {
    position: absolute;
    padding-right: 15px;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
}

html:not(.css-theme-loaded) h1:not(.logo),
html:not(.css-theme-loaded) .h1:not(.logo),
html:not(.css-theme-loaded) h2,
html:not(.css-theme-loaded) .h2,
html:not(.css-theme-loaded) h3,
html:not(.css-theme-loaded) .h3,
html:not(.css-theme-loaded) h4,
html:not(.css-theme-loaded) .h4,
html:not(.css-theme-loaded) h5,
html:not(.css-theme-loaded) .h5,
html:not(.css-theme-loaded) h6:not(.logo),
html:not(.css-theme-loaded) .h6:not(.logo) {
    font-weight: normal !important;
}

html:not(.css-theme-loaded) .header__counter {
    display: none;
}

html:not(.css-theme-loaded) .header__btn-compare,
html:not(.css-theme-loaded) .header__btn-wishlist {
    display: flex;
}

html:not(.css-theme-loaded) .product-options {
    color: transparent !important;
}

html:not(.css-theme-loaded) .menu__item > a > span,
html:not(.css-theme-loaded) .header__btn-search > span,
html:not(.css-theme-loaded) .header__btn-cart > span,
.breadcrumbs ul {
    line-height: 14px !important;
}

html:not(.css-theme-loaded) .rimage {
    position: relative;
}

html:not(.css-theme-loaded) .rimage__img {
    position: absolute;
    top: 0;
    left: 0;
}

html:not(.css-theme-loaded) .rimage__img--show {
    visibility: hidden;
}

html:not(.css-theme-loaded) .rimage__img--fade-in {
    opacity: 0;
}

html:not(.css-theme-loaded) .btn {
    border-width: 1px;
}

@media (min-width: 1025px) {
    html:not(.css-theme-loaded) .btn {
        min-height: 35px;
        padding: 0 16px;
    }

    html:not(.css-theme-loaded) .header__line-top {
        min-height: 38px;
    }

    html:not(.css-theme-loaded) .header__line-bottom {
        min-height: 39px;
    }

    html:not(.css-theme-loaded) .header__logo--center + .header__sidebar {
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
    }

    html:not(.css-theme-loaded) .header__btn-cart > span {
        margin-top: 0 !important;
    }

    html:not(.css-theme-loaded) .header__nav {
        height: calc(100% + 12px);
        margin-top: -6px;
    }

    html:not(.css-theme-loaded) .menu__panel {
        height: 100%;
    }

    html:not(.css-theme-loaded) .menu__panel > .menu__item > a {
        height: 100%;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }

    html:not(.css-theme-loaded) .collections__sidebar--width-md {
        width: 300px;
    }
}

@media (max-width: 1024px) {
    html:not(.css-theme-loaded) .btn {
        min-height: 50px;
        padding: 0 10px;
    }

    html:not(.css-theme-loaded) .header__line-top {
        min-height: 50px;
    }

    html:not(.css-theme-loaded) .header__line-bottom {
        height: 0;
    }
}

[class*='promobox__content--animation'] {
    opacity: 0;
    transition-property: transform, opacity;
    will-change: transform, opacity;
}

i {
    display: inline-flex;
    align-items: center;
}

[class*='icon-theme-'] {
    display: inline-block;
}

.promobox:not(.promobox--is-slider) .promobox__content {
    transition-duration: 0.5s !important;
}

.promobox__content--animation-scale-in {
    transform: scale(1.7);
}

.promobox__content--animation-scale-out {
    transform: scale(0.7);
}

.promobox__content--animation-translate-top {
    transform: translate3d(0, -100px, 0);
}

.promobox__content--animation-translate-bottom {
    transform: translate3d(0, 100px, 0);
}

.promobox__content--animation-translate-left {
    transform: translate3d(-100px, 0, 0);
}

.promobox__content--animation-translate-right {
    transform: translate3d(100px, 0, 0);
}

.loader {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 0.3s;
    will-change: opacity;
}

.loader.visible {
    opacity: 1;
}

.loader.delay {
    transition-delay: 0.5s;
}

.loader img {
    display: block
}

.loader__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    background-color: #ffffff;
}

.loader--page {
    z-index: 9999;
    pointer-events: none;
}

.loader__spinner {
    position: relative;
    text-indent: -9999em
}

.loader__spinner.fixed {
    position: fixed;
    top: 50%;
    z-index: 9999;
    transform: translate3d(0, -50%, 0);
}

.loading-element {
    position: relative !important;
    cursor: progress;
}

.loading-element * {
    cursor: progress;
}

.slider__slick:not(.slick-initialized) .slider__slide:not(:first-child),
.carousel__slick:not(.slick-initialized) img:not(:first-child) {
    display: none;
}

@media (min-width: 1025px) {
    .loader--page {
        cursor: default !important;
    }
}

.loader--page .loader__bg {
    opacity: 0.8;
}

.cssload-container {
    position: relative;
}

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    border-left-color: rgb(0, 0, 0);
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
}

.cssload-whirlpool {
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-rotate {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}

[data-product-handle="sweater-with-slogan"] .product-options__section[data-property="colour"] [data-value="pink"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1937547927_2_4_1_92x.progressive.jpg?v=1547894882) !important;
}

[data-product-handle="sweater-with-slogan"] .product-options__section[data-property="colour"] [data-value="white"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1937547318_2_4_1_92x.progressive.jpg?v=1547894982) !important;
}

[data-product-handle="jersey-graphic-tee"] .product-options__section[data-property="select-color"] [data-value="lightpink"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/2121900600_2_4_1_1_92x.progressive.jpg?v=1547895606) !important;
}

[data-product-handle="jersey-graphic-tee"] .product-options__section[data-property="select-color"] [data-value="gainsboro"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/2121900712_2_4_1_1_92x.progressive.jpg?v=1547895660) !important;
}

[data-product-handle="round-neck-sweater"] .product-options__section[data-property="select-color"] [data-value="black"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456401_2_5_1_1_92x.progressive.jpg?v=1547912316) !important;
}

[data-product-handle="round-neck-sweater"] .product-options__section[data-property="select-color"] [data-value="yellow"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456310_2_5_1_1_92x.progressive.jpg?v=1547912359) !important;
}

[data-product-handle="round-neck-sweater"] .product-options__section[data-property="select-color"] [data-value="pink"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456645_2_5_1_1_92x.progressive.jpg?v=1547912397) !important;
}

[data-product-handle="round-neck-sweater"] .product-options__section[data-property="select-color"] [data-value="white"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456712_2_5_1_1_92x.progressive.jpg?v=1547912439) !important;
}

[data-product-handle="trainers-with-heel-detail"] .product-options__section[data-property="select-color"] [data-value="white"] {
    background-color: #ffffff !important;
}

[data-product-handle="trainers-with-heel-detail"] .product-options__section[data-property="select-color"] [data-value="white"][data-was-processed] {
    background-image: none !important;
}

[data-product-handle="trainers-with-heel-detail"] .product-options__section[data-property="select-color"] [data-value="powderblue"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1202331001_2_5_1_1_92x.progressive.jpg?v=1547913095) !important;
}

[data-product-handle="trainers-with-heel-detail"] .product-options__section[data-property="select-color"] [data-value="pink"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1205331001_2_5_1_1_92x.progressive.jpg?v=1547913138) !important;
}

[data-product-handle="hoodie-with-slogan"] .product-options__section[data-property="colour"] [data-value="black"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1935457407_2_4_1_92x.progressive.jpg?v=1547913876) !important;
}

[data-product-handle="hoodie-with-slogan"] .product-options__section[data-property="colour"] [data-value="gainsboro"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1935457812_2_4_1_92x.progressive.jpg?v=1547913936) !important;
}

[data-product-handle="belted-chino-trousers"] .product-options__section[data-property="color"] [data-value="green"] {
    background-color: #337563 !important;
}

[data-product-handle="belted-chino-trousers"] .product-options__section[data-property="color"] [data-value="pink"] {
    background-color: #e7c7c2 !important;
}

[data-product-handle="belted-chino-trousers"] .product-options__section[data-property="color"] [data-value="gainsboro"] {
    background-color: #d5d2d1 !important;
}

[data-product-handle="copy-of-loose-fit-round-neck-sweater"] .product-options__section[data-property="color"] [data-value="black1"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456401_2_5_1_1_3e332e60-d251-47d9-b8b4-a002f5da088c_92x.progressive.jpg?v=1549660081) !important;
}

[data-product-handle="copy-of-loose-fit-round-neck-sweater"] .product-options__section[data-property="color"] [data-value="yellow1"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456310_2_5_1_1_e49ddcb3-5593-411a-b350-0588cc635181_92x.progressive.jpg?v=1549660136) !important;
}

[data-product-handle="copy-of-loose-fit-round-neck-sweater"] .product-options__section[data-property="color"] [data-value="1pink"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456645_2_5_1_1_11c7dc6d-bd05-4f50-85b7-f8604e6aaf0c_92x.progressive.jpg?v=1549660180) !important;
}

[data-product-handle="copy-of-loose-fit-round-neck-sweater"] .product-options__section[data-property="color"] [data-value="white1"][data-was-processed] {
    background-image: url(https://cdn.shopify.com/s/files/1/0026/2910/7764/files/1931456712_2_5_1_1_bd0612d1-6193-495a-8fab-9849ec662fb3_92x.progressive.jpg?v=1549660222) !important;
}

.st0 {
    fill: #ddd
}

.st1 {
    fill: #141414
}

.st2 {
    fill: #919191
}

.st3 {
    fill: #858585
}

.st4 {
    fill: #fff
}

.st5 {
    fill: #333
}

.spr-container {
    padding: 24px;
    border-color: #ECECEC;
}

.spr-review, .spr-form {
    border-color: #ECECEC;
}

/*END: Preload styles */
