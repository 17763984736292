//font family
$ff: 'Archivo', sans-serif;

//font size
$fs:                         13px;
$fs-lg:                      15px;
$fs-h1:                      44px;
$fs-h2:                      32px;
$fs-h3:                      24px;
$fs-h4:                      18px;
$fs-h5:                      15px;
$fs-h6:                      13px;
$fs-list:                    13px;
$fs-list-lg:                 15px;

//line height
$lh:                         17px;
$lh-lg:                      23px;
$lh-h1:                      48px;
$lh-h2:                      35px;
$lh-h3:                      31px;
$lh-h4:                      24px;
$lh-h5:                      21px;
$lh-h6:                      17px;
$lh-list:                    23px;
$lh-list-lg:                 23px;