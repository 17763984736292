.popup-confirm-transfer-data {
  width: 510px;
  max-width: 100%;
  &__close {
    top: 16px;
    right: 16px;
    z-index: 1;
  }
  &__content {
    max-width: 390px;
  }
}