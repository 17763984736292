.popup-wishlist-full {
  max-width: 100%;
  @include screen-up(sm) {
    width: 90%;
  }
  @include screen-down(sm) {
    width: 100%;
  }
  &__content {
    margin: 0 -20px;
  }
}