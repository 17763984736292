.tippy-box[data-theme~='tomato'] {
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  font-style: italic;
  -webkit-perspective: 700px;
  perspective: 700px;
  z-index: 9999;
  outline: 0;
  //transition-timing-function: cubic-bezier(.165, .84, .44, 1);
  transition: all cubic-bezier(.46, .1, .52, .98);
  pointer-events: none;
  line-height: 1.4
}

.tippy-box[data-theme~='tomato'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: black;
}
.tippy-box[data-theme~='tomato'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: black;
}
.tippy-box[data-theme~='tomato'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: black;
}
.tippy-box[data-theme~='tomato'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: black;
}

.tippy-box[data-theme~='tomato'] > .tippy-svg-arrow {
  fill: black;
}
