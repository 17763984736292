@mixin container {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1025px) {
    padding-right: 15px;
    padding-left: 15px
  }
  @media (min-width: 541px) {
    max-width: 540px
  }
  @media (min-width: 778px) {
    max-width: 740px
  }

  @media (min-width: 1025px) {
    max-width: 980px
  }

  @media (min-width: 1260px) {
    max-width: 1200px
  }
}

@mixin row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  @media (min-width: 1025px) {
    margin-right: -15px;
    margin-left: -15px
  }
}

@function percent($cols) {
  @return 100% / 12 * $cols;
}

@mixin colDefaults {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  @media (min-width: 1025px) {
    padding-right: 15px;
    padding-left: 15px
  }
}

@mixin col($cols) {
  //@include colDefaults;
  flex: 0 0 percent($cols);
  max-width: percent($cols);
}

@mixin d-flex-hcenter {
  display: flex;
  justify-content: center;
}
