/*================ Rich Text Editor ================*/
.rte {
  img {
    height: auto;
  }
}

.text-center.rte,
.text-center .rte {
  ul,
  ol {
    margin-left: 0;
    list-style-position: inside;
  }
}

// allow table to scroll for tables in the RTE since we don't know
// how many columns they will contain. Class added by JS.
// sass-lint:disable no-misspelled-properties
.rte-table {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// This class is wrapped around YouTube/Vimeo embeds in the RTE
// to make them responsive and prevent layout breaking
.rte__video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// This class is wrapped around tables in the RTE
// to prevent layout breaking with a scrollable parent
.rte__table-wrapper {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
