.lookbook {
  &__picker-circle {
    &::before,
    &::after {
      display: block;
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &::before {
      @include size(20px);
      margin-top: -10px;
      margin-left: -10px;
      opacity: 0.7;
      @include screen-up(lg) {
        transition-property: transform, opacity;
        will-change: transform, opacity;
      }
    }
    &::after {
      @include size(10px);
      margin-top: -5px;
      margin-left: -5px;
    }
  }
  &__picker:hover &__picker-circle,
  &__picker-circle.open {
    &::before {
      @include screen-up(lg) {
        transform: scale(2);
        opacity: 0.9;
      }
    }
  }
  &__picker-circle.open + * &__product {
    @include screen-up(lg) {
      display: block !important;
    }
  }
  &__product {
    @include screen-up(lg) {
      width: 160px;
      z-index: 1;
    }
  }
}