.header {
  z-index: 3;
  &__spacer {
    display: none;
  }
  &__spacer--visible {
    display: block;
    position: relative;
    width: 100%;
  }
  &__tape {
    min-height: 35px;
    i[data-action="close"]::before {
      @include screen-up(md) {
        width: 30px;
      }
    }
  }
  &__tape-close {
    @include screen-down(md) {
      top: 2px;
    }
  }
  &__bottom-tape {
    @include screen-up(md) {
      height: 70px;
    }
  }
  &__bottom-tape img {
    @include screen-up(md) {
      max-width: none;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  &__content--sticky {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 2;
    will-change: opacity;
    transition-property: opacity;
    &:hover {
      opacity: 1 !important;
    }
  }
  &__line-top {
    @include screen-up(md) {
      min-height: 38px;
      z-index: 2;
    }
    @include screen-down(md) {
      min-height: 50px;
      z-index: 2;
    }
  }
  &__line-bottom {
    @include screen-up(md) {
      min-height: 39px;
      z-index: 1;
    }
    @include screen-down(md) {
      height: 0;
    }
  }
  &__logo svg {
    @include screen-down(sm) {
      max-width: 46px;
    }
  }
  &__logo--center + &__sidebar {
    @include screen-up(md) {
      position: absolute;
      padding-right: 15px;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
    }
  }
  &__nav {
    @include screen-up(md) {
      height: calc(100% + 12px);
      margin-top: -6px;
      z-index: 1;
    }
  }
  &__counter {
    position: absolute;
    top: -3px;
    left: 22px;
    &[data-js-cart-count-mobile='0'],
    &[data-js-wishlist-count='0'],
    &[data-js-compare-count='0'] {
      display: none !important;
    }
  }
  &__counter[data-js-cart-count-mobile='0'] + &__counter-spacer {
    display: none !important;
  }
  &__content--sticky &__logo--sticky-hidden {
    @include screen-up(md) {
      display: none !important;
    }
  }
  &__content--sticky &__sticky-logo {
    * {
      @media (min-width: 1380px) {
        height: 100%;
      }
    }
    img {
      @media (min-width: 1380px) {
        width: auto;
      }
    }
  }
  &__content--sticky &__sticky-logo--containerized {
    @include screen-up(md) {
      display: flex !important;
    }
  }
  &__content--sticky &__sticky-logo--displaced {
    @media (min-width: 1380px) {
      display: flex !important;
    }
  }
  &__sticky-sidebar {
    @media (min-width: 1540px) {
      display: flex !important;
    }
  }
  &__btn-services,
  &__btn-account,
  &__btn-wishlist,
  &__btn-compare,
  &__btn-cart,
  &__btn-currency,
  &__btn-language,
  &__btn-search {
    transition-property: color;
    will-change: color;
    & > i .icon {
      transition-property: fill;
      will-change: fill;
    }
  }
  &__btn-currency span {
    min-width: 28px;
  }
  &__btn-services,
  &__btn-account,
  &__btn-wishlist,
  &__btn-compare,
  &__btn-cart,
  &__btn-currency,
  &__btn-language {
    &::before {
      @include screen-up(md) {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: -5px;
        left: -8px;
        right: -8px;
      }
    }
  }
  &__btn-currency,
  &__btn-language {
    i {
      @include screen-up(md) {
        width: 15px;
      }
    }
  }
  &__btn-currency span {
    @include screen-up(md) {
      min-width: 34px;
    }
  }
  &__dropdown {
    @include screen-up(md) {
      margin-top: 4px;
    }
  }
  &__dropdown,
  &__ly-languages-switcher .select__dropdown {
    @include screen-up(md) {
      min-width: 169px;
    }
  }
  &__password {
    @include screen-up(md) {
      min-width: 260px;
    }
  }
  &__ly-languages-switcher {
    .select i {
      margin-top: -15px;
    }
  }
}
