@mixin pb($distance) {
  padding-bottom: $distance+px;
}

@mixin pl($distance) {
  padding-left: $distance+px;
}
@mixin pt($distance) {
  padding-top: $distance+px;
}
@mixin pr($distance) {
  padding-right: $distance+px;
}
@mixin px($distance) {
  padding-left: $distance+px;
  padding-right: $distance+px;
}

@mixin py($distance) {
  padding-top: $distance+px;
  padding-bottom: $distance+px;
}
