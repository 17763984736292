.block {
  &__enabled {
    color: #959595;
  };
  &__disabled {
    text-decoration: line-through !important;
    cursor: not-allowed;
    color: #959595 !important;
  };
  &__text {
    &_bold {
      font-weight: bold;
    }
  }
}

.label--transparent {
  color: black;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 2px 2px 2px 2px;
  font-size: 12px;
  line-height: .9em;
  height: 14px;
  width: 14px;
  &.girl {
    background-color: pink;
  }
  &.boy {
    background-color: skyblue;
  }
  &.boy {
    background-color: skyblue;
  }

  &.other {
    background-color: gray;
    color: white;
  }
}

.hover {
  &:hover {
    background-color: rgba(0,0,0,.15);
  }
}

.label--basic {
  color: black;
  border: 1px solid rgba(0,0,0,.3)
}

.border-on-hover {
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  &:hover {
    border-color: black;
  }
}
