.links-tree__container {
  @include container;
  @include mt(10);
  @include mt-lg(25);
}

.links-tree__block {
  @include mt(10);
  @include mb(10);
}

.links-tree__header {
  @include mb(10);
  @include mt(10);
}

.links-tree__title {
  @include pb(5);
  @include font-weight-bold;
  @include mb(15);
}

.links-tree__item {
  @include pl(15);
  @include py(10);
  @include mb(15);
}
