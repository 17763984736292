.breadcrumbs {
  @include container;
  @include mt(15);
  @include mb(15);
  font-size: 10px;
  ul {
    @include list-unstyled;
    li {
      @include list-unstyled;
    }
  }
  .breadcrumbs__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include screen-up(lg) {
      justify-content: start;
    }
  }
  .breadcrumbs__block {
    span {
      font-size: 13px;
    }
    &::before {
      content: none !important;
    }
  }
  .breadcrumbs__block-home {}
  .breadcrumbs__block-middle {}
  .breadcrumbs__item {}
}
