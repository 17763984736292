.popup-delivery-return {
  width: 785px;
  max-width: 100%;
  &__close {
    top: 16px;
    right: 16px;
    z-index: 1;
  }
  &__content {
    @include screen-up(md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}