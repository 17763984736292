/**
 * Margins
 */

// Lg
@mixin mt-lg($distance) {
  @include screen_up(lg) {
    margin-top: $distance+px;
  }
}

@mixin mt($distance) {
  margin-top: $distance+px;
}

@mixin mb($distance) {
  margin-bottom: $distance+px;
}
