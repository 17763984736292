.standard-color-white {  background-color: #FFFFFF !important; border: #E5E5E5 1px solid !important; }
.standard-color-aliceblue, .standard-color-alice-blue {  background-color: #f0f8ff !important;  }
.standard-color-antiquewhite, .standard-color-antique-white {  background-color: #faebd7 !important;  }
.standard-color-aqua {  background-color: #00ffff !important;  }
.standard-color-aquamarine, .standard-color-aqua-marine { background-color: #7fffd4 !important;  }
.standard-color-azure {  background-color: #f0ffff !important;  }
.standard-color-beige {  background-color: #f5f5dc !important;  }
.standard-color-bisque {  background-color: #ffe4c4 !important;  }
.standard-color-black {  background-color: #000000 !important;  }
.standard-color-blanchedalmond, .standard-color-blanched-almond  {  background-color: #ffebcd !important;  }
.standard-color-blue {  background-color: #0000ff !important;  }
.standard-color-blueviolet,.standard-color-blue-violet  {  background-color: #8a2be2 !important;  }
.standard-color-brown {  background-color: #a52a2a !important;  }
.standard-color-burlywood, .standard-color-burly-wood {  background-color: #deb887 !important;  }
.standard-color-cadetblue, .standard-color-cadet-blue {  background-color: #5f9ea0 !important;  }
.standard-color-chartreuse {  background-color: #7fff00 !important; }
.standard-color-chocolate {  background-color: #d2691e !important;  }
.standard-color-coral {  background-color: #ff7f50 !important;  }
.standard-color-cornflowerblue, .standard-color-cornflower-blue {  background-color: #6495ed !important;  }
.standard-color-cornsilk {  background-color: #fff8dc !important;  }
.standard-color-crimson {  background-color: #dc143c !important;  }
.standard-color-cyan {  background-color: #00ffff !important;  }
.standard-color-darkblue, .standard-color-dark-blue  {  background-color: #00008b !important;  }
.standard-color-darkcyan, .standard-color-dark-cyan {  background-color: #008b8b !important;  }
.standard-color-darkgoldenrod, .standard-color-dark-golden-rod  {  background-color: #b8860b !important;  }
.standard-color-darkgray, .standard-color-dark-gray {  background-color: #a9a9a9 !important;  }
.standard-color-darkgreen, .standard-color-dark-green {  background-color: #006400 !important;  }
.standard-color-darkkhaki, .standard-color-dark-khaki  {  background-color: #bdb76b !important;  }
.standard-color-darkmagenta, .standard-color-dark-magenta {  background-color: #8b008b !important;  }
.standard-color-darkolivegreen, .standard-color-dark-olive-green  {  background-color: #556b2f !important;  }
.standard-color-darkorange, .standard-color-dark-orange {  background-color: #ff8c00 !important;  }
.standard-color-darkorchid, .standard-color-dark-orchid {  background-color: #9932cc !important;  }
.standard-color-darkred, .standard-color-dark-red {  background-color: #8b0000 !important;  }
.standard-color-darksalmon, .standard-color-dark-salmon {  background-color: #e9967a !important;  }
.standard-color-darkseagreen, .standard-color-dark-sea-green {  background-color: #8fbc8f !important;  }
.standard-color-darkslateblue, .standard-color-dark-slate-blue {  background-color: #483d8b !important;  }
.standard-color-darkslategray, .standard-color-dark-slate-gray {  background-color: #2f4f4f !important;  }
.standard-color-darkturquoise, .standard-color-dark-turquoise {  background-color: #00ced1 !important;  }
.standard-color-darkviolet, .standard-color-dark-violet {  background-color: #9400d3 !important;  }
.standard-color-deeppink, .standard-color-deep-pink {  background-color: #ff1493 !important;  }
.standard-color-deepskyblue, .standard-color-deep-sky-blue {  background-color: #00bfff !important;  }
.standard-color-dimgray, .standard-color-dim-gray {  background-color: #696969 !important;  }
.standard-color-dimgrey, .standard-color-dim-grey {  background-color: #696969 !important;  }
.standard-color-dodgerblue, .standard-color-dodger-blue {  background-color: #1e90ff !important;  }
.standard-color-firebrick, .standard-color-fire-brick {  background-color: #b22222 !important;  }
.standard-color-floralwhite, .standard-color-floral-white {  background-color: #fffaf0 !important;  }
.standard-color-forestgreen, .standard-color-forest-green {  background-color: #228b22 !important;  }
.standard-color-fuchsia {  background-color: #ff00ff !important;  }
.standard-color-gainsboro {  background-color: #dcdcdc !important;  }
.standard-color-ghostwhite, .standard-color-ghost-white {  background-color: #f8f8ff !important;  }
.standard-color-gold {  background-color: #ffd700 !important;  }
.standard-color-goldenrod, .standard-color-golden-rod {  background-color: #daa520 !important;  }
.standard-color-gray {  background-color: #808080 !important;  }
.standard-color-grey {  background-color: #808080 !important;  }
.standard-color-green {  background-color: #008000 !important;  }
.standard-color-greenyellow, .standard-color-green-yellow {  background-color: #adff2f !important;  }
.standard-color-honeydew, .standard-color-honey-dew {  background-color: #f0fff0 !important;  }
.standard-color-hotpink, .standard-color-hot-pink {  background-color: #ff69b4 !important;  }
.standard-color-indianred, .standard-color-indian-red {  background-color: #cd5c5c !important;  }
.standard-color-indigo {  background-color: #4b0082 !important;  }
.standard-color-ivory {  background-color: #fffff0 !important;  }
.standard-color-khaki {  background-color: #f0e68c !important;  }
.standard-color-lavender {  background-color: #e6e6fa !important;  }
.standard-color-lavenderblush, .standard-color-lavender-blush {  background-color: #fff0f5 !important;  }
.standard-color-lawngreen, .standard-color-lawn-green {  background-color: #7cfc00 !important;  }
.standard-color-lemonchiffon, .standard-color-lemon-chiffon {  background-color: #fffacd !important;  }
.standard-color-lightblue, .standard-color-light-blue {  background-color: #add8e6 !important;  }
.standard-color-lightcoral, .standard-color-light-coral {  background-color: #f08080 !important;  }
.standard-color-lightcyan, .standard-color-light-cyan {  background-color: #e0ffff !important;  }
.standard-color-lightgoldenrodyellow, .standard-color-light-golden-rod-yellow {  background-color: #fafad2 !important;  }
.standard-color-lightgray, .standard-color-light-gray {  background-color: #d3d3d3 !important;  }
.standard-color-lightgrey, .standard-color-light-grey {  background-color: #d3d3d3 !important;  }
.standard-color-lightgreen, .standard-color-light-green {  background-color: #90ee90 !important;  }
.standard-color-lightpink, .standard-color-light-pink {  background-color: #ffb6c1 !important;  }
.standard-color-lightsalmon, .standard-color-light-salmon {  background-color: #ffa07a !important;  }
.standard-color-lightseagreen, .standard-color-light-sea-green {  background-color: #20b2aa !important;  }
.standard-color-lightskyblue, .standard-color-light-sky-blue {  background-color: #87cefa !important;  }
.standard-color-lightslategray, .standard-color-light-slate-gray {  background-color: #778899 !important;  }
.standard-color-lightsteelblue, .standard-color-light-steel-blue {  background-color: #b0c4de !important;  }
.standard-color-lightyellow, .standard-color-light-yellow {  background-color: #ffffe0 !important;  }
.standard-color-lime {  background-color: #00ff00 !important;  }
.standard-color-limegreen, .standard-color-lime-green {  background-color: #32cd32 !important;  }
.standard-color-linen {  background-color: #faf0e6 !important;  }
.standard-color-magenta {  background-color: #ff00ff !important;  }
.standard-color-maroon {  background-color: #800000 !important;  }
.standard-color-mediumaquamarine, .standard-color-medium-aqua-marine {  background-color: #66cdaa !important;  }
.standard-color-mediumblue, .standard-color-medium-blue {  background-color: #0000cd !important;  }
.standard-color-mediumorchid, .standard-color-medium-orchid {  background-color: #ba55d3 !important;  }
.standard-color-mediumpurple, .standard-color-medium-purple {  background-color: #9370d8 !important;  }
.standard-color-mediumseagreen, .standard-color-medium-sea-green {  background-color: #3cb371 !important;  }
.standard-color-mediumslateblue, .standard-color-medium-slate-blue {  background-color: #7b68ee !important;  }
.standard-color-mediumspringgreen, .standard-color-medium-spring-green {  background-color: #00fa9a !important;  }
.standard-color-mediumturquoise, .standard-color-medium-turquoise {  background-color: #48d1cc !important;  }
.standard-color-mediumvioletred, .standard-color-medium-violet-red {  background-color: #c71585 !important;  }
.standard-color-midnightblue, .standard-color-midnight-blue {  background-color: #191970 !important;  }
.standard-color-mintcream, .standard-color-mint-cream {  background-color: #f5fffa !important;  }
.standard-color-mistyrose, .standard-color-misty-rose {  background-color: #ffe4e1 !important;  }
.standard-color-moccasin {  background-color: #ffe4b5 !important;  }
.standard-color-navajowhite, .standard-color-navajo-white {  background-color: #ffdead !important;  }
.standard-color-navy {  background-color: #000080 !important;  }
.standard-color-oldlace, .standard-color-old-lace {  background-color: #fdf5e6 !important;  }
.standard-color-olive {  background-color: #808000 !important;  }
.standard-color-olivedrab, .standard-color-olive-drab {  background-color: #6b8e23 !important;  }
.standard-color-orange {  background-color: #ffa500 !important;  }
.standard-color-orangered, .standard-color-orange-red {  background-color: #ff4500 !important;  }
.standard-color-orchid {  background-color: #da70d6 !important;  }
.standard-color-palegoldenrod, .standard-color-pale-golden-rod {  background-color: #eee8aa !important;  }
.standard-color-palegreen, .standard-color-pale-green {  background-color: #98fb98 !important;  }
.standard-color-paleturquoise, .standard-color-pale-turquoise {  background-color: #afeeee !important;  }
.standard-color-palevioletred, .standard-color-pale-violet-red {  background-color: #d87093 !important;  }
.standard-color-papayawhip, .standard-color-papaya-whip {  background-color: #ffefd5 !important;  }
.standard-color-peachpuff, .standard-color-peach-puff {  background-color: #ffdab9 !important;  }
.standard-color-peru {  background-color: #cd853f !important;  }
.standard-color-pink {  background-color: #ffc0cb !important;  }
.standard-color-plum {  background-color: #dda0dd !important;  }
.standard-color-powderblue, .standard-color-powder-blue {  background-color: #b0e0e6 !important;  }
.standard-color-purple {  background-color: #800080 !important;  }
.standard-color-red {  background-color: #ff0000 !important;  }
.standard-color-rosybrown, .standard-color-rosy-brown {  background-color: #bc8f8f !important;  }
.standard-color-royalblue, .standard-color-royal-blue {  background-color: #4169e1 !important;  }
.standard-color-saddlebrown, .standard-color-saddle-brown {  background-color: #8b4513 !important;  }
.standard-color-salmon {  background-color: #fa8072 !important;  }
.standard-color-sandybrown, .standard-color-sandy-brown {  background-color: #f4a460 !important;  }
.standard-color-seagreen, .standard-color-sea-green {  background-color: #2e8b57 !important;  }
.standard-color-seashell, .standard-color-sea-shell {  background-color: #fff5ee !important;  }
.standard-color-sienna {  background-color: #a0522d !important;  }
.standard-color-silver {  background-color: #c0c0c0 !important;  }
.standard-color-skyblue, .standard-color-sky-blue {  background-color: #87ceeb !important;  }
.standard-color-slateblue, .standard-color-slate-blue {  background-color: #6a5acd !important;  }
.standard-color-slategray, .standard-color-slate-gray {  background-color: #708090 !important;  }
.standard-color-slategrey, .standard-color-slate-grey {  background-color: #708090 !important;  }
.standard-color-snow {  background-color: #fffafa !important;  }
.standard-color-springgreen, .standard-color-spring-green {  background-color: #00ff7f !important;  }
.standard-color-steelblue, .standard-color-steel-blue {  background-color: #4682b4 !important;  }
.standard-color-tan {  background-color: #d2b48c !important;  }
.standard-color-teal {  background-color: #008080 !important;  }
.standard-color-thistle {  background-color: #d8bfd8 !important;  }
.standard-color-tomato {  background-color: #ff6347 !important;  }
.standard-color-turquoise {  background-color: #40e0d0 !important;  }
.standard-color-violet {  background-color: #ee82ee !important;  }
.standard-color-wheat {  background-color: #f5deb3 !important;  }
.standard-color-whitesmoke, .standard-color-white-smoke {  background-color: #f5f5f5 !important;  }
.standard-color-yellow {  background-color: #ffff00 !important;  }
.standard-color-yellowgreen, .standard-color-yellow-green {  background-color: #9acd32 !important;  }
.standard-color-transparent {  background-color: transparent !important;  }
//arrow
.standard-color-arrow svg {  color: #FFFFFF !important;  }
.standard-color-white,
.standard-color-antiquewhite, .standard-color-antique-white,
.standard-color-navajowhite, .standard-color-navajo-white,
.standard-color-floralwhite, .standard-color-floral-white,
.standard-color-ghostwhite, .standard-color-ghost-white,
.standard-color-whitesmoke, .standard-color-white-smoke,
.standard-color-lightgray, .standard-color-light-gray,
.standard-color-lightslategray, .standard-color-light-slate-gray,
.standard-color-lightslategray, .standard-color-light-slate-gray {
  .standard-color-arrow svg {
    color: #000000 !important;
  }
}