.product-search {
  &__image {
    @include screen-down(lg) {
      width: 60px;
      min-width: 60px;
    }
    img {
      width: 100%;
    }
  }
  &__image-overlay-top {
    padding-left: 10px;
    padding-right: 10px;
  }
  &__image-overlay-top {
    padding-top: 10px;
  }
}