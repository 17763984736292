.products-card {
  .rimage {
    max-height: 30vh;
    height: 30vh;
  }
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-collection__button-add-to-cart {
  width: 100%;
}

.btn {
  &.itemInCart {
    //background-color: lightgrey !important;
    //opacity: 0.4;
    .btn__text {
      //color: lightgrey !important;
    }
    &:hover {
      .btn__icon {
        display: block;
      }
    }
  }
  &.js-product-button-add-to-cart {
    width: 100%;
  }
}
