input,
textarea,
select {
  border-radius: 0;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select {
  padding: 0 15px;
  margin-bottom: 20px;
  background-color: white;
  border: solid 1px transparent;
  will-change: background-color, border-color, color;
  transition-property: background-color, border-color, color;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
select {
  height: 35px;
}
input[type="checkbox"],
input[type="radio"] {
  margin-right: 7px;
  vertical-align: -1px;
}
input {
  &.success {

  }
  &.error {

  }
}
textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
}
label {
  margin-bottom: 2px;
  margin-right: 15px;
}
fieldset {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
}
form {
  margin-bottom: 20px;
}
.label-hidden {
  display: none;
}
.label-required::after {
  content: '*';
}
.note {
  position: relative;
  display: inline-block;
  min-height: 25px;
  padding: 4px 15px;
  margin-bottom: 20px;
  border-radius: 3px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 15px;
    border: solid 4px transparent;
    border-bottom-width: 5px;
  }
  p {
    margin-top: 15px;
  }
}
.input-quantity {
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  &--type-01 {
    [data-control] {
      min-width: 36px;
      transition-property: border-color;
    }
  }
  &--type-02 {
    input {
      width: 62px;
      min-width: 62px;
    }
    [data-control] {
      min-width: 26px;
    }
  }
}
.input-checkbox {
  input {
    display: none;
  }
  input + span {
    @include size(18px);
    min-width: 18px;
    margin-top: -2px !important;
  }
  span {
    @include screen-up(lg) {
      transition-property: color;
    }
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  input:checked + span i {
    display: flex !important;
  }
}
.input-boolean {
  input {
    display: none;
  }
  input + span {
    width: 30px;
    height: 18px;
    min-width: 30px;
    border-radius: 10px;
    transition-property: background-color, border-color;
    transition-timing-function: ease;
    span {
      width: 16px;
      min-width: 16px;
      height: 100%;
      left: -1px;
      border-radius: 50%;
      transition-property: left;
      transition-timing-function: ease;
    }
  }
  input:checked + span {
    span {
      left: calc(100% - 15px);
    }
  }
}
.select {
  z-index: 1;
  &.hovered {
    z-index: 2;
  }
  select {
    @include screen-up(lg) {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  i {
    @include screen-up(lg) {
      top: 50%;
      margin-top: -10px;
      pointer-events: none;
    }
  }
  &__dropdown {
    @include screen-up(lg) {
      min-width: 100%;
    }
  }
}