#footers-links {
  padding-top: 16px;
  a {
    font-size: 10px;
    line-height: 12px;
  }
  .vertical-sections {
    .tabs {
      display: flex;
      justify-content: center;
      .tabs__head {
        width: 30%;
        border: none;
        @media all and (min-width: 1024px){
          border-right: 1px solid #E5E5E5;
        }
        .tabs__btn {
          font-size: 10px;
        }
        .tabs__btn {
          &:hover {
            border-bottom: 1px solid #E5E5E5;
            //border-bottom-: #E5E5E5;
          }
        }
        .active {
          //border: none;
          font-weight: bold;
          border-width: 2px;
          border-bottom-color: #E5E5E5;
        }
      }
      .tabs__body {
        width: 70%;
        font-size: 10px;
        .tabs__content {
          padding: 0;
        }
      }
      .tabs__slider {
        flex-direction: column;
        //width: 25%;
      }
    }
  }
}
.footers-links {
  .footers-links__tabs {
    //display: flex;
    //justify-content: center;
    .footers-links__tab {
      //min-width: 300px;
      //display: flex;
      //justify-content: center;
      //cursor: pointer;
      &.active {
        //background-color: gray;
        //color: white;
        //font-weight: bold;
      }
    }
  }
}