$admin-box-height: 50vh;

.admin__bottom-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $admin-box-height;
  z-index: 1000000;
  background-color: white;
  border-top: 1px solid gray;
  box-shadow: 0px -30px 72px black;
  overflow-y: auto;
  overflow-x: hidden;
}

.staff-editable-button {
  box-shadow: 4px 4px 8px rgba(0,0,0,.7);
}

.text-editor {
  .sun-editor-editable {
    ul {
      list-style-type: none;
    }
  }
}

.admin__container {
  .main-toolbar {
    z-index: 1000003;
    bottom: calc(#{$admin-box-height} - 20px);
    .icon {
      width: 30px;
    }
    svg {
      //transform: scale(2.5);
    }
  }
  .tabs__head {
    &.admin__mainTabs {
      position: fixed;
      bottom: calc(#{$admin-box-height} - 20px);
      //transform: translateX(50vw);
      z-index: 1000001;
      width: 100%;
      background-color: transparent;
      .tabs__slider {
        //width: 50vw;
        //margin: 0 auto;
        //margin: 0;
        //width: auto;
        padding-top: 0;

        color: black;
        background-color: white;
        border: 1px solid gray;
        box-shadow: 4px 4px 8px rgba(0,0,0,.3);
        width: fit-content;
        margin: 0 auto;
        //padding-left: 20px;
        .active {
          background-color: black;
          color: white;
        }
      }
    }
    .tabs__content {
      padding-bottom: 0;
    }
  }
  .text-editor {
    .sun-editor-editable {
      max-height: calc(#{$admin-box-height} - 180px);
    }
  }
  //@media (min-width: 1025px) {
    .tabs__content {
      //padding: 35px 0;
      padding-bottom: 0 !important;
    }
  //}
}