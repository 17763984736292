#theme-section-hero {
  .rimage__img {
    transition-property: none;
    opacity: 1;
    &.mobile-left {
      @media all and (max-width: 1000px) {
        width: auto;
        padding: 0;
        margin: 0;
      }
    }
  }
  .slick-slide {
    .promobox-container {
      //display: inherit;
      display: none !important;
      opacity: 0;
      z-index: 1;
      //left: -3000px !important;
      //position: absolute;
    }
    &.slick-current {
      a {
        z-index: 2;
      }
      .promobox-container {
        display: inline-block !important;
        opacity: 1;
        z-index: 2;
        left: 0;
      }
    }
  }
}
