*, *::before, *::after {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
  width: device-width
}

body {
  margin: 0;
  padding: 0;
  text-align: left
}
