@import "header";
@import "detailPage";
@import "productCard";
@import "helpers";
@import "uses";
@import "admin";
//@import "styleguidist";

// New styles
@import "variables";
@import "global";
@import "themes/defaultTheme";
@import "mixins/globalMixins";
@import "mixins/margins";
@import "mixins/paddings";
@import "typography";
@import "mixins/globalMixins";

@import "layout";
@import "colors";
@import "parts/components/links";
@import "parts/landing/hero";
@import "breadcrumbs";
@import "temporary";

@import "start-style";
@import "themes/theme";

.chip {
  padding: 3px;
  border: 1px solid rgba(0,0,0,.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  i {
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  }
  &:hover {
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  }
}

.products-grid-buttons {
  .icon {
    fill: #BDBDBD;
  }
  .active {
    .icon {
      fill: #141414;
    }
  }
}

.promobox__image {
  .rimage {
    padding-top: 50%;
    min-height: 300px;
  }
}

//.product-options__value--circle {
//  margin-bottom: 3px;
//  &.active {
//    margin-bottom: 10px;
//  }
//}

.product-page-gallery__main--single {
  .mainImage {
    width: 100%;
    min-height: 50vh;
    height: 50vh;
    max-height: 50vh;
    display: flex;
    align-content: center;
    justify-content: center;
    a {
      display: flex !important;
      align-content: center;
      justify-content: center;
    }
  }
}

.product-page-gallery__collage {
  .active {
    border: 1px solid gray;
  }
}

.tabs[data-type="mobile"] .tabs__body > div > div {
  display: block;
}

.carousel__prev, .carousel__next {
  opacity: 1;
}
.slick-track {
  display: flex;
  overflow: hidden;
  width: 100%;
  .carousel__item {
    width: 195px;
    min-width: 195px;
    max-width: 195px;
    position: relative;
  }
}

.footer__logo {
  width: 120px;
}
.logo-small {
  width: 120px;
  height: 32px;
}

.wide-checkout {
  .input-block {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    input {
      margin-bottom: 0;
    }
    label {
      width: 20%;
      max-width: 100px;
      //text-align: right;
    }
  }
  .delivery-and-payment {
    display: flex;
    &> div {
      width: 50%;
    }
    .input-block {
      width: 100%;
      label {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.autocomplete__variants {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background-color: darkgray;
  }
}

.gl-header__menu {
  .container {
    @include screen-up(md) {
      width:100%;
      //max-width: 900px;
    }
    .header__nav {
      @include screen-up(md) {
        width:100%;
        max-width: 900px;
      }
      .menu {
        @include screen-up(md) {
          width:100%;
          max-width: 900px;
        }
      }
    }
  }
}

.popup-cart {
  width: 290px;
}

.cart__image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.popup-navigation {
  background-color: white;
}

.popup-filters {
  @extend .popup-navigation;
  background-color: white;
  width: 290px;
}

.product-page-gallery__collage {
  img {
    max-width: 150px;
  }
  .col {
    max-width: 120px;
  }
  .thumbnail-container {
    width: 120px;
  }
}

.product-collection {
  .ph-item {
    .ph-col-1 {
      flex: 0 0 8.33333%;
    }
    padding: 0;
    border: none;
    .ph-col-12 {
      padding: 0;
    }
    .ph-picture {
      height: 60%;
      min-height: 300px;
    }
    .ph-row {
      div {
        min-height: 20px;
      }
    }
  }
  .ph-button {
    border: 1px solid black;
    height: 35px;
  }
  .ph-avatar {
    width: 20px;
    max-width: 20px;
    min-width: 20px;
  }
}

.icon {
  &.checked {
    fill: orange;
  }
}

.input-checkbox {
  &.disabled {
    text-decoration: line-through;
    a, span {
      color: lightgray;
      text-decoration: line-through;
    }
  }
}

//.collection-filters.row {
//  margin-bottom: -10px;
//  max-height: 800px;
//  overflow-y: auto;
//  padding-top: 10px;
//}

.checksPage {
  .checks_invalid {
    color: red;
  }
}

.sticked-header {
  max-width: 200px;
  width: 200px;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

#MainContent {
  overflow-x: auto;
}

.js-product-button-add-to-cart {
  @media all and (max-width: 400px) {
    .btn__text {
      font-size: 12px;
    }
    .btn__icon {
      display: none;
    }
  }
}

.se-toolbar {
  ul {
    li {
      &:before {
        display: none;
      }
    }
  }
}

.sorting-collections__products {
  width:100%;
}

.menu__title {
  font-weight: bold;
}

.popup-navigation {
  //height: 100%;
  //min-height: 100vh;
}


#root {

}

#popups {
  position: fixed;
  top: 0;
  left: 0;
  //bottom: 0;
  //right: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  .popups__background {
    background-color: rgba(255,255,255,.8);
    width: 100%;
    height: 100%;
    z-index: 100000;
    position: relative;
  }
}

.popup-cart__free-shipping_red {
  .free-shipping__progress {
    background-color: red;
  }
}

.footer__back-to-top {
  width: 30px;
  height: 30px;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.promobox-container {
  padding: 0;
  margin: 0;
  .promobox-item {
    padding: 0;
  }
}

.slick-prev {
  &:before {
    content: none !important;
  }
}
.slick-next {
  &:before {
    content: none !important;
  }
}

#theme-section-promos, #theme-section-hero {
  .slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-bottom: -10px;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);

    .slick-active {
      &:before {
        width: 10px;
        height: 10px;
        margin-bottom: 2px;
      }
    }
  }
}
.admin__bottom-dialog {
  .slick-prev {
    left: 0;
    z-index: 1;
    &:before {
      color: transparent;
    }
    .slick-prev:before {
      content: none !important;
    }
  }

  .slick-next {
    right: 0;
    //z-index: 1;
  }

  .slick-arrow {
    &:before {
      color: gray;
    }
    &:hover {
      &:before {
        color: black;
      }
    }
  }
  .slick-dots {
    bottom: 25px;
    li {
      &:before {
        margin-bottom: 4px;
        margin-right: 0px;
        margin-left: -17px;
      }
    }
  }
}
#theme-section-hero, #theme-section-promos {
  position: relative;
  .slick-prev {
    left: 0;
    z-index: 1;
  }

  .slick-next {
    right: 0;
    //z-index: 1;
  }
  .slick-arrow {
    &:before {
      color: gray;
    }
    &:hover {
      &:before {
        color: black;
      }
    }
  }
  .slick-dots {
    bottom: 25px;
    li {
      &:before {
        margin-bottom: 4px;
        margin-right: 0px;
        margin-left: -17px;
      }
    }
  }
}

.separator {
  width: 100%;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  .separator-text {
    position: absolute;
    top: -8px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    color: black;
  }
}

input {
  &.not-valid {
    border-color: red;
  }
}

#theme-section-promo-box {
  .rimage {

  }
}

#theme-section-promos {
  .promobox {
    .rimage {
      background-color: #f3f3f3;
    }
  }
}

#theme-section-popup {
  //.popup {
  //  &.show {
  //    .popup__body {
  //      transform: scale(0, 0);
  //      transform-origin: right;
  //      opacity: 1;
  //    }
  //  }
  //
  //  .popup__body {
  //    opacity: 0;
  //  }
  //}
  .popup__body {
    position: absolute;
    //position: fixed;
    .menu__megamenu {
      height: 100% !important;
    }
    .menu__item {
      line-height: 24px;
    }
  }
}

.drawer-right {
  right: 0px;
}

.category {
  &__text-block {
    margin-top: 36px;
    h2 {
      margin-bottom: 20px;
    }
  }
}

.toolbar {
  padding-top: 10px;
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  input {
    margin-bottom: 0px;
    margin-left: 10px;
  }
}


.label-150 {
  span {
    min-width: 150px;
  }
}

.popup {
  .popup__body {
    min-height: 100vh;
  }
  .popup-navigation__head, .popup-account__head, .popup-cart__head, .popup-services__head {
    max-width: 100%;
    width: 100%;
  }
  .popup-account {
    width: 100%;
    max-width: 290px;
    min-width: 250px;
  }
}

.free-shipping-summ {
  word-break: keep-all;
  white-space: nowrap;
}

@media all and (min-width: 778px) {
  .slide-menu-up {
    top: -40px!important;
  }
}

.promobox {
  .rimage {
    //width: 100vw;
    display: flex;
    justify-content: center;
  }
  .loading {
    //max-width: 40vw;
    //width: 40vw;
    display: inline-flex;
    justify-content: center;
    object-fit: initial;
    left: 50%;
    transform: translateX(-50%);
  }
}

.product-collection {
  .loading {
    object-fit: none;
  }
}
@media all and (max-width: 777px) {
  #theme-section-header {
    &.hide-header {
      /* This timing applies on the way OUT */
      transition-timing-function: ease-in;
      /* Quick on the way out */
      transition: 0.2s;
      /* Hide thing by pushing it outside by default */
      transform: translateY(-200%);
      opacity: 0;
    }

    &.show-header {
      /* This timing applies on the way IN */
      transition-timing-function: ease-out;

      /* A litttttle slower on the way in */
      transition: 0.2s;

      /* Move into place */
      transform: translateY(-1px);
      opacity: 1;
    }
  }
}

.related-links {
  a {
    color: #858585;
  }
  li {
    width: 100%;
  }
  .related-links__title {
    color: #858585;
    font-weight: bold;
  }
}

.sitemapPage {
  font-size: 18px;
  .links-tree_block {
    font-size: 18px;
  }
  .sitemap-header {
    font-size: 18px;
  }
}


.slick-slide {
  max-height: 70vh;
}

.admin__bottom-dialog {
  .slick-slide {
    max-height: 100%;
  }
}

.scale0 {
  transform: scale(0);
  opacity: 0;
}
.scale1 {
   transform: scale(1);
  opacity: 1;
}

.product-page-info__details-buttons {
  margin-bottom: 25px;
  a {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.additional-text {
  font-size: 13px;
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  a {
    display: initial;
  }
  &.mask-links {
    a {
      color: #858585;

      &:visited {
        color: #858585;
      }

      &:hover {
        font-weight: bold;
      }
    }
  }
}

.filters-group, .filters-seasons {
  label {
    text-transform: uppercase;
  }
}

.collection-control {
  .row {
    justify-content: space-between;
  }
}
.collection-control__sort-by {
  min-width: 100px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 785px;
  height: 817px;
  max-width: 100%;
  max-height: 90vh;
  background-color: white;
  margin: auto;
  padding: 25px;
  .dialog__body {
    overflow-y: auto;
    max-height: calc(90vh - 66px);
    padding-top: 20px;
  }
  .dialog__header {
    height: 16px;
  }
  .dialog__footer {
    position: absolute;
    bottom: 0;
    height: 5vh;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid gray;
  }
}

.range-draggable-area {

}
.btn-large {
  width: 200px;
}

.popup-navigation__search {
  position: fixed;
  top: 0;
  z-index: 10002;
  left: 0;
  right: 0;
}

.separator {
  border-bottom-color: #E5E5E5 !important;
}

.layer-navigation {
  .separator {
    margin-bottom: 20px;
  }
  .separator-text {
    //color: #858585;
    font-weight: normal;
  }
}

.collection-filter-by-price {
  padding-left: 10px;
  padding-right: 10px;
}

.product-search__image {
  @media all and (min-width: 1024px){
    a {
      height: 250px;
      max-height: 250px;
      img {
        max-height: 250px;
        object-fit: contain;
      }
    }
  }
}

.btn-add-large {
  width: 100%;
}
.btn-add-middle {
  max-width: 70%;
}
@media all and (max-width: 1023px) {
  .menu {
    a, span {
      font-size: 13px;
      line-height: 36px;
    }

    .menu__item--has-children {
      a, span {
        color: rgb(20, 20, 20) !important;
      }
      svg {
        width: 20px;
      }
    }

    .menu__item--group {
      a, span {
        color: rgb(20, 20, 20) !important;
        font-size: 15px;
      }
    }
  }

  .menu__level-0 {
    a, span {
      font-size: 15px;
      line-height: 36px;
      //span {
      color: rgb(20, 20, 20) !important;
      //}
    }
  }
}

@media all and (max-width: 1023px){
  .accord-new {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, overflow 0.5s ease-in-out 0.5s!important;
    //transform-origin: 50px 0;
    opacity: 0!important;
    //transform: scaleY(0);
    max-height: 0!important;
    overflow: hidden!important;
    &.open {
      transition: all 0.5s ease-in-out!important;
      transform-origin: 50px 0;
      transform: scaleY(1);
      opacity: 1!important;
      max-height: 2000rem!important;
      overflow: visible!important;
    }
  }
}
  .accord {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, overflow 0.5s ease-in-out 0.5s!important;
    //transform-origin: 50px 0;
    opacity: 0!important;
    //transform: scaleY(0);
    max-height: 0!important;
    overflow: hidden!important;
    &.open {
      transition: all 0.5s ease-in-out!important;
      transform-origin: 50px 0;
      transform: scaleY(1);
      opacity: 1!important;
      max-height: 2000rem!important;
      overflow: visible!important;
    }
  }

  .grayscaled-image {
    img {
      filter: grayscale(100%);
    }
  }

.menu__curtain, .menu__megamenu {
  transform-origin: 0 0;
  transition: height .3s ease-in-out;
  overflow: hidden;
  &.open {
    animation: open .3s ease-in-out;
    transition: height .3s ease-in-out;
    height: 540px;
    &.overflow-auto {
      overflow: auto;
    }
  }
  &.close {
    animation: close .3s ease-in-out;
    transition: height .3s ease-in-out;
    height: 0;
    overflow: hidden;
  }
}

@keyframes open {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes close {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}


  //. slick-active slick-current
.slick-slide {
  .promo-text {
    transition: ease-in-out .5s;
    transform-origin: center;
    animation: zoom .5s ease-in-out;
    transform: scale(1, 1);
  }
  &.slick-active {
    .promo-text {
      //transform: scale(2, 2);
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
  }
}
