$animation-css-duration: 0.3s;
$animation-css-low-duration: $animation-css-duration / 3 * 2;
$animation-product-hover-duration: 0.3s;
$animation-tabs-duration: 0.4s;
$animation-promobox-duration: 0.5s;
$animation-menu-desktop-duration: 0.4s;
$animation-menu-mobile-duration: 0.4s;
$animation-dropdown-duration: 0.3s;
$animation-accordion-duration: 0.4s;
$animation-popup-duration: 0.4s;
$animation-notification-duration: 0.4s;
$animation-footbar-product-duration: 0.4s;