.rvideo {
  position: relative;
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    background-position: center;
    object-fit: cover;
    object-position: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}