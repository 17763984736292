.post {
  &--limit-width {
    max-width: 772px;
    margin-left: auto;
    margin-right: auto;
  }
}
.post:hover .post__hidden-info {
  @include screen-up(lg) {
    display: block !important;
    z-index: 1;
  }
}