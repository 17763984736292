.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 80%;
  max-width: 300px;
  z-index: 100000;
  overflow-x: hidden;
  overflow-y: auto;
  //padding: 20px;
  transition: transform 0.3s ease-in-out;
  &.side-drawer_left {
    left: 0;
  }
  &.side-drawer_right {
    right: 0;
  }
  &.side-drawer_center {
    padding-top: 10px;
    left: 10vw;
    top: -200vh;
    &.side-drawer__closed {

    }
    &.side-drawer__opened {
      transform: translateY(205vh);
    }
    //top: 10px;
    height: auto;
    width: 80vw;
    max-width: 80vw;
    max-height: 90vh
  }
  &.side-drawer__opened {
    transform: translateX(0);
  }

  &.side-drawer__closed {
    &.side-drawer_left {
      transform: translateX(-100%);
    }
    &.side-drawer_right {
      transform: translateX(200%);
    }
  }
  .side-drawer__header {
    padding: 25px;
    //border-bottom: 1px solid gray;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 21px;
    color: #141414;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100001;
  }
  .side-drawer__content {
    padding-left: 25px;
    padding-right: 25px;
  }
  ul {
    li {
      list-style: none;
      padding-left: 0 !important;
      margin: 0;
      &::before {
        display: none !important;
      }
    }
  }
}

.side-drawer__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 99999;
}
