.password-page-content {
  @include screen-up(xl) {
    min-height: 400px;
  }
  &__subscription {
    width: 100%;
    max-width: 500px;
  }
  &__subscription--width-limit {
    @include screen-up(xl) {
      max-width: 280px;
    }
  }
  &__image {
    @include screen-up(lg) {
      max-width: 480px;
    }
  }
}