.product-page-gallery__collage {

}

.mainImage, .thumb {
  .rimage {
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
