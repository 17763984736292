/**
 * Основные классы для отображения
 * - контейнеры
 */
@import "mixins/layoutMixins";

// Layout classes
.container {
  @include container;
}

.page {

}

.page-container {
  @include pb(10);
  @include screen-up(lg) {
    margin-bottom: 25px;
  }
}

.row {
  @include row;
}

//.no-gutters > .col, .no-gutters > [class*="col-"] {
//  padding-right: 0;
//  padding-left: 0
//}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  @include colDefaults;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none
}

.col-1 {  @include col(1);}
.col-2 {  @include col(2);}
.col-3 {  @include col(3);}
.col-4 {  @include col(4);}
.col-5 {  @include col(5);}
.col-6 {  @include col(6);}
.col-7 {  @include col(7);}
.col-8 {  @include col(8);}
.col-9 {  @include col(9);}
.col-10 {  @include col(10);}
.col-11 {  @include col(11);}
.col-12 {  @include col(12);}

@media (min-width: 541px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
  }

  .col-sm-1 { @include col(1); }
  .col-sm-2 { @include col(2); }
  .col-sm-3 { @include col(3); }
  .col-sm-4 { @include col(4); }
  .col-sm-5 { @include col(5); }
  .col-sm-6 { @include col(6); }
  .col-sm-7 { @include col(7); }
  .col-sm-8 { @include col(8); }
  .col-sm-9 { @include col(9); }
  .col-sm-10 { @include col(10); }
  .col-sm-11 { @include col(11); }
  .col-sm-12 { @include col(12); }

  .order-sm-first {
    order: -1
  }

  .order-sm-last {
    order: 13
  }

  .order-sm-0 {
    order: 0
  }

  .order-sm-1 {
    order: 1
  }

  .order-sm-2 {
    order: 2
  }

  .order-sm-3 {
    order: 3
  }

  .order-sm-4 {
    order: 4
  }

  .order-sm-5 {
    order: 5
  }

  .order-sm-6 {
    order: 6
  }

  .order-sm-7 {
    order: 7
  }

  .order-sm-8 {
    order: 8
  }

  .order-sm-9 {
    order: 9
  }

  .order-sm-10 {
    order: 10
  }

  .order-sm-11 {
    order: 11
  }

  .order-sm-12 {
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.33333%
  }

  .offset-sm-2 {
    margin-left: 16.66667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.33333%
  }

  .offset-sm-5 {
    margin-left: 41.66667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.33333%
  }

  .offset-sm-8 {
    margin-left: 66.66667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.33333%
  }

  .offset-sm-11 {
    margin-left: 91.66667%
  }
}

@media (min-width: 778px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
  }

  .col-md-1 { @include col(1); }
  .col-md-2 { @include col(2); }
  .col-md-3 { @include col(3); }
  .col-md-4 { @include col(4); }
  .col-md-5 { @include col(5); }
  .col-md-6 { @include col(6); }
  .col-md-7 { @include col(7); }
  .col-md-8 { @include col(8); }
  .col-md-9 { @include col(9); }
  .col-md-10 { @include col(10); }
  .col-md-11 { @include col(11); }
  .col-md-12 { @include col(12); }

  .order-md-first {
    order: -1
  }

  .order-md-last {
    order: 13
  }

  .order-md-0 {
    order: 0
  }

  .order-md-1 {
    order: 1
  }

  .order-md-2 {
    order: 2
  }

  .order-md-3 {
    order: 3
  }

  .order-md-4 {
    order: 4
  }

  .order-md-5 {
    order: 5
  }

  .order-md-6 {
    order: 6
  }

  .order-md-7 {
    order: 7
  }

  .order-md-8 {
    order: 8
  }

  .order-md-9 {
    order: 9
  }

  .order-md-10 {
    order: 10
  }

  .order-md-11 {
    order: 11
  }

  .order-md-12 {
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.33333%
  }

  .offset-md-2 {
    margin-left: 16.66667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.33333%
  }

  .offset-md-5 {
    margin-left: 41.66667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.33333%
  }

  .offset-md-8 {
    margin-left: 66.66667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.33333%
  }

  .offset-md-11 {
    margin-left: 91.66667%
  }
}

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
  }

  .col-lg-1 { @include col(1); }
  .col-lg-2 { @include col(2); }
  .col-lg-3 { @include col(3); }
  .col-lg-4 { @include col(4); }
  .col-lg-5 { @include col(5); }
  .col-lg-6 { @include col(6); }
  .col-lg-7 { @include col(7); }
  .col-lg-8 { @include col(8); }
  .col-lg-9 { @include col(9); }
  .col-lg-10 { @include col(10); }
  .col-lg-11 { @include col(11); }
  .col-lg-12 { @include col(12); }

  .order-lg-first {
    order: -1
  }

  .order-lg-last {
    order: 13
  }

  .order-lg-0 {
    order: 0
  }

  .order-lg-1 {
    order: 1
  }

  .order-lg-2 {
    order: 2
  }

  .order-lg-3 {
    order: 3
  }

  .order-lg-4 {
    order: 4
  }

  .order-lg-5 {
    order: 5
  }

  .order-lg-6 {
    order: 6
  }

  .order-lg-7 {
    order: 7
  }

  .order-lg-8 {
    order: 8
  }

  .order-lg-9 {
    order: 9
  }

  .order-lg-10 {
    order: 10
  }

  .order-lg-11 {
    order: 11
  }

  .order-lg-12 {
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333%
  }

  .offset-lg-2 {
    margin-left: 16.66667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333%
  }

  .offset-lg-5 {
    margin-left: 41.66667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333%
  }

  .offset-lg-8 {
    margin-left: 66.66667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333%
  }

  .offset-lg-11 {
    margin-left: 91.66667%
  }
}

@media (min-width: 1260px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none
  }

  .col-xl-1 { @include col(1); }
  .col-xl-2 { @include col(2); }
  .col-xl-3 { @include col(3); }
  .col-xl-4 { @include col(4); }
  .col-xl-5 { @include col(5); }
  .col-xl-6 { @include col(6); }
  .col-xl-7 { @include col(7); }
  .col-xl-8 { @include col(8); }
  .col-xl-9 { @include col(9); }
  .col-xl-10 { @include col(10); }
  .col-xl-11 { @include col(11); }
  .col-xl-12 { @include col(12); }

  .order-xl-first {
    order: -1
  }

  .order-xl-last {
    order: 13
  }

  .order-xl-0 {
    order: 0
  }

  .order-xl-1 {
    order: 1
  }

  .order-xl-2 {
    order: 2
  }

  .order-xl-3 {
    order: 3
  }

  .order-xl-4 {
    order: 4
  }

  .order-xl-5 {
    order: 5
  }

  .order-xl-6 {
    order: 6
  }

  .order-xl-7 {
    order: 7
  }

  .order-xl-8 {
    order: 8
  }

  .order-xl-9 {
    order: 9
  }

  .order-xl-10 {
    order: 10
  }

  .order-xl-11 {
    order: 11
  }

  .order-xl-12 {
    order: 12
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.33333%
  }

  .offset-xl-2 {
    margin-left: 16.66667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.33333%
  }

  .offset-xl-5 {
    margin-left: 41.66667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.33333%
  }

  .offset-xl-8 {
    margin-left: 66.66667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.33333%
  }

  .offset-xl-11 {
    margin-left: 91.66667%
  }
}

