  .tabs {
    &.tabs__vertical {
      display: flex;
      justify-content: center;
      .tabs__head {
        width: 30%;
        border: none;
        @media all and (min-width: 1024px) {
          border-right: 1px solid #E5E5E5;
        }

        .tabs__btn {
          font-size: 10px;
        }

        .tabs__btn {
          &:hover {
            border-bottom: 1px solid #E5E5E5;
            //border-bottom-: #E5E5E5;
          }
        }

        .active {
          //border: none;
          font-weight: bold;
          border-width: 2px;
          border-bottom-color: #E5E5E5;
        }
      }

      .tabs__body {
        width: 70%;
        font-size: 10px;

        .tabs__content {
          padding: 0;
        }
      }

      .tabs__slider {
        flex-direction: column;
        //width: 25%;
      }
    }
  }