@import "../scss/variables";
@import "../scss/mixins/paddings";
@import "../scss/mixins/globalMixins";

.sitemapPage {
  /** 123 */
  .page {
    font-size: 24px;
  }
}
