.contact-map {
  &::before {
    content: '';
    display: block;
    padding-top: 65%;
  }
  iframe {
    width: 100%;
    height: 100%;
    -webkit-filter: grayscale(90%);
    filter: grayscale(90%);
    @include stretch-block();
  }
  &:hover {
    iframe {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}