.rimage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &__img {
    position: absolute;
    max-width: initial;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-property: opacity;
  }
  &__img--show:not(.loaded) {
    visibility: hidden;
  }
  &__img--fade-in:not(.loaded) {
    //opacity: 0; // Garphild
  }
  &__img--cover {
    object-fit: cover;
  }
  &__img--contain {
    object-fit: contain;
  }
  &__img--stretch-by-width {
    width: 100%;
    height: auto;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
  }
  &__img--stretch-by-height {
    width: auto !important;
    height: 100% !important;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
