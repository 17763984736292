.notification {
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  &.animate {
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }
  &.pressed {
    transform: scale(0.95);
    cursor: pointer;
  }
  &__inner {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
    &.animate {
      transition-property: opacity, transform;
      will-change: opacity, transform;
    }
    &.visible {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}